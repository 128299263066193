export default {
    "ERROR_BRAND_LOCKED": "Die ausgewählte Marke ist momentan aufgrund eines laufenden Prozesses gesperrt und kann daher nicht modifiziert werden.",
    "ERROR_BRAND_LOCKED_TITLE": "Marke gesperrt",
    "ERROR_CONFLICTING_SEMAPHORE_ACQUISITION_COPY": "Die ausgewählte Marke ist Teil eines aktiven Kopiervorgangs und kann daher nicht modifiziert werden.",
    "ERROR_CONFLICTING_SEMAPHORE_ACQUISITION_COPY_TITLE": "Marke gesperrt: Kopiervorgang läuft",
    "ERROR_CONFLICTING_SEMAPHORE_ACQUISITION_WHITELIST": "Die Verarbeitung der letzten Whitelist ist noch nicht abgeschlossen.<br>Diese Funktion ist in wenigen Minuten wieder verfügbar.",
    "ERROR_CONFLICTING_SEMAPHORE_ACQUISITION_WHITELIST_TITLE": "Verarbeitung",
    "ERROR_RULE_ALREADY_EXISTS": "Es existiert bereits eine andere Regel für dieses Schlüsselwort.",
    "ERROR_RULE_ALREADY_EXISTS_TITLE": "Regel existiert bereits",
    "TIME_GAP_ERROR": "Angewandte Wechselkurse müssen lückenlos alle Zeiträume ab dem heutigen Tag abdecken. Der letzte Eintrag sollte daher kein Enddatum haben.",
    "TIME_GAP_ERROR_TITLE": "Zeitliche Lücke beim Anwenden der Wechselkurse",
    "TIME_OVERLAP_ERROR": "Angewandte Wechselkurse dürfen keine Überlappung in den gegebenen Zeiträumen haben. Bitte überprüfen Sie die markierten Einträge. Leere Zeiträume werden entsprechend als Minimum/Maximum behandelt.",
    "TIME_OVERLAP_ERROR_TITLE": "Zeitraum-Überlappung beim Anwenden der Wechselkurse",
    "access_denied_msg": "Sie haben keinen Zugriff auf diese Seite",
    "accommodation": {
        "missing_translation": "Die Übersetzung für diese Unterkunftsart fehlt. Als Ausweichlösung wird die vom Lieferanten bereitgestellte Bezeichnung angezeigt.",
        "subtype": {
            "AH": "Altenheim, Seniorenresidenz",
            "AP": "Apartments",
            "B": "Berghütte",
            "BB": "Privatzimmer",
            "BW": "Biwakschachtel",
            "CC": "Campingplatz & Übernachtungsangebote",
            "CG": "Campingplatz",
            "D": "Andere",
            "FD": "Feriendorf, Freizeitpark",
            "FG": "Gîte",
            "FH": "Ferienhaus, Bungalow",
            "FW": "Ferienwohnung",
            "G": "Gasthof",
            "GH": "Gästehaus",
            "GK": "Kro",
            "GR": "Gruppenunterkunft, Gruppenhaus, Schullandheim, Wanderheim",
            "H": "Hotel",
            "HA": "Apartment-Hotel, Apartmentanlage",
            "HC": "Hotel-Cafe",
            "HE": "Parador, Pousada, Albergo Diffuso",
            "HF": "Karawanserei, Han",
            "HG": "Hotel garni",
            "HH": "Spezielle Hotels (z. B. Eishotel, Kranhotel)",
            "HI": "Nichtöffentliche Hotels & Clubs",
            "HJ": "Ryokan",
            "HK": "Kurhotel",
            "HM": "Motel",
            "HN": "Riad",
            "HO": "Hostel, Backpackers Hotel",
            "HP": "Hotel-Pension",
            "HQ": "--- reserviert für zukünftige Verwendung ---",
            "HR": "Hotel-Restaurant",
            "HS": "Hotelschiff, Kreuzfahrtschiff",
            "HT": "Hotelzug",
            "HU": "Hotelbetrieb einer Universiät/Bildungseinrichtung",
            "K": "Klinik, Krankenhaus",
            "KK": "Kurklinik",
            "KR": "Rehabilitationsklinik",
            "KS": "Sanatorium",
            "KW": "Therapeutische Wohnheime/Wohngruppen",
            "L": "Lodge",
            "LE": "Eco Lodge",
            "LT": "Tent Camp, Luxury Tents",
            "MZ": "Monteurzimmer",
            "P": "Pension",
            "PC": "Pension-Cafe",
            "PG": "Pension garni, Garni",
            "PR": "Pension-Restaurant",
            "R": "Resort",
            "RI": "Resort, Privatinsel",
            "SC": "Selbstversorgerhaus",
            "T": "Tagungszentrum, Tagungshaus",
            "UB": "Ferienhof, Urlaubsbauernhof",
            "WH": "Wohnheim, Studentenwohnheim",
            "YH": "Jugendherberge"
        },
        "type": {
            "1": "Gasthof",
            "2": "Pension",
            "3": "Care",
            "4": "Gewerbliche Privatquartiere",
            "5": "Berghütten",
            "6": "Apartment",
            "7": "Monteurzimmer",
            "8": "Andere",
            "9": "Wohnheime",
            "10": "Gasthaus",
            "11": "Camping",
            "12": "Klinik/Krankenhaus",
            "13": "Resort",
            "14": "Hotel",
            "15": "Gruppenhäuser",
            "16": "Rundreise"
        }
    },
    "accommodation_cap": "Unterkunftart",
    "accommodation_subtype_disabled": "Diese Unterkunft-Unterart ist deaktiviert, weil eine zugehörige Unterkunftart bereits ausgewählt ist",
    "accommodation_type_disabled": "Diese Unterkunftart ist deaktiviert, weil eine zugehörige Unterkunft-Unterart bereits ausgewählt ist",
    "actions_cap": "Aktionen",
    "activated_cap": "Aktiviert",
    "active": "Aktiv",
    "active_cap": "Aktiv",
    "active_master_code_cap": "Aktiver Mastercode",
    "active_rule_elements": "Aktive Regelelemente",
    "add": "Hinzufügen",
    "add_airport": "Airport code hinzufügen",
    "add_all": "Alle hinzufügen",
    "add_as_remark_btn": "Filter für Remark verwenden",
    "add_constant": "Konstante hinzufügen",
    "add_new": "Neuen hinzufügen",
    "add_program_type": "Programmart hinzufügen",
    "add_rule": "Regel hinzufügen",
    "add_to_draft": "auf den Entwurf",
    "additional_type_placeholder": "AdditionalType eingeben",
    "address": "Adresse",
    "address_code": "Adressenbezeichnung",
    "address_delete": "Adresse löschen",
    "address_edit": "Adresse bearbeiten",
    "address_register": "Adresse anlegen",
    "addresses_cap": "Adressen",
    "adopt_delivered_airports_btn": "Flughäfen übernehmen",
    "adopt_delivered_hotel_category_btn": "Kategorie übernehmen",
    "adopt_delivered_hotel_name_btn": "Hotelnamen übernehmen",
    "agency": "Agentur",
    "agency_delete_cap": "Agentur löschen",
    "agency_number": "Agenturnummer",
    "agency_number_delete_cap": "Agenturnummer löschen",
    "agency_numbers_cap": "Argenturnummern",
    "agency_numbers_edit": "Agenturnummer bearbeiten",
    "agency_numbers_register": "Agenturnummer anlegen",
    "agoda": "Agoda",
    "air_type_cap": "Flugzeugtyp",
    "airline_cap": "Fluggesellschaft",
    "airports_per_page_cap": "Flughäfen pro Seite",
    "airports_total": "Flughäfen gesamt:",
    "all": "alle",
    "amount_cap": "Aufschlag (netto)",
    "amount_gross_cap": "Aufschlag (brutto)",
    "and_or_required_field": "mindestens eine Eingabe erforderlich",
    "annotation_cap": "Anmerkung",
    "appliance_code_cap": "Appliance Code",
    "appliance_extra_codes_cap": "Appliance Codes",
    "appliance_group_code_cap": "Appliance Group Code",
    "appliance_groups_cap": "Appliance Groups",
    "appliances_cap": "Anwendung auf",
    "apply": "Anwenden",
    "apply_exchange": "Ausgewähler Marke zuweisen",
    "apply_rule": "Regel anwenden",
    "apply_to_cap": "Bezug auf",
    "arrival_airport_cap": "Zielflughafen",
    "arrival_airports_cap": "Zielflughäfen",
    "arrival_airports_to_use_cap": "Diese Flughäfen verwenden",
    "asc": {
        "assign_button_cap": "Einschränkungen hinzufügen",
        "deleteModal": {
            "message": "Sind Sie sicher, dass Sie alle Accommodation Sales Controls von den gefilterten Hotels entfernen möchten?",
            "title": "Accommodation Sales Control Einschränkungen entfernen"
        },
        "exportBtn": "Accommodation Sales Control Exportieren",
        "exportConfirmModal": {
            "btnConfirm": "ASC Exportieren",
            "exportInProgress": "Ein ASC-Export läuft bereits. Die Funktion steht nach Beendigung des Exports wieder zur Verfügung.",
            "message": "Sind Sie sicher, dass Sie das Accommodation Sales Control exportieren möchten?",
            "successMessage": "ASC erfolgreich exportiert",
            "title": "ASC Exportieren"
        },
        "filterModal": {
            "assignmentInProgress": "Eine ASC-Zuweisung läuft bereits. Die Funktion steht nach Beendigung der Zuweisung wieder zur Verfügung.",
            "title": "ASC Einschränkung hinzufügen"
        },
        "form": {
            "end_disabled_tt": "Start- und Enddatum können nicht gleichzeitig genutzt werden",
            "start_disabled_tt": "Start- und Enddatum können nicht gleichzeitig genutzt werden"
        },
        "hotelModal": {
            "add_btn": "Einschränkung hinzufügen",
            "current_asc_cap": "Vorhandene Einschränkungen",
            "delete_cap": "Löschen",
            "emptyText": "Es sind keine Einschränkungen vorhanden",
            "empty_restriction_not_allowed_tooltip": "Leere Einschränkungen sind nicht erlaubt",
            "end_cap": "Enddatum",
            "giataId_cap": "GIATA ID",
            "goalId_cap": "GOAL ID",
            "localSupplier_cap": "Lokaler Lieferant",
            "new_asc_cap": "Neue Einschränkung anlegen",
            "restrictions_to_be_added_cap": "Einschränkungen werden hinzugefügt",
            "start_cap": "Startdatum",
            "title": "ASC Zuweisungen Editieren"
        },
        "remove_button_cap": "Einschränkungen entfernen",
        "table_column_cap": "Sales Control"
    },
    "assign": "Zuweisen",
    "assign_brands": "Marken zuweisen",
    "assign_brands_error": "Fehler beim Hinzufügen zu einer Marke",
    "assign_brands_success": "Markenzuweisung erfolgreich durchgeführt",
    "assign_distribution_channel_btn": "Vertriebskanäle zuweisen",
    "assign_distribution_channel_cap": "Vertriebskanäle hinzufügen",
    "assign_extras_btn": "Zusatzleistungen zuweisen",
    "assign_program_types": "Programmarten zuweisen",
    "assign_program_types_cap": "Programmart hinzufügen",
    "assign_to_brand": "Marken zuweisen",
    "assigned": "Zugewiesen",
    "assigned_brands_cap": "Zugewiesene Marken",
    "assigned_cap": "Zugewiesen",
    "assigned_exchange_rates_cap": "Zugewiesene Wechselkurse",
    "assigned_extras_cap": "Zugewiesene Zusatzleistungen",
    "assigned_globaltypes_cap": "Zugewiesene GlobalTypes",
    "assigned_prod_specs_cap": "Zugewiesene Produktionsspezifikationen",
    "assigned_to_brands": "Zu Marken zugewiesen",
    "at_extra_type": "Eintrittskarte",
    "auth_access_denied": "Zugriff verweigert",
    "auth_login": "Anmelden",
    "auth_token": "Token",
    "auto_refresh_cap": "Automatische Aktualisierung",
    "auto_whitelist_config_cap": "Automatisches Whitelisting",
    "available_description_languages_cap": "Beschreibungstexte (Sprachen)",
    "back_to_draft_btn": "wieder aufnehmen",
    "back_to_grouped_view": "Zurück zur gruppierten Ansicht",
    "back_to_list": "Zurück zur Liste",
    "back_to_main_code_view": "Zurück zur Hauptcodegruppe",
    "basic": "Basis",
    "basic_data_cap": "Basisdaten",
    "bhubpwcustomer": "Peakwork Customer",
    "bhubpwoffice": "Peakwork Office",
    "bhubusername": "Benutzername",
    "board_cap": "Verpflegung",
    "board_code_assigned": "liefert, dann verwende den Verpflegungstyp",
    "board_code_cap": "Verpflegungstyp",
    "board_code_not_found": "Zugeordneter Verpflegungscode konnte nicht gefunden werden",
    "board_count_cap": "Verpflegungsanzahl",
    "board_key_cap": "Verpflegungsschlüssel",
    "board_matching_config_cap": "Automatische Codezuordnung für Verpflegungen",
    "board_names_cap": "Verpflegungsname",
    "board_restrictions_cap": "Verpflegung",
    "board_restrictions_count_cap": "Verpflegung",
    "board_rule_added_successfully": "Regel erfolgreich angelegt",
    "board_rule_deleted_successfully": "Regel erfolgreich gelöscht",
    "board_rule_edited_successfully": "Regel erfolgreich editiert",
    "board_tag_cap": "Verpflegungskenner",
    "booking_code_cap": "Buchungscode",
    "booking_end_cap": "Buchungsende",
    "booking_period_cap": "Buchungszeitraum",
    "booking_start_cap": "Buchungsbeginn",
    "brand": "Marke",
    "brandSpecificOverride": "Marken-Spezifische Überschreibung. Geliefert",
    "brand_bhub_credentials": "Brand B-Hub-Zugangsdaten",
    "brand_cap": "Marke",
    "brand_copy": {
        "cap": {
            "copy_contracts": "Verträge kopieren",
            "existing": "In existierende Marke kopieren",
            "modal": "In Marke kopieren",
            "new": "Neue Marke erstellen"
        },
        "card": {
            "options": "Optionen",
            "source": "Quell-Marke",
            "submit": "Absenden",
            "target": "Ziel-Marke"
        },
        "code_description": "Code der Marke. Wird genutzt, um die Marke zu identifizieren, z.B. bei der Angebotssuche.",
        "info": {
            "brand_deletion_warning": "Falls ein Fehler während des Kopiervorgaangs auftritt, wird die neue Marke automatisch gelöscht.",
            "brand_lock_warning": "Dieser Prozess kann sehr lange dauern. Während des Prozesses sind beide Marken gesperrt und weder die Marke noch deren Daten können bearbeitet werden. <br> Während dieser Zeit empfehlen wir, nicht die Wechselkurs-API zu nutzen, da diese unter Umständen nicht der neuen Marke zugewiesen werden.",
            "head": "Die folgenden Daten werden in die neue Marke übernommen:",
            "head_select": "Für jeden gefilterten Vertrag können folgende Daten in die Marke kopiert werden:",
            "matched": "Der konfigurierte Filter findet {numContracts} Verträge.",
            "no_intersecting_suppliers": "Es gibt keine gemeinsamen Lieferanten zwischen der Quell- und der Ziel-Marke.",
            "no_steps": "Es wurden keine Kopierschritte ausgewählt."
        },
        "name_description": "Name der neuen Marke. Wird im Porfolio Management der Box angezeigt.",
        "steps": {
            "BRAND_ASSIGNMENTS": "Markenzuweisung",
            "DISTRIBUTION_CHANNELS": "Vertriebskanäle",
            "EXCHANGE_RATES": "Wechselkurse",
            "GLOBAL_EXTRAS": "Zuweisungen von Zusatzleistungen",
            "GLOBAL_TYPES": "Marken-spezifische Globaltypes",
            "HOTEL_ENRICHMENTS": "Marken-spezifische Hoteldaten-Überschreibungen (Programmarten)",
            "REMARKS": "Zuweisungen von Remarks",
            "ROOM_BOARD_BLACKLIST": "Zimmer-Auswahl",
            "WHITELIST": "Entwurf-Status"
        }
    },
    "brand_copy_process_step": "Kopierschritt:",
    "brand_currency_cap": "Währung Marke",
    "brand_delete": "Marke löschen",
    "brand_edit": "Marke bearbeiten",
    "brand_locked_warning": "Aufgrund eines aktiven Vorgangs für die ausgewählte Marke (z.B. Whitelisting, Marke wird kopiert...) können die Daten für diese Marke nicht editiert werden.",
    "brand_package_travel_directive": "Pauschalreiserichtlinien Konfiguration",
    "brand_package_travel_directive_delete": "Pauschalreiserichtlinien Konfiguration löschen",
    "brand_package_travel_directive_edit": "Pauschalreiserichtlinien Konfiguration bearbeiten",
    "brand_package_travel_directive_register": "Pauschalreiserichtlinien Konfiguration anlegen",
    "brand_preselection_cap": "Markenvorauswahl",
    "brand_register": "Marke anlegen",
    "brand_supplier_configuration_title": "Konfiguration für \"{name}\"",
    "brandbooking_delete": "B-Hub-Zugangsdaten löschen",
    "brandbooking_edit": "B-Hub-Zugangsdaten bearbeiten",
    "brandbooking_register": "B-Hub-Zugangsdaten anlegen",
    "brands": "Marken",
    "brands_cap": "Marken",
    "c_dtpicker": {
        "clear_cap": "Auswahl löschen",
        "close_cap": "Schliessen",
        "decrement_hour": "Stunden reduzieren",
        "decrement_minute": "Minuten reduzieren",
        "decrement_second": "Sekunden reduzieren",
        "increment_hour": "Stunden erhöhen",
        "increment_minute": "Minuten erhöhen",
        "increment_second": "Sekunden erhöhen",
        "next_century": "Nächstes Jahrhundert",
        "next_decade": "Nächstes Jahrzehnt",
        "next_month_cap": "Nächster Monat",
        "next_year": "Nächstes Jahr",
        "pick_hour": "Wähle Stunde",
        "pick_minute": "Minuten wählen",
        "pick_second": "Sekunden wählen",
        "prev_century": "Vorheriges Jahrhundert",
        "prev_decade": "Vorheriges Jahrzehnt",
        "prev_month_cap": "Vorheriger Monat",
        "prev_year": "Vorheriges Jahr",
        "select_decade": "Jahrzehnt wählen",
        "select_month_cap": "Monat wählen",
        "select_year": "Jahr wählen",
        "today_cap": "heute"
    },
    "c_table": {
        "column_visibility": "Spaltensichtbarkeit",
        "empty_result": "Kein Inhalt",
        "entries_per_page": "Einträge pro Seite: ",
        "no_response": "Abfrage fehlgeschlagen!",
        "total": "Gesamt:",
        "total_extras": "Zusatzleistungen gesamt"
    },
    "calculation_cap": "Berechnung",
    "cancel": "Abbrechen",
    "category_cap": "Hotelkategorie",
    "channels_cap": "Kanäle",
    "charges_cap": "Aufschläge",
    "charts": {
        "horizontal_bar_amount": "Anzahl",
        "horizontal_bar_draft_count": "Entwurf",
        "horizontal_bar_total_count": "Insgesamt",
        "horizontal_bar_whitelist_count": "Whitelist"
    },
    "city_cap": "Stadt",
    "clear_dates": "Datumseingaben entfernen",
    "close": "Schliessen",
    "close_application": "Anwendung schließen",
    "code": "Code",
    "code_cap": "Code",
    "code_supplier_cap": "Lieferantencode",
    "codes_cap": "Codes",
    "condition_cap": "Wenn/Dann",
    "configuration": "Konfiguration",
    "configure_basic": "Basisdaten konfigurieren",
    "confirm": "Bestätigen",
    "confirm_add_room_matching_configuration": "Möchten Sie die Konfiguration zur Codezuordnung '{0}' erstellen?",
    "confirm_add_room_matching_configuration_title": "Neue Konfiguration erstellen",
    "confirm_brandAssignmentInProgress_in_pending": "Die Verarbeitung der letzten Markenzuweisung ist noch nicht abgeschlossen.<br>Diese Funktion ist in einigen Minuten wieder verfügbar.",
    "confirm_copy_room_matching_configuration": "Möchten Sie die existierende Konfiguration zur Codezuordnung '{source}' in die neue Konfiguration zur Codezuordnung '{target}' kopieren?",
    "confirm_delete_board_code": "Soll der Verpflegungscode \"{0}\" wirklich gelöscht werden? <br> Bitte beachten Sie, dass dies Einfluss auf das Verpflegungs-Matching haben kann!",
    "confirm_delete_board_code_title": "Verpflegungscode löschen",
    "confirm_delete_custom_rate": "Soll die benutzerdefinierte Rate \"{0}\" wirklich gelöscht werden?",
    "confirm_delete_custom_rate_title": "Rate löschen",
    "confirm_delete_extra": "Möchten Sie diese Zusatzleistung und deren Zuordnung zu den Hotelverträgen löschen?",
    "confirm_delete_extra_title": "Bestätigung erforderlich",
    "confirm_delete_global_type": "Wollen Sie diesen GlobalType wirklich löschen?",
    "confirm_delete_global_type_title": "GlobalType löschen",
    "confirm_delete_rate_rule": "Soll die Regel wirklich gelöscht werden?",
    "confirm_delete_rate_rule_title": "Regel löschen",
    "confirm_delete_room_code": "Soll der Zimmercode \"{0}\" wirklich gelöscht werden? <br> Bitte beachten Sie, dass dies Einfluss auf das Zimmer-Matching haben kann!",
    "confirm_delete_room_code_title": "Zimmercode löschen",
    "confirm_delete_room_matching_configuration": "Möchten Sie die Konfiguration zur Codezuordnung '{0}' entfernen?",
    "confirm_delete_room_matching_configuration_title": "Konfiguration löschen",
    "confirm_draft_remove_result": "Soll das gesamte Suchergebnis vom Entwurf entfernt werden?",
    "confirm_draft_remove_result_title": "Ergebnis entfernen",
    "confirm_draft_result": "Soll das gesamte Suchergebnis in den Entwurf übernommen werden?",
    "confirm_draft_result_title": "Ergebnis übernehmen",
    "confirm_export_global_types": "Sollen alle GlobalTypes jetzt exportiert werden?<br>(GlobalTypes sind auch Teil des Exports der Whitelist)",
    "confirm_export_global_types_title": "GlobalTypes exportieren",
    "confirm_full_export": "Diese Funktion löst einen vollständigen Export der Markeninformationen aus.<br>D.h. <b>Hotels von der Whitelist</b> inklusive aller Stammdaten-Änderungen, die zugehörigen <b>Zusatzleistungen</b>, <b>Umrechnungskurse</b> und <b>DRV GlobalTypes</b>.<br> Möchten Sie wirklich den vollständigen Export auslösen?",
    "confirm_full_export_title": "Vollständiger Export",
    "confirm_job_in_pending": "Die Verarbeitung des letzten Auftrags ist noch nicht abgeschlossen.<br>Diese Funktion ist in wenigen Minuten wieder verfügbar.",
    "confirm_overwrite_exchange_rate_msg": "Möchten Sie den bestehenden Wechselkurs überschreiben?",
    "confirm_overwrite_exchange_rate_title": "Wechselkurs überschreiben",
    "confirm_rb_automatching_activate_cap": "Aktivierung der automatisierten Codezuordnung",
    "confirm_rb_automatching_deactivate_cap": "Deaktivierung der automatisierten Codevergabe",
    "confirm_remark_delete": "Soll dieses Remark gelöscht werden?",
    "confirm_remove_board_rule": "Soll die Regel \"{0} {1} {2} ({3})\" gelöscht werden?",
    "confirm_remove_board_rule_title": "Regel löschen",
    "confirm_remove_exchange_rate": "Soll der Wechselkurs {0} => {1} wirklich entfernt werden?",
    "confirm_remove_exchange_rate_template_msg": "Möchten Sie den Wechselkurs wirklich löschen?",
    "confirm_remove_exchange_rate_template_title": "Wechselkurs löschen",
    "confirm_remove_exchange_rate_title": "Wechselkurs entfernen",
    "confirm_remove_from_draft_title": "Vom Entwurf entfernen",
    "confirm_remove_hotel_from_draft": "Soll das Hotel \"{0}\" vom Entwurf entfernt werden?",
    "confirm_remove_hotels_from_draft": "Sollen {0} Hotels vom Entwurf entfernen werden?",
    "confirm_remove_room_rule": "Soll die Regel \"{0} {1} ({2})\" gelöscht werden?",
    "confirm_remove_room_rule_title": "Regel löschen",
    "confirm_save_rate_rules": "Möchten Sie die Regeln für benutzerdefinierte Raten speichern? <br/><br/> Die Regeln werden danach direkt angewendet, was längere Zeit dauern kann. Währenddessen sind sämtliche Marken gesperrt.",
    "confirm_save_rate_rules_title": "Speichern & Anwenden",
    "confirm_set_master_code_rule": "Wollen Sie die neue Regel anwenden?<br>Dies ändert den Mastercode aller Hotels in jeder Brand!<br>Beachten Sie, das die Verarbeitung der neuen Regel etwas Zeit in Anspruch nimmt in der keine Zusatzleistungen oder Remarks zugewiesen werden sollten",
    "confirm_set_master_code_rule_title": "Regel für Mastercode",
    "confirm_update_room_matching_configuration": "Möchten Sie die Konfiguration zur Codezuordnung '{0}' aktualisieren?",
    "confirm_update_room_matching_configuration_title": "Konfiguration aktualisieren",
    "confirm_whitelist_draft_result": "Möchten Sie die Ergebnisse der Filterung wirklich whitelisten?",
    "confirmation_btn": "Änderungen bestätigen",
    "confirmation_text": "Bestätigungstext",
    "contains_brand_specific_locked_msg": "Dieses Hotel hat Marken-Spezifische Überschreibungen, die mittels der Panel Enrichment-API eingespielt wurden. Bitte benutzen Sie weiterhin die API um die Marken-Spezifischen Überschreibungen zu bearbeiten.",
    "contractUuid_cap": "Vertrag UUID",
    "contract_cap": "Verträge",
    "contract_currency_cap": "Währung Hotelvertrag",
    "contract_distribution_channels": "Vertriebskanäle auf Vertragsebene",
    "contract_level_filter_applied": "Die folgenden Filter auf Vertragsebene wirken sich auf dieses Feld aus: {0}",
    "contract_name_cap": "Vertragsschlüssel",
    "contracts_grouped_tooltip": "{number} Verträge wurden zusammen gruppiert",
    "conversion_factor_cap": "Umrechnungsfaktor",
    "copy": "Kopieren",
    "count_of_images_cap": "Anzahl Bilder",
    "count_of_rooms": "Anzahl Zimmer",
    "count_of_rooms_matching_rate_filter": "Anzahl Zimmer, die vom Ratenfilter getroffen werden",
    "country": "Land",
    "country_cap": "Land",
    "create_btn": "Hinzufügen",
    "create_globalType_blacklist_cap": "GlobalTypes Blacklist auswählen",
    "create_globalType_filter_cap": "GlobalTypes Filter auswählen",
    "csv_export_modal": {
        "error_getting_supported_headers": "Beim Anfragen der CSV-Felder ist ein Fehler aufgetreten",
        "export_options": "Export-Einstellungen",
        "selected_brand": "Gewählte Marke"
    },
    "csv_fields": "CSV-Felder",
    "currency_cap": "Währung",
    "currency_not_provided": "nicht verfügbar",
    "custom_airports_cap": "Eigene Zielflughäfen",
    "custom_codes_cap": "Benutzerdefinierte Code-Vorlagen",
    "custom_codes_deactivated": "Benutzerdefinierte Code-Vorlagen können nur für die Standardkonfiguration angewandt werden",
    "custom_descriptions_cap": "Benutzerdefinierte Beschreibung",
    "custom_master_code_overwrite_cap": "Benutzerdefinierter Mastercode",
    "custom_master_code_overwrite_descriptions": "Benutzerdefinierte Beschreibungen",
    "custom_rate_assigned_label": "liefert, dann verwende die benutzerdefinierte Rate",
    "custom_rate_assignment": "Ratenregel",
    "custom_rate_cap": "Benutzerdefinierte Rate",
    "custom_rate_code_cap": "Code",
    "custom_rate_deleted": "Rate erfolgreich gelöscht",
    "custom_rate_distribution_channel_exclusions_cap": "Ausgeschlossene Vertriebskanäle",
    "custom_rate_filter": "Filter auf benutzerdefinierte Raten",
    "custom_rate_filtered_tooltip": "Diese benutzerdefinierte Rate ist nicht Teil des Ratenfilters der Marke. Dieses Zimmer wird nicht exportiert.",
    "custom_rate_global_type_assignment": "GlobalType-Regel",
    "custom_rate_live_supplier_tooltip": "Anwendung benutzerdefinierter Ratenregeln für Live Search Lieferanten nicht möglich. Markenfilter wird beim Live Search angewendet.",
    "custom_rate_name_cap": "Name",
    "custom_rate_rules_exported": "Benutzerdefinierte Raten wurden erfolgreich exportiert",
    "custom_rate_unknown": "Benutzerdefinierte Rate unbekannt",
    "custom_rates_cap": "Benutzerdefinierte Raten",
    "customtype_placeholder": "CustomType eingeben",
    "date_format": "DD.MM.YYYY",
    "date_restrictions_cap": "Buchungs- / Reisezeitraum",
    "date_restrictions_count_cap": "Buchungs- / Reisezeitraum",
    "date_time_format": "DD.MM.YYYY HH:mm",
    "date_time_seconds_format": "DD.MM.YYYY HH:mm:ss",
    "days": "Tage",
    "dc_assigned": "zugewiesen",
    "dc_unassigned": "nicht zugewiesen",
    "deactivate_btn": "Deaktivieren",
    "default": "Standard",
    "delete": "Löschen",
    "delete_all": "Alle löschen",
    "delete_confirm_btn": "Ja, löschen",
    "delete_filter_set_cap": "Filtersatz löschen",
    "deleted_brand": "gelöschte Marke",
    "deleted_info": "Informationen über die Löschung",
    "deleted_info_cap": "Aus dem Inventar entfernt am",
    "deleted_info_not_scheduled": "Nicht zur Löschung vorgesehen",
    "deleted_info_not_scheduled_job_disabled": "Das Löschen von alten Verträgen ist ausgeschaltet",
    "deleted_info_not_scheduled_whitelisted": "Für die Marken {0} gewhitelisted",
    "deleted_info_scheduled_date": "Wird nach dem {0} gelöscht",
    "delivered_arrival_airports_cap": "Gelieferte Zielflughäfen",
    "delivered_cap": "Geliefert",
    "delivered_code": "Verpflegungs-Code",
    "delivered_code_label": "den Verpflegungs-Code",
    "delivered_supplier_rate_label": "die Rate",
    "delivered_supplier_rates_cap": "Gelieferte Raten",
    "departure_airport_cap": "Abflughafen",
    "description": "Beschreibung",
    "description_cap": "Beschreibung",
    "description_contains": "das Schlüsselwort",
    "description_deactivate_rb_matching": "Sie sind dabei die automatische Codevergabe für Zimmer und Verpflegungen zu deaktivieren. Dies bedeutet, dass Zimmer und Verpflegungen so publiziert werden, wie sie geliefert werden.",
    "description_language_cap": "Wenn der Beschreibungstext in der Sprache",
    "description_languages_cap": "Beschreibungstext Sprachen",
    "descriptions": "Beschreibungen",
    "discard": "Verwerfen",
    "discard_and_close": "Änderungen verwerfen und schliessen",
    "display_hotels": "Hotels anzeigen",
    "distributionDashboard": {
        "booking_traffic": "Buchungsverkehr",
        "live_search": "Live-Suche"
    },
    "distribution_agencies_cap": "Agenturen",
    "distribution_agencies_edit": "Agentur bearbeiten",
    "distribution_agencies_register": "Agentur anlegen",
    "distribution_cap": "Vertriebskanal",
    "distribution_channel": "Vertriebskanal",
    "distribution_channel_cap": "Vertriebskanal",
    "distribution_channel_delete_cap": "Vertriebskanal löschen",
    "distribution_channel_max_info": "Bitte beachten Sie, dass nicht mehr als 60 Vertriebskanäle unterstützt werden.",
    "distribution_channels_cap": "Vertriebskanäle",
    "distribution_channels_edit": "Vertriebskanal bearbeiten",
    "distribution_channels_register": "Vertriebskanal anlegen",
    "do_not_modify": "Nicht modifizieren",
    "documentation": "Dokumentation",
    "doris_live_brand_configuration": "Live Search Marken Konfiguration",
    "doris_live_supplier_configuration": "Live Search Lieferanten Konfiguration",
    "double_room": "Doppelzimmer",
    "download_csv_export": "CSV-Export herunterladen",
    "download_in_progress": "Download läuft",
    "download_options_cap": "Download Optionen",
    "draft": "Entwurf",
    "draft_all_btn": "Ergebnis zum Entwurf hinzufügen",
    "draft_button_cap": "Draft action",
    "draft_page_btn": "Seite zum Entwurf hinzufügen",
    "drafted": "auf dem Entwurf",
    "drafted_hotels": "Entwurf",
    "drafted_hotels_cap": "Entwurf",
    "drafted_or_whitelisted": "auf dem Entwurf oder auf der Whitelist",
    "drv_global_types_cap": "DRV GlobalTypes",
    "edit": "Bearbeiten",
    "edit_cap": "Bearbeitung",
    "edit_global_types": "GlobalTypes bearbeiten",
    "edit_hotel_data": "Hoteldaten bearbeiten",
    "edit_hotel_data_cap": "Hoteldaten bearbeiten",
    "edit_hotel_global_types_cap": "Hotel GlobalTypes bearbeiten",
    "edit_master_data": "Stammdaten bearbeiten",
    "edit_room_descriptions_add_custom_code": "Neuer Benutzerdefinierter Code erstellen",
    "edit_room_descriptions_cap": "Raumbeschreibung bearbeiten",
    "edit_room_descriptions_prompt": "Bearbeitungsmodus auswählen",
    "edit_room_descriptions_use_existing_code": "Benutzerdefinierte Code Vorlage verwenden",
    "edit_rule": "Regel bearbeiten",
    "empty_code": "Leerer Code",
    "end_cap": "Ende",
    "end_date_cap": "Enddatum",
    "enriched_program_type_cap": "Programmart",
    "env_stage_demo": "Demo",
    "env_stage_dev": "Entwicklung",
    "env_stage_prod": "Produktion",
    "env_stage_qa": "QA",
    "env_stage_showcase": "Showcase",
    "env_stage_staging": "Staging",
    "env_stage_test": "Test",
    "env_updated": "Umgebungsbeschreibungen wurden erfolgreich gespeichert",
    "environment_description": "Hier können ein Name und ein Umgebungstyp angelegt werden. Name und Umgebungstyp werden in der oberen rechten Ecke des Bildschirms angezeigt. Die Angaben sind frei wählbar und dienen der reinen Darstellung. Dies kann beispielsweise zur Unterscheidung bei Verwendung verschiedener Instanzen genutzt werden.",
    "environments": "Umgebungen",
    "error": "Fehler",
    "error_activate": "Fehler beim Aktivieren",
    "error_adding_global_type_failed": "Beim Anlegen des GlobalTypes ist ein Fehler aufgetreten",
    "error_adding_main_rule_failed": "Beim Anlegen der Regel ist ein Fehler aufgetreten",
    "error_airports_call_failed": "Beim Abrufen der Flughäfen ist ein Fehler aufgetreten",
    "error_all_currencies_call_failed": "Beim Abrufen der Währungen ist ein Fehler aufgetreten",
    "error_all_currencies_for_brand_call_failed": "Beim Abrufen der Währungen für die gewählte Marke ist ein Fehler aufgetreten",
    "error_all_main_rules_failed": "Beim Abrufen der Regeln für Hauptcodes ist ein Fehler aufgetreten",
    "error_all_sub_rules_failed": "Beim Abrufen der Regeln für Subcodes ist ein Fehler aufgetreten",
    "error_asc_edit_call_failed": "Beim Ändern der Accommodation Sales Control Einschränkungen ist ein Fehler aufgetreten",
    "error_assigning_distribution_channel_failed": "Bei der Zuweisung der Vertriebskanäle ist ein Fehler aufgetreten",
    "error_autocomplete_call_failed": "Beim Abrufen von Eingabevervollständigungen ist ein Fehler aufgetreten",
    "error_automatching_config_call_failed": "Beim Abrufen der Konfiguration der automatischen Codevergabe ist ein Fehler aufgetreten",
    "error_board_codes_call_failed": "Beim Abrufen der Codes für Verpflegungen ist ein Fehler aufgetreten",
    "error_board_description_call_failed": "Beim Abrufen der Verpflegungsbeschreibungen ist ein Fehler aufgetreten",
    "error_board_rules_call_failed": "Beim Abrufen der Regeln für Verpflegungen ist ein Fehler aufgetreten",
    "error_brand_call_failed": "Beim Abrufen der Marken ist ein Fehler aufgetreten",
    "error_city_call_failed": "Beim Abrufen der verfügbaren Städte ist ein Fehler aufgetreten",
    "error_code": {
        "NAME_ALREADY_EXISTS": "Dieser Name existiert bereits"
    },
    "error_content_gc": "Beim bereinigen des Content Cache ist ein Fehler aufgetreten",
    "error_contract_call_failed": "Beim Abrufen der Hotelverträge ist ein Fehler aufgetreten",
    "error_contract_description_call_failed": "Beim Abrufen der Hotelvertragbeschreibungen ist ein Fehler aufgetreten",
    "error_country_call_failed": "Beim Abrufen der verfügbaren Länder ist ein Fehler aufgetreten",
    "error_custom_rate_brand_filters_call_failed": "Beim Abrufen der Ratenfilter für Brands ist ein Fehler aufgetreten",
    "error_custom_rate_call_failed": "Beim Abrufen der benutzerdefinierten Raten ist ein Fehler aufgetreten",
    "error_custom_rate_rules_export_failed": "Benutzerdefinierte Raten konnten nicht exportiert werden",
    "error_custom_rate_saving_failed": "Beim Speichern der benutzerdefinierten Rate ist ein Fehler aufgetreten",
    "error_custom_rate_update_failed": "Beim Update der benutzerdefinierten Rate ist ein Fehler aufgetreten",
    "error_dashboard": "Fehler beim Laden des Dashboards",
    "error_dashboard_no_url": "Konnte Dashboard-URL nicht ermitteln.",
    "error_delete_room_code_failed": "Beim Löschen des Zimmercodes ist ein Fehler aufgetreten",
    "error_deleting_board_code_failed": "Beim Löschen des Verpflegungscodes ist ein Fehler aufgetreten",
    "error_deleting_custom_rate_failed": "Beim Löschen der benutzerdefinierten Rate ist ein Fehler aufgetreten",
    "error_deleting_extra_failed": "Beim Löschen der Zusatzleistung ist ein Fehler aufgetreten",
    "error_deleting_rate_rule_failed": "Beim Löschen der Rate ist ein Fehler aufgetreten",
    "error_deleting_remark_failed": "Beim Löschen des Remark ist ein Fehler aufgetreten",
    "error_deleting_room_code_failed": "Beim Löschen des Raumcodes ist ein Fehler aufgetreten",
    "error_deleting_room_matching_config_default": "Standardkonfiguration kann nicht gelöscht werden",
    "error_distribution_channel_call_failed": "Beim Abrufen der Vertriebskanäle ist ein Fehler aufgetreten",
    "error_download_call_failed": "Beim Starten des Downloads ist ein Fehler aufgetreten",
    "error_draft_remove_result_call_failed": "Beim Entfernen des gesamten Ergebnisses vom Entwurf ist ein Fehler aufgetreten",
    "error_draft_result_call_failed": "Beim Hinzufügen des gesamten Ergebnisses zum Entwurf ist ein Fehler aufgetreten",
    "error_editing_global_type_failed": "Bei der Speichern der Änderungen des GlobalTypes ist ein Fehler aufgetreten",
    "error_event_call_failed": "Beim Abrufen der Ereignisse ist ein Fehler aufgetreten",
    "error_exchange_rate_assignment": "Der Wechselkurs konnte nicht übernommen werden",
    "error_exchange_rate_template_call_failed": "Bestehende Wechselkurse konnten nicht abgerufen werden.",
    "error_exchange_rates_call_failed": "Beim Abrufen der Umrechnungskurse ist ein Fehler aufgetreten",
    "error_exchange_rates_config_call_failed": "Beim Abrufen der Umrechnungskurseinstellungen ist ein Fehler aufgetreten",
    "error_exchange_rates_config_put_failed": "Beim Speichern der Umrechnungskurseinstellungen ist ein Fehler aufgetreten",
    "error_exporting_exchange_rates": "Beim exportieren der Wechselkurse ist ein Fehler aufgetreten",
    "error_external_app_not_found": "Es konnten keine Informationen zur Anwendung \"{0}\" geladen werden.",
    "error_external_apps_call_failed": "Beim Abrufen der externen Anwendungen ist ein Fehler aufgetreten",
    "error_extra_call_failed": "Beim Abrufen der Zusatzleistung ist ein Fehler aufgetreten",
    "error_extras_add_call_failed": "Beim Hinzufügen einer Zusatzleistung ist ein Fehler aufgetreten",
    "error_extras_call_failed": "Beim Abrufen der Zusatzleistungen ist ein Fehler aufgetreten",
    "error_extras_call_for_goalid_failed": "Beim Abrufen der Hoteldaten ist ein Fehler aufgetreten",
    "error_extras_remove_call_failed": "Beim Entfernen der Zusatzleistungen ist ein Fehler aufgetreten",
    "error_filterset_call_failed": "Beim Abrufen der Filtersätze ist ein Fehler aufgetreten",
    "error_filterset_saving_failed": "Beim Speichern des Filtersatzes ist ein Fehler aufgetreten",
    "error_full_export_failed": "Beim vollständigen Export ist ein Fehler aufgetreten",
    "error_full_export_global_types_failed": "Beim Exportieren der GlobalTypes ist ein Fehler aufgetreten",
    "error_get_master_code_rule_call_failed": "Beim Abrufen der aktuellen Regel ist ein Fehler aufgetreten",
    "error_get_missing_exchange_rates": "Beim Abrufen der fehlenden Wechselkurse ist ein Fehler aufgetreten",
    "error_getting_environment": "Beim Abrufen der Umgebungsinformationen ist ein Fehler aufgetreten",
    "error_getting_release_notes": "Beim Abfragen der Release notes ist ein Fehler aufgetreten",
    "error_global_type_delete_call_failed": "Beim Löschen des GlobalTypes ist ein Fehler aufgetreten",
    "error_global_types_call_failed": "Beim Abrufen der GlobalTypes ist ein Fehler aufgetreten",
    "error_global_types_sources_call_failed": "Beim Abrufen der Quellen der GlobalTypes ist ein Fehler aufgetreten",
    "error_globaltype_groups_load_failed": "Beim Laden der GlobalType Gruppen ist ein Fehler aufgetreten",
    "error_globaltype_groups_save_failed": "Beim Speichern der GlobalType Gruppe ist ein Fehler aufgetreten",
    "error_globaltype_groups_scoring_failed": "Die Berechnung der Punktwerte für die Gruppe erreichte nicht den Schwellenwert. Bitte erhöhen Sie die Punktwerte oder verringern Sie den Schwellenwert",
    "error_globaltypes_blacklist_call_failed": "Beim Abrufen der GlobalTypes-Blacklist ist ein Fehler aufgetreten",
    "error_globaltypes_config_call_failed": "Beim Abrufen der Konfiguration der GlobalTypes Quellen ist ein Fehler aufgetreten",
    "error_globaltypes_config_edit_call_failed": "Beim Speichern der GlobalType Konfiguration ist ein Fehler aufgetreten",
    "error_hotel_call_failed": "Beim Abrufen der Hoteldaten ist ein Fehler aufgetreten",
    "error_hotel_drafting_failed": "Beim Hinzufügen eines Eintrags zum Entwurf ist ein Fehler aufgetreten",
    "error_hotel_removing_failed": "Beim Entfernen eines Eintrags vom Entwurf ist ein Fehler aufgetreten",
    "error_hotel_whitelisting_failed": "Beim whitelisten ist ein Fehler aufgetreten",
    "error_hotelpreselection_config_call_failed": "Beim Aktualisieren der Konfiguration für die Hotelvorauswahl ist ein Fehler aufgetreten",
    "error_hotelpreselection_config_fetch_call_failed": "Beim Abrufen der Konfiguration für die Hotelvorauswahl ist ein Fehler aufgetreten",
    "error_ignored_tokens_call_failed": "Beim abrufen der ignorierten Token ist ein Fehler aufgetreten",
    "error_infx_attributes_call_failed": "Beim Abrufen der Infx Attribute ist ein Fehler aufgetreten",
    "error_init_rate_rules_call_failed": "Beim Abrufen der Regeln ist ein Fehler aufgetreten",
    "error_internal_server_error": "INTERNAL SERVER ERROR - ein schwerwiegender Fehler ist aufgetreten",
    "error_languages_call_failed": "Beim Abrufen der verfügbaren Sprachen ist ein Fehler aufgetreten",
    "error_languages_call_failed_persisted": "Die Sprachen der Zimmerbeschreibungen konnten nicht geladen werden. Bitte versuchen Sie es später erneut.",
    "error_main_description_call_failed": "Beim Abrufen der Beschreibungen ist ein Fehler aufgetreten",
    "error_master_code_active_call_failed": "Beim Abrufen des Mastercode Aktivstatus ist ein Fehler aufgetreten",
    "error_network_error": "Service ist nicht verfügbar",
    "error_no_visualization_for_configuration": "Keine visuelle Anzeige für Konfigurationselement : \"{0}\".",
    "error_options_call_failed": "Beim Abrufen der Optionen ist ein Fehler aufgetreten",
    "error_permissions": "Fehler beim Abrufen der Nutzerberechtigungen",
    "error_primary_hotel_id": "Fehler beim Abrufen der Konfiguration zur primären Hotel-ID",
    "error_program_types_delete_call_failed": "Beim Entfernen der Programmarten ist ein Fehler aufgetreten",
    "error_program_types_save_call_failed": "Beim Speichern der Programmarten ist ein Fehler aufgetreten",
    "error_rating_suppliers_call_failed": "Beim Abrufen der Hotelbewertungsanbieter ist ein Fehler aufgetreten",
    "error_region_call_failed": "Beim Abrufen der verfügbaren Regionen ist ein Fehler aufgetreten",
    "error_reload": "Ein Fehler ist aufgetreten; bitte laden Sie die Seite neu.",
    "error_remarks_call_failed": "Beim Abrufen der Remarks ist ein Fehler aufgetreten",
    "error_removing_distribution_channel_failed": "Beim Entfernen der Verkaufskanäle ist ein Fehler aufgetreten",
    "error_removing_exchange_rate_from_brand_failed": "Beim Entfernen des Wechselkurses ist ein Fehler aufgetreten",
    "error_revert_whitelist_job_call_failed": "Beim Rückgängig machen des Whitelist Export ist ein Fehler aufgetreten",
    "error_room_code_config_call_failed": "Beim Abrufen der Zimmercode-Konfiguration ist ein Fehler aufgetreten",
    "error_room_codes_call_failed": "Beim Abrufen der Raumcodes ist ein Fehler aufgetreten",
    "error_room_custom_codes_call_failed": "Beim Abrufen der eigenen Codes ist ein Fehler aufgetreten",
    "error_room_main_codes_call_failed": "Beim Abrufen der Hauptcodes ist ein Fehler aufgetreten",
    "error_room_matching_configurations_call_failed": "Beim Abrufen der Codezuordnungskonfigurationen ist ein Fehler aufgetreten",
    "error_room_sub_codes_call_failed": "Beim Abrufen der Subcodes ist ein Fehler aufgetreten",
    "error_save": "Speichern fehlgeschlagen",
    "error_saving_all_board_rules_failed": "Beim Speichern der Boardregeln ist ein Fehler aufgetreten",
    "error_saving_all_main_rules_failed": "Beim Speichern der Regeln ist ein Fehler aufgetreten",
    "error_saving_brand_config_failed": "Beim Speichern der Markenkonfiguration ist ein Fehler aufgetreten",
    "error_saving_custom_rate_filters_failed": "Beim Speichern der Ratenfilter ist ein Fehler aufgetreten",
    "error_saving_descriptions_failed": "Beim Speichern der Beschreibungen ist ein Fehler aufgetreten",
    "error_saving_exchange_rate_failed": "Beim Speichern der Wechselkurse ist ein Fehler aufgetreten",
    "error_saving_hotel_data_failed": "Beim Speichern der Hoteldaten ist ein Fehler aufgetreten",
    "error_saving_rate_rules_failed": "Beim Speichern der Regeln ist ein Fehler aufgetreten",
    "error_saving_remark_failed": "Beim Speichern des Remarks ist ein Fehler aufgetreten",
    "error_saving_room_matching_config_failed": "Beim Speichern der Codezuordnungskonfiguration ist ein Fehler aufgetreten",
    "error_saving_rooms_and_boards_failed": "Beim Speichern der gewählten Zimmer und Verpfelgungen ist ein Fehler aufgetreten",
    "error_saving_rule_failed": "Beim Speichern der Regel ist ein Fehler aufgetreten",
    "error_saving_unknown_rooms_config_failed": "Beim Speichern der Konfiguration für unbekannte Räume ist ein Fehler aufgetreten",
    "error_search_descriptions_failed": "Bei der Suchanfrage ist ein Fehler aufgetreten",
    "error_single_hotel_call_failed": "Beim Abrufen der Hotel Zimmer- und Verpflegungsdaten ist ein Fehler aufgetreten",
    "error_sub_description_call_failed": "Beim Abrufen der Zimmernamen für Subcodes ist ein Fehler aufgetreten",
    "error_supplier_call_failed": "Beim Abrufen der Lieferanten ist ein Fehler aufgetreten",
    "error_supplier_rate_call_failed": "Beim Abrufen der Gelieferten Raten ist ein Fehler aufgetreten",
    "error_supplier_type_call_failed": "Beim Abrufen der Lieferantenarten ist ein Fehler aufgetreten",
    "error_title_code": "Fehler {code}",
    "error_token_ignore_call_failed": "Fehler beim ignorieren des Tokens",
    "error_token_restore_call_failed": "Fehler beim wiederherstellen des Tokens",
    "error_unknown": "Unbekannter Fehler",
    "error_unknown_rooms_config_call_failed": "Beim Abrufen der Konfiguration für unbekannte Räume ist ein Fehler aufgetreten",
    "error_unknown_rooms_types_call_failed": "Beim Abrufen der unbekannten Raumtypen ist ein Fehler aufgetreten",
    "error_updating_auto_whitelist_configuration_failed": "Beim Updaten der Konfiguration für das automatische Whitelisting ist ein Fehler aufgetreten",
    "error_updating_rating_supplier_assignment_failed": "Beim Updaten der Konfiguration für Hotelbewertungen ist ein Fehler aufgetreten",
    "error_version_call_failed": "Beim Abrufen der Software-Version ist ein Fehler aufgetreten",
    "error_while_getting_auto_whitelist_configuration": "Beim Abrufen der Konfiguration für das Auto-Whitelisting ist ein Fehler aufgetreten",
    "error_while_getting_brand_configurations": "Beim Abrufen der Markenkonfigurationen ist ein Fehler aufgetreten",
    "error_while_getting_rating_supplier_configuration": "Beim Abrufen der Konfiguration für Bewertungsanbieter ist ein Fehler aufgetreten",
    "error_while_updating_environment": "Beim Aktuallisieren der Umgebungsinformationen ist ein Fehler aufetreten",
    "error_whitelist_history_call_failed": "Beim Abrufen der Whitelist Historie ist ein Fehler aufgetreten",
    "error_whitelist_job_info_call_failed": "Die Details zum Rückgängig machen eines Whitelist Exports konnten nicht bestimmt werden",
    "error_whitelist_job_info_download_failed": "Die Details zum Rückgängig machen eines Whitelist Exports konnten nicht heruntergeladen werden",
    "events": {
        "business_context_cap": "Kontext",
        "correlation_id_cap": "Correlation ID",
        "date_cap": "Datum",
        "events_cap": "Ereignisse",
        "link": {
            "details_cap": "Details"
        },
        "link_cap": "Link",
        "message_cap": "Nachricht",
        "severity_cap": "Schweregrad",
        "time_end_cap": "Ende",
        "time_start_cap": "Start",
        "triggers_cap": "Auslöser",
        "username_cap": "Benutzer"
    },
    "ex_extra_type": "Ausflug",
    "exchange_last_export_on": "Letzer Export am",
    "exchange_rate_source_api_import": "Quelle: API Import",
    "exchange_rate_source_portfolio": "Quelle: Portfolio",
    "exchange_rate_successfully_removed": "Wechselkurs erfolgreich entfernt",
    "exchange_rate_successfully_saved": "Wechselkurs erfolgreich gespeichert",
    "exchange_rate_table_has_expired": "Wechselkurs abgelaufen",
    "exchange_rate_table_last_export": "Zuletzt exportiert am: ",
    "exchange_rate_table_never_exported": "Noch nicht exportiert",
    "exchange_rate_table_soon_expiring": "Wechselkurs läuft bald ab",
    "exchange_rate_templates_cap": "Wechselkurse",
    "exchange_rate_will_be_deleted": "Quelle: Portfolio. Dieser Wechselkurs kann vom (automatischen) Import über die API überschrieben werden",
    "exchange_rates_assigned_cap": "Zugewiesene Wechselkurse",
    "exchange_rates_never_exported": "Diese Wechselkurse wurde noch nie exportiert.",
    "exchange_rates_successfully_exported": "Wechselkurse erfolgreich exportiert",
    "exchange_rates_table_empty_table": "Keine Wechselkurse gefunden",
    "exchange_rates_table_filter_cap": "Filter",
    "exchange_rates_table_filter_inactive_nothing_selected": "Filter nicht aktiv, kein Feld ausgewählt",
    "exchange_rates_table_filter_on_cap": "Filter anwenden auf",
    "exchange_rates_table_source_currency_cap": "Quellwährung",
    "exchange_rates_table_target_currency_cap": "Zielwährung",
    "exchangerate_difference_cap": "Differenz",
    "exchangerates_info": "Die hier hinterlegten Wechselkurse werden verwendet um die Hotelverträge in die Markenwährung umzurechnen. Wenn ein Wechselkurs fehlt, können die Angebote des Hotelvertrages nicht kalkuliert und folglich nicht vertrieben werden. Sie gelten nicht für die Umrechnung von der Markenwährung in die Verkaufswährung einer Webseite. Diese Wechselkurse werden in der Kalkulation hinterlegt.",
    "exchangerates_info_delivered_by_api": "Die Portfolio Konfiguration ist so eingestellt, dass Wechselkurse über die API importiert werden können. Bitte beachten Sie, dass Änderungen über das UI durch den API Import überschrieben werden können und umgekehrt.",
    "expert": "Experte",
    "export": "Exportieren",
    "exportBoardKey_cap": "Verpflegung-ID",
    "exportRoomKeyBoardKey_cap": "Zimmer-/Verpflegung-ID",
    "exportRoomKey_cap": "Zimmer-ID",
    "export_status_exported": "Hotelvertrag erfolgreich exportiert",
    "export_status_no_distribution_channels": "Hotelvertrag ist keinem Vertriebskanal zugewiesen",
    "export_status_no_goal_id": "Hotelvertrag hat keine GOAL-ID",
    "export_status_no_rooms_and_boards": "Es konnten keine exportierbaren Zimmer/Verpflegungen gefunden werden",
    "export_status_not_whitelisted": "Hotelvertrag ist nicht auf Whitelist",
    "export_status_removed_from_whitelist": "Hotelvertrag von der Whitelist entfernt",
    "export_type_full": "Vertragsdaten",
    "export_type_hoteldata": "Hoteldaten",
    "export_type_masterdata": "Stammdaten",
    "external_app": "Externe Anwendung",
    "extra_deleted": "Zusatzleistung wurde erfolgreich gelöscht",
    "extra_max_apply_cap": "Häufigkeit Berechnung",
    "extra_of_type_added": "Die Zusatzleistung vom Typ \"{0}\" wurde hinzugefügt",
    "extra_of_type_removed": "Die Zusatzleistung vom Typ \"{0}\" wurde entfernt",
    "extras": "Zusatzleistungen",
    "extras_assign_page_btn": "Zusatzleistungen der Seite zuweisen",
    "extras_assigned": "zugewiesen",
    "extras_assigned_cap": "Zugewiesen",
    "extras_cap": "Zusatzleistungen",
    "extras_not_assigned": "nicht zugewiesen",
    "extras_overview_assigned_cap": "Zugewiesene Zusatzleistungen",
    "extras_per_page_cap": "Zusatzleistungen auf Seite",
    "feedback": "Feedback",
    "filter": "Filter",
    "filterInformation": {
        "noFilters": "Keine Filter ausgewählt: Findet alles"
    },
    "filter_airport": "Flughafen filtern",
    "filter_btn": "Filtern",
    "filter_cap": "Filter",
    "filter_city": "Stadt filtern",
    "filter_country": "Land filtern",
    "filter_region": "Region filtern",
    "filters": {
        "accommodations": "Unterkunftarten",
        "agoda": "Agoda Bewertung",
        "arrivalAirport": "Zielflughafen",
        "assignedBrands": "Zugewiesene Marken",
        "boardCode": "Verpflegung",
        "brandConfigId": "Marke",
        "category": "Hotelkategorie",
        "city": "Stadt",
        "contractId": "Vertrags ID",
        "country": "Land",
        "currency": "Währung",
        "customRate": "Benutzerdefinierte Rate",
        "deleted": "In letzter Lieferung",
        "descriptionLanguages": "Beschreibungstexte (Sprachen)",
        "distributionChannel": "Vertriebskanal",
        "distributionChannelsAssigned": "Zugewiesen zu einem Distributionskanal",
        "draftedForBrand": "Gedraftet für Marke",
        "enrichedProgramType": "Zugewiesene Programmart",
        "firstDeliverySince": "Neu seit",
        "giataIds": "GIATA-IDs",
        "globalTypes": "GlobalTypes",
        "goalIds": "GOAL-IDs",
        "hasTaxInformation": "Steuer-Informationen vorhanden",
        "holidaycheck": "HolidayCheck Bewertung",
        "hotelCode": "Hotelcode",
        "hotelKey": "Hotelschlüssel",
        "hotelName": "Hotelname",
        "lastDeliverySince": "Geändert seit",
        "localSupplier": "Hotellieferant",
        "localSuppliers": "Hotellieferant",
        "masterCode": "Mastercode",
        "masterCodeMissing": "Fehlendes Zimmer-Matching",
        "minImageCount": "Anzahl Bilder (min.)",
        "objectType": "Objekt-Typ",
        "otherContractsWhitelisted": "Hotel hat gewhitelistete Hotelverträge",
        "preselectedForCache": "Für den Cache ausgewählt",
        "region": "Region",
        "reviews": "Anzahl Bewertungen (min.)",
        "roomCode": "Zimmer",
        "scopedTooltip": "Limitiert auf",
        "source": "Lieferanteninstanz/-job",
        "status": "Status",
        "tripadvisor": "TripAdvisor Bewertung",
        "trustyou": "TrustYou Bewertung",
        "whitelistedForBrand": "Gewhitelistet für Marke"
    },
    "filterset_add_cap": "Filtersatz hinzufügen",
    "filterset_cap": "Filtersatz",
    "filterset_name_cap": "Name des Filtersatzes",
    "firstname": "Vorname",
    "flight": "Flug",
    "flight_label_cap": "Flugkennzeichnung",
    "flight_price_table_cap": "Flugpreistabelle",
    "flight_restrictions_cap": "Flug",
    "flight_restrictions_count_cap": "Flug",
    "flight_type_cap": "Hin- / Rückflug",
    "flightonly": "Nur Flug",
    "full_export_global_types_btn": "GlobalTypes jetzt exportieren",
    "giataId_cap": "GiataId",
    "giata_id": "GIATA-ID",
    "giata_ids": "GIATA-IDs",
    "global_extras_configuration_call_failed": "Beim Abrufen der Konfiguration für Zusatzleistungen ist ein Fehler aufgetreten",
    "global_extras_goal_resolution_disabled": "Auf dieser Umgebung ist die Zuweisung von Zusatzleistungen auf Hotelebene deaktiviert",
    "global_supplier_cap": "Lieferant",
    "global_type_cap": "GlobalType",
    "global_type_duplicate_warning": "Der GlobalType \"{0}\" existiert bereits für alle Marken. Wenn Sie ihn nur zu einer Marke hinzufügen wollen deaktivieren Sie zuerst den allgemein zugewiesenen.",
    "global_type_duplicate_warning_click_cap": "Klicken",
    "global_type_duplication_warnign_click_cap_end": " Sie hier um ihn anzuzeigen.",
    "global_type_group_create_cap": "GlobalType Gruppe erstellen",
    "global_type_groups_cap": "GlobalType Gruppen",
    "global_types": "GlobalTypes",
    "global_types_cap": "GlobalTypes",
    "global_types_cap_enriched": "GlobalTypes angereichert",
    "global_types_custom_rate_rules_cap": "Falls ein Raum den folgenden DRV GlobalType enthält",
    "global_types_types_cap": "GlobalTypes ( GT | ST | AT | CT )",
    "globaltype_filter_cap": "GlobalTypes",
    "globaltype_groups_delete_message": "Wollen Sie diese GlobalType Gruppe löschen?",
    "globaltype_groups_delete_title": "GlobalType Gruppe löschen",
    "globaltype_groups_deleted_successfully": "Gruppe wurde erfolgreich gelöscht",
    "globaltype_groups_saved_successfully": "GlobalType Gruppe wurde erfolgreich gespeichert",
    "globaltype_placeholder": "GlobalType eingeben",
    "globaltypes": "GlobalTypes",
    "goalId_cap": "GoalId",
    "goal_id": "GOAL-ID",
    "group_cap": "Gruppe",
    "groups_per_page_cap": "Gruppen pro Seite",
    "gt_additional_type": "AdditionalType",
    "gt_custom_type": "CustomType",
    "gt_globaltype": "GlobalType",
    "gt_source_globaltype_import": "GlobalType Import",
    "gt_source_hotel_contract": "Hotel Contract",
    "gt_source_non_bookable_content": "Non-bookable content",
    "gt_source_portfolio": "Portfolio",
    "gt_subtype": "SubType",
    "gu_extra_type": "Reiseleitung",
    "guest_restrictions_cap": "Reisende (Personentypen)",
    "guest_restrictions_count_cap": "Reisende (Personentypen)",
    "hasTaxInfo_cap": "Steuer-Informationen vorhanden",
    "here": "hier",
    "hf_extra_type": "Bearbeitungsgebühren",
    "holidaycheck": "HolidayCheck",
    "hotel": "Hotel",
    "hotel_added_to_draft": "Hotel \"{0}\" zum Entwurf hinzugefügt",
    "hotel_code_cap": "Hotelcode",
    "hotel_codes": "Hotelcodes",
    "hotel_description_cap": "Beschreibung",
    "hotel_exported_successfully": "erfolgreich exportiert",
    "hotel_images_cap": "Hotelbilder",
    "hotel_info_cap": "Hotelinformation",
    "hotel_key_cap": "Hotelschlüssel",
    "hotel_key_renamed": "früher \"Vertragsschlüssel\" genannt",
    "hotel_name_cap": "Hotelname",
    "hotel_new_added": "neu im Entwurf",
    "hotel_not_exported": "nicht exportiert",
    "hotel_on_whitelist": "auf der Whitelist",
    "hotel_ratings_supplier_label": "Hotelbewertungsanbieter",
    "hotel_removed_from_draft": "Hotel \"{0}\" vom Entwurf entfernt",
    "hotel_removed_from_whitelist": "Hotel \"{0}\" von Whitelist entfernt",
    "hotel_restrictions_cap": "Hotel",
    "hotel_restrictions_count_cap": "Hotel",
    "hotel_supplier_and_codes": "Lieferant / Hotelcodes",
    "hotel_will_be_removed": "werden von Whitelist entfernt",
    "hoteldetail": "Hotel Detail",
    "hotelonly": "Nur Hotel",
    "hotels": "Hotels",
    "hotels_added_to_draft": "{0} Hotels zum Entwurf hinzugefügt",
    "hotels_added_to_whitelist": "{0} zur Whitelist hinzugefügt",
    "hotels_cap": "Hotels",
    "hotels_per_page_cap": "Hotels auf Seite",
    "hotels_removed_from_draft": "{0} Hotels vom Entwurf entfernt",
    "hotels_removed_from_whitelist": "{0} Hotels von Whitelist entfernt",
    "hotels_successfully_whitelisted": "Hotels wurden auf Whitelist übertragen",
    "hub": "Hub",
    "hubDashboard": {
        "customer_traffic": "Kundenstatistik",
        "request_traffic": "Anfragestatistik",
        "server_load": "Serverlast"
    },
    "iata_code_cap": "Iata Code",
    "id": "Id",
    "ignored": "ignoriert",
    "image_count": "Anzahl Bilder",
    "image_load_failed": "Bilddatei nicht gefunden",
    "image_loading": "Bilddatei wird geladen",
    "in_extra_type": "Versicherung",
    "inactive_gt_source": "inaktiv",
    "inactive_gt_source_tooltip": "Diese Quelle wird nicht exportiert ( inaktiv )",
    "include_all": "alle",
    "info_text": "Informationstext",
    "infx_attributes_cap": "INFX Attribute",
    "infx_cap": "INFX",
    "infx_level_cap": "Ebene",
    "infx_level_contract": "Vertrag",
    "infx_level_hotel": "Hotel",
    "infx_level_room": "Zimmer",
    "infx_position_cap": "Position",
    "infx_value_cap": "Wert",
    "insurance_and_transfer": "Versicherungen & Transfer",
    "insurances": "Versicherungen",
    "invalid_brand_exchanges": "Die zugewiesenen Wechselkurse können so nicht exportiert werden. Es dürfen keine Zeitüberlappungen oder Zeitlücken für Währungen vorhanden sein. Überprüfen Sie bitte {source} > {target}",
    "invalid_brand_exchanges_TOO_MANY_ENTRIES": "Die zugewiesenen Wechselkurse können so nicht exportiert werden. Maximal {maximum} Wechselkurse werden unterstützt, dieser Marke sind aber {given} Wechselkurse zugewiesen.",
    "invert_cap": "Invertieren",
    "is_assigned_for": "zugewiesen für \"{brand}\"",
    "is_not_assigned_for": "nicht zugewiesen für \"{brand}\"",
    "is_not_on_whitelist_for": "nicht gewhitelistet für \"{brand}\"",
    "is_on_whitelist_for": "gewhitelistet für \"{brand}\"",
    "key_customer_rate_cap": "Benutzerdefinierte Rate",
    "key_supplier_cap": "Lieferantenschlüssel",
    "key_supplier_rate_cap": "Lieferantenpreisrate",
    "keyword": "Schlüsselwort",
    "keyword_cap": "Schlüsselwort",
    "label_cap": "Saisonkenner",
    "lang_de_de": "Deutsch",
    "lang_en_us": "English",
    "language": "Sprache",
    "language_cap": "Sprache",
    "language_wildcard": "Platzhalter ({0})",
    "languages_cap": "Sprachen",
    "last_delivered_cap": "In letzter Lieferung",
    "last_delivery_cap": "Letzte Lieferung",
    "last_modification_timestamp_cap": "Letzte Änderung",
    "lastname": "Nachname",
    "listactions_delete_confirm_btn": "Ja, löschen",
    "live_search_brand_config_delete": "Live Brand Konfiguration löschen",
    "live_search_brand_config_edit": "Live Brand Konfiguration bearbeiten",
    "live_search_brand_config_register": "Live Brand Konfiguration anlegen",
    "live_search_supplier_delete": "Live Search Lieferant löschen",
    "live_search_supplier_edit": "Live Search Lieferant bearbeiten",
    "live_search_supplier_register": "Live Search Lieferant anlegen",
    "loading": "Lade...",
    "local_supplier_cap": "Hotellieferant",
    "local_supplier_rule_cap": "Wenn der Hotellieferant",
    "login_msg": "Bitte klicken Sie auf den Anmelden-Button",
    "login_via_peakwork": "Anmelden...",
    "logout": "Abmelden",
    "lookerDashboardConfig": {
        "active": "aktiv",
        "allowedExtraFilters": "Zulässige Extra-Filter",
        "config_saved": "Looker-Konfiguration erfolgreich gespeichert",
        "config_saved_failed": "Speichern der Looker-Konfiguration fehlgeschlagen: {0}",
        "dashboardIdLabel": "Looker-Dashboard Id",
        "dashboard_saved": "Dashboard erfolgreich gespeichert",
        "dashboard_saved_failed": "Speichern von dem Looker-Dashboard fehlgeschlagen: {0}",
        "defaultFilterKey": "Standard Filter-Schlüssel",
        "defaultFilterValue": "Standard Filter-Wert",
        "lookerConfigHead": "Looker-Konfiguration",
        "lookerHost": "Looker-Host",
        "lookerSecret": "Looker-Secret",
        "models": "Looker-Models, die freigegeben werden sollen",
        "userAttribute": "User-Attribute, die gesetzt werden sollen"
    },
    "lookerDashboardNavigation": {
        "goBackButton": "Zurück",
        "goForwardButton": "Vorwärts"
    },
    "lpac": "LPAC",
    "main_code_cap": "Hauptcode",
    "main_codes_cap": "Hauptcodes",
    "main_navigation": {
        "accommodation_sales_control": "Accommodation Sales Control",
        "account_basic": "Kunde",
        "account_hub": "HotelData, GeoData, Ratings",
        "accounts": "Benutzerkonten",
        "addresses": "Adressen",
        "agencies": "Agenturen",
        "agency_numbers": "Agenturnummern",
        "assign_distribution_channel": "Vertriebskanäle",
        "assign_extras": "Zusatzleistungen",
        "boards": "Verpflegungen",
        "booking": "Buchung",
        "booking_statistics": "Buchungsstatistiken",
        "brand_portfolio": "Marken-Portfolio",
        "brand_preselection": "Markenvorauswahl",
        "brands": "Marken",
        "brands_assignment_cap": "Markenzuweisung",
        "brands_copy": "Marke Kopieren",
        "brands_overview": "Übersicht",
        "configuration": "Konfiguration",
        "configuration_basic": "Basis",
        "configuration_dhub": "D-Hub",
        "configuration_dhubcancellationrules": "Stornoregeln",
        "configuration_dhubdatamix": "Datamix Einstellungen",
        "configuration_hub": "Hub",
        "configuration_lpac": "LPAC",
        "configuration_player": "Player",
        "content": "Content",
        "contract_import": "Import der Hotelverträge im Portfolio",
        "converter_statistics": "Konverter-Statistiken",
        "custom_airports": "Eigene Zielflughäfen",
        "dashboards": "Dashboards",
        "distribution": "Vertriebskanal",
        "distribution_channels": "Vertriebskanäle",
        "dynamic_touroperator": "Verwaltung dyn. Veranstalter",
        "edf_deliveries": "EDF-Lieferungen",
        "edit_production_specification": "Produktionsspezifikation bearbeiten",
        "environment": "Umgebung",
        "events": "Ereignisse",
        "exchange_rates": "Wechselkurse",
        "expert": "Experte",
        "external": "Extern",
        "external_apps": "Externe Anwendungen",
        "extras": "Zusatzleistungen",
        "flight": "Flug",
        "fulfillment": "Fulfillment",
        "generic_form": "Generisches Formular",
        "global_type_groups": "GlobalType Gruppen",
        "home": "Start",
        "hotel": "Hotel",
        "hotel_master_data": "Hotel",
        "hub_statistics": "Hub Suchstatistiken",
        "insights": "Einblicke",
        "insurance": "Versicherung",
        "live_search_config": "Live Search",
        "live_search_config_brand": "Marken",
        "live_search_config_supplier": "Lieferanten",
        "live_search_statistics": "Live-Search-Statistiken",
        "live_supplier": "Lieferanten",
        "looker_dashboard_config": "Looker-Dashboard-Konfiguration",
        "lpac": "LPAC",
        "master_data": "Stammdaten",
        "nonbookablecontent": "Nicht-Buchbare Inhalte",
        "operation": "Betrieb",
        "overview": "Überblick",
        "package_travel_directive": "Pauschalreiserichtlinie",
        "payments": "Zahlungsarten",
        "peakworkAdmin": "Peakwork Admin",
        "player_production_summary": "Player-Produktion",
        "portfolio": "Portfolio",
        "portfolio_configuration": "Portfolio Konfiguration",
        "portfolio_management": "Portfolio Management",
        "portfolio_overview": "Portfolio Übersicht",
        "price_equality_rules": "Preisglättung",
        "production": "Produktion",
        "production_specification": "Produktions-Spezifikation",
        "program_type_enrichment": "Programmarten Hinzufügen",
        "rates": "Raten",
        "reference_data": "Stammdaten",
        "remarks": "Remarks",
        "rooms": "Zimmer",
        "scoper": "Portfolio Scoper",
        "supplier_inventory": "Lieferanteninventar",
        "supplier_performance": "Lieranten-Performance",
        "suppliers": "Lieferanten",
        "supply": "Lieferant",
        "transfer": "Transfer",
        "whitelist_export_statistics": "Whitelist Export Statistiken",
        "whitelisting": "Whitelisting",
        "whitelisting_history": "Whitelist Historie"
    },
    "mask_cap": "Wochtage (Mo-So)",
    "master_code_cap": "Mastercode",
    "master_code_override_cap": "Mastercode überschreiben",
    "master_code_rule_cap": "Regel für Mastercode",
    "master_codes_cap": "Mastercodes",
    "master_data_cap": "Stammdaten",
    "master_data_readonly": "Die Daten für diese Marke können nicht editiert werden, entweder Aufgrund eines aktiven Vorgangs (z.B. Whitelisting, Marke wird kopiert...) oder weil Sie nicht über die notwendigen Berechtigungen verfügen.",
    "max_age_cap": "Alter (max)",
    "max_booking_offset_cap": "max. Anz. Tage vor Buchung",
    "max_cap": "Max",
    "max_person_cap": "Personenanzahl (max)",
    "migration_in_progress_active_no_items": "Migration läuft, Element-Anzahl nicht verfügbar",
    "migration_in_progress_items": "Elemente migriert: {0}",
    "migration_in_progress_not_active": "Bevorstehend",
    "migration_in_progress_subtitle": "Es laufen momentan Migrationen. Das Panel steht bald wieder zur Verfügung.",
    "migration_in_progress_title": "Migration läuft",
    "min_age_cap": "Alter (min)",
    "min_booking_offset_cap": "min. Anz. Tage vor Buchung",
    "min_cap": "Min",
    "min_person_cap": "Personenanzahl (min)",
    "missing_exchange_rates_cap": "Fehlende Wechselkurse",
    "mode_cap": "Flug/Hotel",
    "move_to_reorder": "Verschieben zum Umordnen",
    "msg_activate_error": "Fehler beim Aktivieren",
    "msg_activate_success": "Erfolgreich aktiviert",
    "msg_added_all_result": "{0} hinzugefügt",
    "msg_address_delete": "Wollen Sie die Adresse \"{0}\" wirklich löschen?",
    "msg_agency_delete_cap": "Wollen Sie die Agentur \"{0}\" wirklich löschen?",
    "msg_agency_number_delete_cap": "Wollen Sie die Agenturnummer \"{0}\" wirklich löschen?",
    "msg_ambiguously_enriched_hotel_giata_id": "Automatisch ermittelte GIATA-ID könnte mehrdeutig und fehlerhaft sein",
    "msg_auto_whitelist_configurations_successfully_updated": "Konfiguration für das Automatische Whitelisting erfolgreich editiert",
    "msg_board_code_deleted_successful": "Verpflegungscode \"{0}\" erfolgreich gelöscht",
    "msg_brand_config_changed_upstream": "Die Markenkonfiguration wurde verändert. Es wird empfohlen, Änderungen zurückzusetzen.",
    "msg_brand_config_saved_successfully": "Markenkonfiguration erfolgreich gespeichert",
    "msg_brand_deactivated": "Achtung: Marke ist deaktiviert",
    "msg_brand_delete": "Wollen Sie die Marke \"{0}\" wirklich löschen?",
    "msg_brand_supplier_existing_flights": "Bei Speichern der Marke gehen alle ausgewählen Flug-Supplier verloren.",
    "msg_brand_supplier_existing_hotels": "Bei Speichern der Marke gehen alle ausgewählen Hotel-Supplier verloren.",
    "msg_brand_supplier_flight_mask": "Keine Flug-Supplier erlaubt",
    "msg_brand_supplier_hotel_mask": "Keine Hotel-Supplier erlaubt",
    "msg_brands_booking_delete": "Wollen Sie die B-Hub-Zugangsdaten \"{0}\" wirklich löschen?",
    "msg_brands_package_travel_directive_delete": "Wollen Sie die Pauschalreiserichtlinien Konfiguration \"{0}\" wirklich löschen?",
    "msg_configuration_for_rating_suppliers_successfully_updated": "Einstellungen erfolgreich gespeichert",
    "msg_custom_mastercode_missing": "Zimmer Mastercode fehlt. Kann manuell oder mittels der Panel-Enrichment-API gesetzt werden",
    "msg_custom_rate_edited_successfully": "Benutzerdefinierte Rate erfolgreich geändert",
    "msg_custom_rate_filters_saved_successfully": "Ratenfilter erfolgreich gespeichert",
    "msg_custom_rate_saved_successfully": "Benutzerdefinierte Rate erfolgreich gespeichert",
    "msg_custom_roomcodes_not_available": "Aufgrund der Gesamtcodelänge und der hohen Anzahl an Subcodes sind händische Mastercode-Überschreibungen nicht verfügbar. Die Funktionalität über die Regeln (Portfolio Management/Stammdaten/Zimmer) funktioniert weiterhin.",
    "msg_deactivate_error": "Fehler beim Deaktivieren",
    "msg_deactivate_success": "Erfolgreich deaktiviert",
    "msg_definition_not_found": "Die Konfiguration für das Formular konnte nicht geladen werden.",
    "msg_delete_confirm": "Möchten Sie wirklich alle Einträge löschen?",
    "msg_delete_error": "Löschen fehlgeschlagen",
    "msg_delete_filterset": "Wollen Sie den Filtersatz \"{0}\" wirklich löschen ?",
    "msg_delete_remark_successfully": "Remark wurde erfolgreich gelöscht",
    "msg_delete_success": "Erfolgreich gelöscht",
    "msg_descriptions_saving_successful": "Beschreibungen wurden erfolgreich gespeichert",
    "msg_distribution_channel_delete_cap": "Wollen Sie den Distribution Channel \"{0}\" wirklich löschen?",
    "msg_distribution_channels_assigned_successfully": "Vertriebskanäle wurden erfolgreich zugewiesen",
    "msg_distribution_channels_removed_successfully": "Vertriebskanäle wurden erfolgreich entfernt",
    "msg_enriched_hotel_giata_id": "Angereicherte Hotel-GIATA-ID",
    "msg_fallback_remark_text": "Standard bei fehlender Übersetzung",
    "msg_giata_id_unknown": "Die angegebene GIATA-ID ist unbekannt.",
    "msg_global_type_added_succesfully": "GlobalType wurde erfolgreich hinzugefügt",
    "msg_global_type_deleted_succesfully": "GlobalType wurde erfolgreich gelöscht",
    "msg_global_type_edited_succesfully": "GlobalType wurde erfolgreich bearbeitet",
    "msg_global_type_export_succesfully": "GlobalTypes export wurde erfolreich ausgelöst",
    "msg_goal_id_unknown": "Die angegebene GOAL-ID ist unbekannt.",
    "msg_hotel_not_found": "Hotel konnte nicht gefunden werden",
    "msg_hotel_preselection_config": "Neue Verträge wie folgt den brands hinzufügen",
    "msg_init_brand_copy_error": "Marke konnte nicht geladen werden",
    "msg_instance_not_found": "Der Datensatz existiert nicht oder konnte nicht gefunden werden.",
    "msg_insufficient_edit_permissions": "Sie verfügen nicht über die notwendigen Schreibberechtigungen",
    "msg_live_search_brand_delete": "Wollen Sie die Live Search Konfiguration für die Marke \"{0}\" wirklich löschen?",
    "msg_live_search_supplier_delete": "Wollen Sie die Live Search Konfiguration für den Lieferanten \"{0}\" wirklich löschen?",
    "msg_missing_master_code_rooms_or_boards": "Einige Zimmer oder Verpflegungen haben noch keinen Master Code und können somit nicht in den Vertrieb.",
    "msg_no_active_suppliers_configured": "Keine aktive Lieferanten für diese Marke konfiguriert!",
    "msg_no_board_restrictions": "Keine \"Verpflegung\"-Bedingungen definiert",
    "msg_no_brand_configured": "Keine Marke konfiguriert!",
    "msg_no_brand_selected": "Keine Marke ausgewählt!",
    "msg_no_charges": "Keine Aufschläge definiert",
    "msg_no_contracts_for_this_brand": "Für die gewählte Marke sind keine Hotelverträge vorhanden.",
    "msg_no_date_restrictions": "Keine \"Buchungs-/Reisezeitraum\"-Bedingungen definiert",
    "msg_no_distribution_channels_assigned_to_contract": "Es sind keine Vertriebskanäle dem Vertrag zugeordnet",
    "msg_no_distribution_channels_defined": "Es sind bislang keine Vertriebskanäle angelegt.",
    "msg_no_external_applications": "Aktuell sind keine externe Anwendungen installiert.",
    "msg_no_flight_restrictions": "Keine \"Flug\"-Bedingungen definiert",
    "msg_no_guest_restrictions": "Keine \"Reisende (Personentypen)\"-Bedingungen definiert",
    "msg_no_hotel_restrictions": "Keine \"Hotel\"-Bedingungen definiert",
    "msg_no_program_types_defined": "Es sind keine Programmarten definiert",
    "msg_no_restrictions": "Keine Bedingungen definiert",
    "msg_no_results_found": "Keine Suchergebnisse gefunden",
    "msg_no_season_restrictions": "Keine \"Season\"-Bedingungen definiert",
    "msg_no_stacks_found": "Keine Stacks gefunden.",
    "msg_no_stay_restrictions": "Keine \"Aufenthaltsdauer\"-Bedingungen definiert",
    "msg_no_suppliers_configured": "Keine Lieferanten für diese Marke konfiguriert!",
    "msg_no_weekday_restrictions": "Keine \"Reisezeitraum Wochentage\"-Bedingungen definiert",
    "msg_not_delivered_by_any_supplier": "Von keinem Hotellieferant geliefert",
    "msg_page_not_found": "Die Seite \"{0}\" konnte nicht gefunden werden.",
    "msg_payment_delete": "Wollen Sie die Zahlungsart \"{0}\" wirklich löschen?",
    "msg_rate_rule_deleted": "Rate wurde erfolgreich gelöscht",
    "msg_rate_rules_saved_successfully": "Die Regeln wurden erfolgreich gespeichert",
    "msg_room_and_board_selection_not_saveable": "Es muss mindestens ein Zimmer und eine Verpflegung ausgewählt sein, um speichern zu können",
    "msg_room_and_board_selection_saved_succesfully": "Ihre Zimmer- und Verpflegungsauswahl wurde erfolgreich gespeichert",
    "msg_room_board_brand_configuration_saved_successfully": "Zuweisungen von Codezuordnungskonfigurationen wurden aktualisiert",
    "msg_room_code_config_changed_successfully": "Zimmercode-Konfiguration erfolgreich geändert",
    "msg_room_code_deleted_successful": "Zimmercode \"{0}\" erfolgreich gelöscht",
    "msg_room_gts_incomplete": "Zimmer-GlobalTypes, die sich auf den gesamten Vertrag beziehen, werden nicht angezeigt",
    "msg_room_key_unknown": "Das Ausgewählte Zimmer konnte nicht gefunden werden",
    "msg_room_matching_configuration_saved_successfully": "Konfiguration zur Codezuordnung wurde aktualisiert",
    "msg_rule_saved_succesfully": "Regel erfolgreich gespeichert",
    "msg_save_error": "Speichern fehlgeschlagen",
    "msg_save_error_buyprice_roomcode_mismatch": "Fehler beim Speichern! Wenn 'Best Price Logic' auf 'Sellprice' gesetzt ist, muss 'Offer Grouping' auf 'Room codes and Hotel Codes' gesetzt werden",
    "msg_save_error_checkfields": "Prüfen Sie Ihre Eingabefelder",
    "msg_save_error_generic": "Speichern fehlgeschlagen: {0}",
    "msg_save_hotel_data_success": "Hoteldaten erfolgreich gespeichert",
    "msg_save_success": "Erfolgreich gespeichert",
    "msg_save_warning_remark_applies_to_all_hotels": "Remark wird auf alle Hotels angewandt, bitte bestätigen",
    "msg_saving_exchangerates_config_successful": "Konfiguration von Wechselkurs Quellen wurde erfolgreich gespeichert",
    "msg_saving_globaltypes_settings_successful": "Konfiguration wurde erfolgreich gespeichert",
    "msg_saving_hotelpreselection_config_successful": "Konfiguration der Vorauswahl erfolgreich gespeichert",
    "msg_state_change_dc_start": "Die Prozessierung der Vertriebskanäle ist abgeschlossen. Klicken Sie",
    "msg_state_change_end": " um die Hotelliste zu aktualisieren.",
    "msg_state_change_start": "Hoteldaten haben sich geändert. Klicken Sie",
    "msg_statuschange_failed_unknown": "Unerwarteter Fehler bei der Statusänderung",
    "msg_statuschange_success": "Statusänderung erfolgreich initialisiert",
    "msg_success_full_export": "Vollständiger Export erfolgreich ausgelöst",
    "msg_supplier_delete": "Wollen Sie den Lieferanten \"{0}\" wirklich löschen?",
    "msg_unknown_rooms_config_changed_successfully": "Einstellung für unbekannte Räume erfolgreich geändert",
    "msg_unsaved_changes": "Achtung: Ungespeicherte Änderungen!",
    "msg_unsaved_changes_prompt": "Sie haben einige Änderungen noch nicht gespeichert. Möchten Sie diese verwerfen?",
    "name": "Name",
    "name_cap": "Name",
    "never": "nie",
    "new": "neu",
    "new_exchange_rate_cap": "Neuen Wechselkurs anlegen",
    "new_global_type_cap": "Neuer GlobalType",
    "new_rule_btn": "Neue Regel",
    "no": "Nein",
    "no_accommodations_found": "Keine Unterkunftarten gefunden",
    "no_airports_found": "Keine Flughäfen gefunden",
    "no_board_codes_found": "Keine Verpflegungscodes gefunden",
    "no_board_descriptions": "Es wurden keine Verpflegungs-Beschreibungen für die Sprache \"{0}\" gefunden",
    "no_brand_selected_to_assign_exchange_rates": "Bitte eine Marke auswählen",
    "no_city_found": "Keine Stadt gefunden",
    "no_country_found": "Kein Land gefunden",
    "no_custom_airports_found": "Keine eigenen Zielflughäfen gefunden",
    "no_custom_rate_found": "Keine benutzerdefinierten Raten gefunden",
    "no_delivered_supplier_rates": "Keine Raten gefunden",
    "no_description_cap": "Keine Beschreibung verfügbar",
    "no_exchange_rate_templates_configured": "Es wurden keine Wechselkurse konfiguriert.",
    "no_exchange_rates_for_brand": "Dieser Marke wurden noch keine Wechselkurse zugewiesen.",
    "no_filters_selected_msg": "Keine Filter ausgewählt",
    "no_globaltype_found": "Keine GlobalTypes gefunden",
    "no_ignored_room_descriptions": "Es wurden keine ignorierten Zimmerbeschreibungen für die Sprache \"{0}\" gefunden",
    "no_images_found": "Keine Bilder gefunden",
    "no_local_supplier_selected": "Es wurden keine Lieferanten ausgewählt",
    "no_missing_exchange_rates": "Es wurden keine fehlenden Wechselkurse gefunden",
    "no_region_found": "Keine Region gefunden",
    "no_room_codes_found": "Keinen Zimmercodes gefunden",
    "no_room_descriptions": "Es wurden keine Zimmerbeschreibungen für die Sprache \"{0}\" gefunden",
    "no_room_descriptions_for_rule": "Für die ausgewählte Regel wurden keine Zimmerbeschreibungen gefunden",
    "no_tokens_to_ignore_selected": "Es wurden keine Tokens zum ignorieren ausgewählt",
    "no_tokens_to_restore_selected": "Es wurden keine Tokens zur Wiederherstellung ausgewählt",
    "none": "Keine Auswahl",
    "none_assigned": "Keine",
    "none_for_contract": "Keine für den gesamten Vertrag",
    "not_applicable_filters": "Teile ihres Filtersatzes sind in diesem Kontext nicht anwendbar",
    "not_assigned": "Nicht zugewiesen",
    "not_drafted": "nicht im Entwurf",
    "not_in_brand_filter": "durch die Markenkonfiguration beim Export exkludiert",
    "not_valid_for": "nicht gültig für",
    "not_whitelisted": "nicht whitelisted",
    "num_splits": "NumSplits (Player)",
    "number": "Nummer",
    "object_type_contract": "Vertrag",
    "object_type_product": "Produkt",
    "off": "aus",
    "ok": "OK",
    "open_application": "Anwendung öffnen",
    "open_in_new_window": "Anwendung im neuen Fenster öffnen",
    "open_provided_exchange_rates": "Umrechnungskurse abrufen",
    "operator_cap": "Operator",
    "output_cap": "Ausgabe",
    "override_room_desc_failed": "Beim Editieren der Raumbeschreibung ist ein Fehler aufgetreten",
    "override_room_desc_successfully_saved": "Raumbeschreibung erfolgreich gespeichert",
    "overrides_cap": "Überschreibt",
    "overview_cap": "Übersicht",
    "overwrite": "Überschreiben",
    "overwrite_hotel_category": "Hotelkategorie Überschreiben",
    "overwrite_supplier_remarks": "Remarks des Lieferanten überschreiben",
    "package": "Paket",
    "page_not_found": "Seite nicht gefunden",
    "panel": "Panel",
    "payment_delete": "Zahlungsart löschen",
    "payment_edit": "Zahlungsart bearbeiten",
    "payment_options_cap": "Zahlungsart",
    "payment_register": "Zahlungsart anlegen",
    "payment_template": "Zahlart",
    "percent_cap": "Prozent",
    "person_type_cap": "Personentyp",
    "pf_extra_type": "Zahlungsgebühren",
    "portfolio_config": {
        "brand": {
            "master_codes_title": "Mastercodes",
            "title": "Markeneinstellungen",
            "use_master_codes_in_distribution": "Nutze Mastercodes im Vertrieb (Hotel, Zimmer & Verpflegung)"
        },
        "exchange_rates_sources": {
            "cap": "Wechselkurs API Import",
            "options": {
                "API_IMPORT": "Ermögliche den Import über die API. Änderungen können dabei immer noch über die Benutzeroberfläche gemacht werden. Bitte beachten Sie, dass sich die Änderungen über das UI und über die API gegenseitig überschreiben."
            }
        },
        "globaltypes_sources": {
            "cap": "GlobalTypes Quellen",
            "globaltypes_blacklist": {
                "info": "Die GlobalType-Blacklist definiert GlobalTypes, die aus allen Angeboten ausgeschlossen werden. Wildcards sind am Ende von allen 4 GlobalType-Teilen erlaubt. Es ist dabei zu beachten, dass hier nur GlobalTypes aus den gelieferten Verträgen ausgeschlossen werden können.",
                "label": "GlobalTypes Blacklist"
            },
            "info": "GlobalTypes dienen zur Beschreibung von Hotelaustattungen und Merkmalen durch standardisierte Codes. Basierend auf der Liste der GlobalTypes kann ein Hotel auf verschiedenen Anbieterseiten gesucht werden. Die Liste der GlobalTypes für ein Hotel kann durch die aufgeführten Quellen gesteuert werden. In dieser Konfiguration kann entschieden werden, welche Quellen verwendet werden sollen.",
            "options": {
                "CONTENT": "Non-bookable content (GlobalTypes, die als 'non-bookable content' zum Vertrag geliefert wurden).",
                "HOTELCONTRACT": "Hotelvertrag (GlobalTypes, die mit dem Hotelvertrag geliefert wurden).",
                "IMPORT": "GlobalType Import (GlobalTypes, die über die 'Enrichment Import API' geliefert wurden).",
                "PORTFOLIO": "Portfolio (GlobalTypes, die durch Nutzer im Panel angelegt wurden)."
            }
        },
        "hotel_preselection_cap": "Markenvorauswahl",
        "primary_hotelid_cap": "Primäre Hotel-ID",
        "primary_hotelid_info": "In dieser BOX werden Hotels anhand ihrer {0} ID identifiziert.",
        "unknown_rooms": {
            "cap": "Unbekannte Räume",
            "info": "Einige Live-Lieferanten liefern keine komplette Raumliste. Diese Einstellung regelt, ob unbekannte Räume in Suchanfragen findbar sein sollen. <br> Dies kann pro Vertrag pro Marke überschrieben werden.",
            "options": {
                "EXPORT_ANY": "Alle unbekannten Räume finden",
                "EXPORT_KNOWN_ROOMS_WITH_UNKNOWN_BOARDS": "Räume mit unbekannter Verpflegung finden",
                "EXPORT_NONE_UNKNOWN": "Keine unbekannten Räume finden"
            }
        }
    },
    "post_filter_selection_tooltip": "Während der Filter: 'Fehlendes Zimmer-Matching' genutzt wird, kann das Ergebnis nicht der Whitelist oder dem Entwurf hinzugefügt oder entfernt werden",
    "post_filter_warning_cap": "Die Gesamtanzahl kann bei Nutzung des 'Fehlendes Zimmer-Matching' Filters nicht ermittelt werden",
    "preselect_for_cache": "Hotels für den Cache auswählen",
    "preselected_for_cache_cap": "Für den Cache ausgewählt",
    "preselection_all_brands_option": "Alle Marken hinzufügen",
    "preselection_blocked_warning": "Aufgrund eines Marken-Kopiervorgangs ist die ausgewählte Änderung nicht möglich.",
    "preselection_change_whitelist_cap": "Draft Veränderung",
    "preselection_no_assignment_option": "Keine Zuordnung",
    "preselection_remove_warning": "Die Änderung an der Zuweisung wird Verträge von der Draft entfernen. Ein Whitelisting muss durchgeführt werden, um diese Verträge aus der Produktion zu entfernen.",
    "process_none": "Kein Prozess aktiv",
    "productionDashboard": {
        "portfolio_overview_over_time": "Portfolio Übersicht im Zeitverlauf"
    },
    "production_specification": {
        "add_cap": "Produktionsspezifikation erstellen",
        "central_supplier_cap": "Zentraler Lieferant",
        "edit_cap": "Produktionsspezifikation bearbeiten",
        "form": {
            "calendar_toggle_title": "Zwischen relativen und absoluten Daten umschalten",
            "date_cap": "Datum",
            "evaluate_btn": "Anzahl von Hotels und Produkten evaluieren",
            "export_csv_btn": "Als CSV exportieren",
            "filter_modal_cap": "Regel-Filter bearbeiten",
            "implicit_rule": "Implizit hinzugefügte Regel: Findet alles",
            "local_supplier_placeholder": "Wähle eine beliebige Menge an Lieferanten",
            "los_cap": "Reisedauer (LOS)",
            "no_rule": "Es wurde keine Regel angelegt: Findet nichts",
            "no_supplier_request_threshold": "Kein Limit für Lieferantenanfragen gefunden",
            "no_threshold_evaluation": "Neue Evaluierung für die Berechnung des Schwellenwertes notwendig",
            "occupancies": {
                "adults_cap": "Erwachsene",
                "children_cap": "Kinder",
                "infants_cap": "Kleinkinder"
            },
            "occupancies_cap": "Belegung",
            "rule_type_cap": "Regel-Typ",
            "rules_cap": "Regeln",
            "save_tooltip": {
                "error": "Einige Eingaben sind ungültig",
                "no_changes": "Es gibt keine zu speichernden Änderungen"
            },
            "settings_cap": "Einstellungen",
            "supplier_limit_cap": "Lieferant Limit",
            "threshold_calculation_cap": "Berechnung der Angebotsschwelle",
            "threshold_value_cap": "Schwellenwert (%)",
            "total_requests_cap": "Angebote Gesamt"
        },
        "hotel_count_cap": "Anzahl Hotels",
        "last_export_summary": {
            "assigned_specifications": "Zugewiesene Spezifikationen",
            "cap": "Letzte Exportübersicht",
            "error_prompt": "Exportieren der Spezifikationen nicht möglich, bitte Umfang reduzieren",
            "estimated_requests_produced": "Geschätzte produzierte Anfragen",
            "estimated_total_offers": "Geschätzter Gesamtbetrag Anträge",
            "hotel_supplier": "Lieferant",
            "incomplete_estimate": "Unvollständige Schätzung aufgrund fehlender Daten",
            "last_export_time": "Letzter Exportzeitpunkt",
            "multiplication_factor": "Multiplikationsfaktor",
            "no_assigned_specifications": "Keine exportierten Spezifikationen für den Lieferanten vorhanden",
            "product_count": "Produktionszahl",
            "specification_name": "Name der Spezifikation",
            "state": "Status",
            "supplier_threshold": "Schwellenwert für Lieferanten",
            "threshold_update": "Aktueller Lieferanten schwellenwert hat sich seit dem letzten Export geändert",
            "threshold_value": "Schwellenwert (%)",
            "total_threshold_value": "Gesamtschwellenwert (%)",
            "warning_prompt": "Spezifikation ist fast am Limit, erwägen Sie eine Reduzierung des Umfangs"
        },
        "modal": {
            "cap": "Zugewiesene Produktionsspezifikationen",
            "error_filter_call_failed": "Fehler beim Abrufen der Produktionsspezifikationen zuweisung",
            "hotel_supplier": "Hotellieferant Code",
            "included": "Enthalten",
            "matching_rule": "Beeinflussende  Regel",
            "name": "Name",
            "no_rules_found": "Keine Regeln für dieses Hotel",
            "no_specifications_found": "Kein Produktionsspezifikationen gefunden",
            "not_preselected": "Dieses Hotel ist nicht für den Cache ausgewählt und wird daher von der Produktion ausgenommen."
        },
        "no_localsupplier": "Es gibt keine lokalen Lieferanten für diesen zentralen Lieferanten",
        "notify": {
            "activate_success": "Produktionsspezifikation wurde aktiviert",
            "assignment_error": "Fehler beim Speichern der Produktionsspezufikationszuweisungen",
            "assignment_success": "Produktionsspezifikationszuweisung erfolgreich",
            "deactivate_success": "Produktionsspezifikation wurde deaktiviert",
            "delete_error": "Produktionsspezifikation konnte nicht gelöscht werden",
            "delete_success": "Produktionsspezifikation wurde gelöscht",
            "evaluate_error": "Regeln der Produktionsspezifikation konnten nicht evaluiert werden",
            "fetch_error": "Beim Abrufen der verfügbaren Produktionsspezifikationen ist ein Fehler aufgetreten",
            "fetch_export_history_error": "Beim Abrufen der verfügbaren letzten Exportinformationen ist ein Fehler aufgetreten",
            "form_error": "Das Eingabeformular enthält ungültige Daten",
            "save_error": "Produktionsspezifikation konnte nicht gespeichert werden",
            "save_success": "Produktionsspezifikation wurde gespeichert"
        },
        "product_count_cap": "Anzahl Produkte",
        "prompt": {
            "delete": "Möchten Sie die Produktionsspezifikation '{0}' wirklich löschen?"
        },
        "scoped": "Produktionsspezifikationen können mit einem limitierten Handlungsbereich nicht editiert werden",
        "select_cap": "Produktionsspezifikationen",
        "states": {
            "ERROR": "Fehler",
            "OK": "Erfolgreich",
            "no_state": "Nicht verfügbar"
        },
        "table": {
            "error_prompt": "Überprüfen Sie den Lieferantenschwellenwert in der \"Letzte Exportübersicht\"",
            "last_export_per_supplier": "Letzter Export pro Lieferant",
            "last_export_state_cap": "Status des letzten Exports",
            "last_export_time_cap": "Letzter Export",
            "n_during_last_export": "{0} während des letzten Exports",
            "no_export": "Es gab keinen Export.",
            "no_of_suppliers_cap": "Lierferantenanzahl",
            "show_details": "Details"
        },
        "table_cap": "Produktionsspezifikationen",
        "tabs": {
            "last_export_summary": "Letzte Exportübersicht",
            "manage_specifications": "Spezifikationen verwalten",
            "search_hotel_assignments": "Hotel Zuweisung suchen"
        }
    },
    "program_type_cap": "Programmart",
    "program_type_create_placeholder": "Neue Programmart",
    "program_type_enrichment_cap": "Übersicht",
    "program_type_management_cap": "Programmarten verwalten",
    "program_type_mastercode_active_tooltip": "Aufgrund der konfigurierten Mastercoderegeln wird dieser Wert beim Export dem Mastercode hinzugefügt",
    "program_type_mastercode_warning": "Die Programmart wird erst beim Export für den Vertrieb hinzugefügt und hat eine undefinierte Länge. Dies führt dazu, dass der Mastercode in Produktion keine feste Länge mehr hat, sondern dynamisch lang werden kann (abhängig von der Programmart).",
    "program_types_cap": "Programmarten auswahl",
    "program_types_create_cap": "Programmarten",
    "program_types_delete_btn_warn": "Löschen bestätigen",
    "program_types_delete_hover": "Das Löschen der Programmart entfernt alle Zuweisungen zu Hotels. Dies kann einen Moment dauern.",
    "provided_currency_cap": "abgerufen",
    "provided_exchange_rates": "Umrechnungskurse",
    "rate_cap": "Rate",
    "ratings": {
        "value_not_set": "Kein Wert"
    },
    "rc_extra_type": "Mietwagen",
    "refresh": "Aktualisieren",
    "region_cap": "Region",
    "relationship_cap": "Beziehung",
    "relative_cap_suffix": "(relativ)",
    "release_date_title": "Veröffentlichungsdatum",
    "release_notes_description": "Die Release Notes beschreiben Änderungen und neue Funktionalitäten",
    "release_notes_header": "Box Release Notes",
    "release_time_cap": "Kontingentverfall Tage",
    "remarkFilters": {
        "brand": "Marke",
        "giataId": "GIATA-ID",
        "giataIds": "GIATA-IDs",
        "goalId": "GOAL-ID",
        "goalIds": "GOAL-IDs",
        "hotelName": "Hotelname",
        "language": "Sprache",
        "masterCode": "Mastercode",
        "title": "Titel"
    },
    "remark_cap": "Remark",
    "remark_label_cap": "Label",
    "remarks_cap": "Remarks",
    "remarks_per_page_cap": "Remarks auf Seite",
    "remove": "Entfernen",
    "remove_brands": "Marken entfernen",
    "remove_distribution_channel_btn": "Vertriebskanäle entfernen",
    "remove_distribution_channel_cap": "Vertriebskanäle löschen",
    "remove_draft_all_btn": "Ergebnis vom Entwurf entfernen",
    "remove_draft_page_btn": "Seite vom Entwurf entfernen",
    "remove_extras_btn": "Zusatzleistungen entfernen",
    "remove_from_draft": "vom Entwurf entfernen",
    "remove_program_types_cap": "Programmarten entfernen",
    "request_cap": "auf Anfrage",
    "required": "benötigt",
    "required_field": "Pflichtfeld",
    "required_fields": "Pflichtfelder",
    "required_fields_tooltip": "Pflichtfelder werden automatisch vorgeschoben, wenn sie unten nicht ausgewählt wurden",
    "reset": "Zurücksetzen",
    "reset_rule": "Regel zurücksetzen",
    "restrictions_cap": "Bedingungen",
    "restrictions_count_cap": "Bedingungen (Anzahl)",
    "reviews": "Bewertungen",
    "rf_extra_type": "Zug und Flug",
    "roomOverrides": {
        "filters": {
            "contractId": "Contract Id",
            "roomKey": "Room Key"
        },
        "table": {
            "cap": "Raumüberschreibungen",
            "data": {
                "disableLinkText": "Nicht verfügbar"
            },
            "emptyText": "Keine Raumüberschreibungen gefunden",
            "header": {
                "contractId": "Contract Id",
                "description": "Raumbeschreibung ({0})",
                "giataId": "GIATA ID",
                "goalId": "GOAL ID",
                "hotelName": "Hotelname",
                "link": "Details",
                "localSupplierCode": "Supplier Code",
                "mainRoomTag": "Hauptraumkenner",
                "masterCode": "Überschreiben des Mastercodes",
                "roomKey": "Room Key",
                "subRoomTags": "Subraum"
            }
        }
    },
    "room_board_leave_page_confirm": "Ohne Speichern verlassen",
    "room_board_leave_page_message": "Möchten Sie die Seite ohne Speichern verlassen ?",
    "room_board_leave_page_title": "Ungespeicherte Änderungen",
    "room_board_leave_table_confirm": "Ohne Speichern verlassen",
    "room_board_leave_table_message": "Möchten Sie die Ansicht ohne Speichern verlassen ?",
    "room_cap": "Zimmer",
    "room_code_assigned": "enthält, dann verwende den Zimmertyp",
    "room_code_cap": "Zimmercode",
    "room_code_not_found": "Zugeordneter Zimmercode konnte nicht gefunden werden",
    "room_detail": {
        "allotment_view": {
            "avail": {
                "NO_ALLOTMENTS": "Kein Kontingent"
            },
            "day": "Tag",
            "options": {
                "half_year": "6 Monate",
                "year": "12 Monate"
            }
        },
        "offer_view": {
            "allotment": "Kontingent",
            "arrival_date": "Ankunftsdatum",
            "avail": {
                "AVAILABLE": "Verfügbar",
                "BOOKED_OUT": "Ausgebucht",
                "ON_REQUEST": "Auf Nachfrage",
                "STOP_SALE": "Verkaufsstopp"
            },
            "avg_night": "Durchschn. Nacht",
            "avg_stay": "Durchschn. Buchung",
            "currency": "Alle Preise in {0}",
            "date_range": "Ergebnisse von {0} bis einschl. {1}",
            "details": {
                "availability_cap": "Verfügbarkeit",
                "card_cap": "Angebotsdetails",
                "date_and_los_cap": "Datum und Dauer",
                "no_offer_found": "Für das gewählte Datum konnte kein Angebot gefunden werden.",
                "no_selection": "Kein Angebot ausgewählt. Klicke auf eine Zelle in der Ergebnisstabelle unten um Details anzusehen.",
                "pax_prices_cap": "Passagierpreise",
                "price_cap": "Preis"
            },
            "error": {
                "bad_los": "Ungültige Dauer (LOS). Die Zahl muss zwischen 1 und {0} liegen, einschließlich.",
                "bad_pax": "Ungültiges Passagieralter. Das Alter muss positiv sein.",
                "generic": "Beim Abrufen der Angebotsinformationen ist ein Fehler aufgetreten.",
                "no_match_hotel": "Hotel konnte nicht gefunden werden",
                "no_match_room": "Zimmer konnte nicht gefunden werden",
                "no_occupancies": "Für diesen Raum existieren keine Angebote mit dieser Belegung.",
                "no_offers": "Für diese Konfiguration wurden keine Angebote gefunden.",
                "no_results": "Es wurden keine Angebote gefunden."
            },
            "filter": {
                "arrival_date": "Ankunftsdatum",
                "arrival_date_placeholder": "Erstmöglich",
                "paxes": "Passagiere (Alter)"
            },
            "no_data_for_live_suppliers": "Diese Ansicht wird für Live Search Lieferanten nicht unterstützt",
            "no_results": "Keine Ergebnisse",
            "price_info": "Unberechnete Lieferantenpreise",
            "results_cap": "Ergebnisse",
            "show": "Zeige",
            "show_offers_title": "Angebote anzeigen"
        },
        "tabs": {
            "allotments": "Verfügbarkeiten",
            "list": "Liste",
            "master_code": "Per Mastercode",
            "offers": "Angebote"
        },
        "tabs_cap": "Zimmer und Verpflegungen"
    },
    "room_images": "Zimmer Bilder",
    "room_information_title": "Zimmerdetails",
    "room_key_cap": "Zimmerschlüssel",
    "room_master_code_descriptions": "Raumbeschreibungen",
    "room_matching_configuration_title": "Codezuordnungskonfiguration für Zimmer",
    "room_matching_configurator": {
        "add_buttons": {
            "code_sequence": "Zähler hinzufügen",
            "matching_character_code": "Konstante hinzufügen",
            "sub_code": "Subcode(s) hinzufügen"
        },
        "add_room_config": "Neue Konfiguration",
        "append_custom_rate_descriptions": "Benutzerdefinierte Ratenbeschreibungen anhängen",
        "append_custom_rate_descriptions_max_room_descriptions_size": "Maximale Raumgrößenbeschreibung",
        "auto_matching": "Automatische Codezuordnung",
        "cancel_room_config_creation": "Erstellen abbrechen",
        "cancel_room_config_edit": "Konfigurationsbearbeitung abbrechen",
        "caps": {
            "CONSTANT": "Konstante",
            "MAIN_CODE": "Maincode",
            "SEQUENCE": "Länge des Zählers",
            "SUB_CODE": "Subcode"
        },
        "configurator": "Konfigurator",
        "delete_room_config": "Konfiguration löschen",
        "edit_room_config": "Konfiguration bearbeiten",
        "one_of_two_must_be_activated": "\"{0}\" muss aktiviert sein, wenn \"{1}\" nicht aktiviert ist",
        "overwrite_room_descriptions": "Überschreibe Raumbeschreibungen",
        "preview": "CODE VORSCHAU",
        "reset_configuration": "Konfiguration zurücksetzen",
        "room_configuration_copy": "Existierende Konfiguration kopieren",
        "room_configuration_set_as_default": "Konfiguration as Standard definieren",
        "room_name_prompt": "Name der Konfiguration",
        "set_code_length": "Länge eines Main-/Subcodes",
        "validations": {
            "config_name_already_used": "Konfigurationsname existiert bereits",
            "main_code_position": "Maincode muss an erster Stelle stehen",
            "matching_type_auto_cannot_contain": "Konstanten oder Zähler sind nur erlaubt, wenn die Raumbeschreibungen nicht überschrieben werden",
            "matching_type_none_cannot_contain": "Konstanten oder Zähler sind nur erlaubt, wenn die Codes automatisch zugeordnet werden",
            "missing_form_data": "Erforderliche Eingaben fehlen",
            "none_selected": "Keine automatische Codezuordnung",
            "rule_is_valid": "Regel ist gültig",
            "semi_auto_selected": "Die Beschreibungen des Lieferanten werden exportiert",
            "sequence_digits_positive": "Die Anzahl der Zeichen im Zähler muss positiv sein",
            "toma_warning": "Konfiguration ist nicht TOMA-konform"
        }
    },
    "room_name_cap": "Zimmername",
    "room_names_code_cap": "Zimmer-/Verpflegungsname ",
    "room_rule_added_successfully": "Regel erfolgreich angelegt",
    "room_rule_deleted_successfully": "Regel erfolgreich gelöscht",
    "room_rule_edited_successfully": "Regel erfolgreich editiert",
    "room_tag_cap": "Raumkenner",
    "room_tag_sub_cap": "Sub-Raumkenner",
    "rooms": "Zimmer",
    "rooms_and_boards_cap": "Zimmer und Verpflegungen",
    "rooms_cap": "Zimmer",
    "rooms_count_cap": "Anzahl Zimmer",
    "roomsandboards": "Zimmer und Verpflegung",
    "rounding_cap": "Rundung",
    "rule": "Regel",
    "rule_already_exists": "Regel existiert bereits",
    "rule_cap": "Regel",
    "rule_matches": "Regel \"{0}\" trifft",
    "rule_matches_modal_description": "Die gezeigten Treffer beziehen sich nur auf die aktuelle Regel und beachten nicht die Regel-Prioritäten",
    "rule_matches_no_active_supplier": "Unbekannter Hotellieferant",
    "rule_preview": "Regelvorschau",
    "rule_right_length": "Die Regel hat die korrekte Länge",
    "rule_too_long": "Die Regel ist zu lang",
    "rule_too_short": "Die Regel ist zu kurz",
    "rule_types": {
        "CONSTANT": "Konstante",
        "IATA_CODE": "Airport Code",
        "PROGRAM_TYPE": "Programmart",
        "SEQUENCE": "Zähler"
    },
    "rules_cap": "Regeln",
    "save": "Speichern",
    "save_and_apply": "Speichern & Anwenden",
    "save_selected": "Ausgewählte Umrechnungskurse übernehmen",
    "score": "Punktwert",
    "search": "suchen",
    "search_cap": "Suche",
    "select_airports": "Flughäfen auswählen",
    "select_board_tag": "Verpflegungskenner auswählen",
    "select_brand": "Marke auswählen",
    "select_currency": "Währung wählen",
    "select_custom_board_code": "eigener Verpflegungscode",
    "select_custom_room_code": "eigener Zimmercode",
    "select_distribution_channel": "Vertriebskanal wählen",
    "select_env": "Umgebungstyp wählen",
    "select_filterset": "Filtersatz auswählen",
    "select_language": "Sprache wählen",
    "select_room_code": "Zimmercode wählen",
    "select_room_matching_config": "Wähle eine Codezuordnungskonfiguration",
    "select_room_tag": "Raumkenner auswählen",
    "select_supplier": "Lieferant wählen",
    "select_supplier_cap": "Lieferant wählen",
    "selected": "Ausgewählt",
    "selected_cap": "Ausgewählt",
    "selected_fields": "Ausgewählte Felder",
    "selected_filter_criteria": "Ausgewählte Filteroptionen",
    "selected_globaltypes_blacklist_cap": "Ausgewählte Blacklist",
    "selected_globaltypes_filter_cap": "Ausgewählte GlobalTypes",
    "selected_rooms_cap": "Gewählte Zimmer",
    "sequence_cap": "Sequenz (Tage)",
    "show": "Anzeigen",
    "show_globaltype": "anzeigen",
    "show_hide": "anzeigen / verbergen",
    "show_hotels_for_remark": "Zeige Hotels für dieses Remark",
    "show_infx": "anzeigen",
    "show_ratefilter_excluded_rooms_cap": "Zeige Zimmer, die nicht vom Ratenfilter inkludiert werden",
    "sidebar": {
        "ascAssignment": {
            "abbr": "ASC",
            "description": "Änderung der Accommodation Sales Control Einschränkungen auf die folgenden Marken:",
            "title": "Accommodation Sales Control Einschränkungen"
        },
        "automatchingConfigChange": {
            "abbr": "AC",
            "description": "Automatische Codezuordnungskonfigurationsänderungen werden angewandt",
            "title": "Automatische Codezuordnungskonfigurationsänderungen"
        },
        "brandAssignmentInProgress": {
            "abbr": "MVA",
            "description": "Markenvorauswahlsänderungen werden angewandt",
            "title": "Markenvorauswahlsänderungen"
        },
        "brandcopy": {
            "abbr": "MK",
            "description": "Marken werden kopiert:",
            "title": "Markenkopiervorgang"
        },
        "contract_import": {
            "abbr": "IHV",
            "description": "Importiere Hotelverträge für folgende Lieferanten:",
            "title": "Import Hotelverträge"
        },
        "customRates": {
            "abbr": "RBR",
            "description": "Änderungen an Regeln für benutzerdefinierte Raten werden angewandt. Alle Marken sind derzeit gesperrt.",
            "title": "Regeln für Benutzerdefinierte Raten"
        },
        "dcAssignment": {
            "abbr": "ZDK",
            "description": "Zuweisung von Distributionskanälen werden für folgende Marken angewandt:",
            "title": "Zuweisung von Distrubutionskanälen"
        },
        "draft": {
            "abbr": "DRA",
            "description": "Entwurfsänderungen werden für folgende Marken angewandt:",
            "title": "Entwurfsänderung (Draft)"
        },
        "global_extras_assignment": {
            "abbr": "ZZL",
            "description": "Weise für folgende marken Zusatzleistungen zu",
            "title": "Zuweisung von Zusatzleistungen"
        },
        "global_extras_export": {
            "abbr": "EZL",
            "description": "Exportiere Zusatzleistungen für folgende Marken:",
            "title": "Exportiere Zusatzleistungen"
        },
        "global_extras_import": {
            "abbr": "IZL",
            "description": "Importiere Zusatzleistungen",
            "title": "Importiere Zusatzleistungen"
        },
        "globaltypes_export": {
            "abbr": "EGT",
            "description": "Exportiere Globaltypes für folgende Marken:",
            "title": "Exportiere GlobalTypes"
        },
        "migrations": {
            "abbr": "MIG",
            "description": "die folgenden Migrationen werden angewandt:",
            "title": "Migrationen"
        },
        "productionSpecificationAssignmentInProgress": {
            "abbr": "ZPS",
            "description": "Zuweisungen von Produktionsspezifikationen werden angewandt",
            "title": "Zuweisung von Produktionsspezifikationen"
        },
        "wlProcess": {
            "abbr": "EWL",
            "description": "Exportiere Hotelverträge für folgende Marken:",
            "title": "Exportiere Whitelist"
        }
    },
    "sk_extra_type": "Skipass",
    "source_cap": "Lieferanteninstanz/-job",
    "source_rate_cap": "Quelle",
    "sources_cap": "Herkunft",
    "stage_cap": "Umgebungstyp",
    "stars": "Sterne",
    "stars_cap": "Sterne",
    "start_cap": "Start",
    "start_date_cap": "Startdatum",
    "started_at": "Gestartet",
    "status_cap": "Status",
    "stay_period_cap": "Aufenthaltszeitraum",
    "stay_restrictions_cap": "Aufenthaltsdauer",
    "stay_restrictions_count_cap": "Aufenthaltsdauer",
    "street_cap": "Straße",
    "sub_codes_cap": "Subcodes",
    "sub_tags_cap": "Sub-Raumkenner",
    "submit": "Speichern",
    "subtype_placeholder": "SubType eingeben",
    "successfully_saved_filterset": "Filtersatz erfolgreich gespeichert",
    "supplier": "Lieferant",
    "supplierDashboard": {
        "last_edf_deliveries": "Letzte vollständigen EDF-Lieferungen"
    },
    "supplier_board_code_cap": "Gelieferter Verpflegungstyp",
    "supplier_board_key_cap": "Gelieferter Verpflegungschlüssel",
    "supplier_cap": "Lieferant",
    "supplier_delete": "Lieferant löschen",
    "supplier_edit": "Lieferant bearbeiten",
    "supplier_hotel_cap": "Hotellieferant",
    "supplier_register": "Lieferant anlegen",
    "supplier_sources_cap": "Quelle (Lieferant)",
    "suppliers": "Lieferanten",
    "suppliers_cap": "Lieferanten",
    "suppliers_type_custom_alert": "curl -X url/{0}",
    "target_currency_cap": "Zielwährung",
    "target_rate_cap": "Ziel",
    "technical_source_cap": "Quelle",
    "technical_sources_cap": "Quelle (technisch)",
    "the_custom_rate_cap": "Die benutzerdefinierte Rate",
    "threshold": "Schwellenwert",
    "title_cap": "Titel",
    "to": "bis",
    "to_draft_btn": "Merken",
    "today": "heute",
    "token_ignored_successfully": "Token wurde erfolgreich ignoriert",
    "token_restored_successfully": "Token wurde erfolgreich wiederhergestellt",
    "tokens_ignored_successfully": "Tokens wurde erfolgreich ignoriert",
    "tokens_restored_successfully": "Tokens wurden erfolgreich wiederhergestellt",
    "tooltip_add_token_as_rule_btn": "Regel erstellen",
    "tooltip_board_mastercode_missing": "Verpflegungscode nicht eingespielt",
    "tooltip_hotel_contract_delivered": "Hotelvertrag in letzter Lieferung",
    "tooltip_hotel_contract_drafted": "Hotelvertrag ist im Entwurf",
    "tooltip_hotel_contract_not_delivered": "Hotelvertrag nicht in letzter Lieferung. Entfernt am {0}",
    "tooltip_hotel_contract_not_drafted": "Hotelvertrag ist nicht im Entwurf",
    "tooltip_hotel_contract_not_whitelisted": "Hotelvertrag ist nicht auf Whitelist",
    "tooltip_hotel_contract_whitelisted": "Hotelvertrag ist auf Whitelist",
    "tooltip_ignore_token_btn": "ignorieren",
    "tooltip_no_rooms_selected": "Keine Zimmer für den Export ausgewählt",
    "tooltip_restore_token_btn": "wiederherstellen",
    "tooltip_room_custom_overrides_deactivated": "Benutzerdefinierte Zimmercodes können für die ausgewählte Konfiguration nicht angewandt werden.",
    "tooltip_room_mastercode_missing": "Zimmercode nicht eingespielt",
    "total_board_codes": "Verpflegungscodes gesamt:",
    "total_contracts_cap": "Verträge gesamt:",
    "total_contracts_on_page_cap": "Verträge auf Seite:",
    "total_custom_rates": "Raten gesamt:",
    "total_extras_cap": "Zusatzleistungen gesamt:",
    "total_groups_cap": "Gruppen gesamt",
    "total_hotels_cap": "Hotels gesamt:",
    "total_hotels_on_page_cap": "Hotels auf Seite:",
    "total_remarks_cap": "Remarks gesamt:",
    "total_room_codes": "Zimmercodes gesamt:",
    "total_rooms_cap": "Zimmer gesamt",
    "touroperator_code_cap": "Lieferant der Zusatzleistung",
    "tr_extra_type": "Transfer",
    "travel_type_cap": "Reiseart",
    "trigger_full_export_btn": "vollständigen Export auslösen",
    "tripadvisor": "TripAdvisor",
    "trustyou": "TrustYou",
    "tt_missing_permissions": "Sie verfügen nicht über die notwendigen Berechtigungen",
    "type": "Typ",
    "type_cap": "Typ",
    "unknown_airport": "Unbekannter Flughafen",
    "unknown_brand": "unbekannte Marke",
    "unknown_rooms": {
        "card_cap": "Unbekannte Räume",
        "info": "Einige Live-Lieferanten liefern keine komplette Raumliste. Diese Einstellung regelt für die aktive Marke, ob unbekannte Räume in Suchanfragen findbar sein sollen.",
        "list_was_complete": "Alle Räume sind bekannt",
        "options": {
            "EXPORT_ANY": "Alle unbekannten Räume finden",
            "EXPORT_KNOWN_ROOMS_WITH_UNKNOWN_BOARDS": "Räume mit unbekannter Verpflegung finden",
            "EXPORT_NONE_UNKNOWN": "Keine unbekannten Räume finden",
            "null": "Standardeinstellung aus der Portfoliokonfiguration ({0})"
        },
        "options_fallback": {
            "EXPORT_ANY": "alle",
            "EXPORT_KNOWN_ROOMS_WITH_UNKNOWN_BOARDS": "auch bei unbekannter Verpflegung",
            "EXPORT_NONE_UNKNOWN": "keine",
            "null": "unbekannt"
        }
    },
    "unknown_supplier": "unbekannter Lieferant",
    "unmatched": "nicht getroffen",
    "update_auto_whitelist_configuration_warning": "Durch das Aktivieren des Auto-Whitelistings <b>wird ein automatisches Whitelisting und Export für all Verträge der Marke ausgelöst.</b>",
    "update_brand_config_cap": "Markeneinstellungen",
    "update_brand_configuration_message": "Wollen Sie wirklich die Markenkonfiguration ändern?",
    "update_custom_rate_filters": "Um die geänderte Selektion der Räume anzuwenden, <b>wird ein Export für all Verträge der Marke ausgeführt</b>.",
    "update_master_codes_in_distribution_warning": "Durch das Ändern der Nutzung von Mastercodes <b>wird ein Export für all Verträge der Marke ausgeführt</b>.",
    "update_rating_config_description": "Änderungen der Hotelbewertungsanbieter können Auswirkungen auf gespeicherte Filtersätze haben.",
    "update_room_matching_brand_assignment": "Für eine geänderte Konfiguration zur Codezuordnung <b>wird ein Export für all Verträge der Marke ausgeführt</b>. Außerdem werden <b>alle manuell zugewiesenen Mastercodes entfernt!</b>",
    "usage": "Verwendung",
    "validation": {
        "greaterEqualThanXy": "{0} muss größer oder gleich {1} sein",
        "minValue": "Muss ein Minimum von {0} sein",
        "required": "Dieses Feld ist erforderlich"
    },
    "validation_error_mastercode_constant_empty": "Bitte geben Sie eine Konstante an",
    "validator_alphaNum": "{0} darf nur Buchstaben und Zahlen beinhalten.",
    "validator_alphaNumExt": "{0} darf nur alphanumerische sowie Leerzeichen enthalten.",
    "validator_alphaNumHyphen": "{0} darf nur Großbuchstaben, Zahlen sowie Bindestriche enthalten.",
    "validator_alphaSpaces": "{0} darf nur Buchstaben und Leerzeichen enthalten",
    "validator_alpha_num_no_case_hyphen": "{0} darf nur Buchstaben, Zahlen und Bindestriche enthalten",
    "validator_at_least_one_globaltype_required": "Mindestens ein GlobalType wird benötigt",
    "validator_decimalExt": "{0} darf nur Zahlen, Punkt oder Komma als Dezimaltrenner beinhalten.",
    "validator_different_currencies": "Hotelvertrags- und Markenwährung müssen sich unterscheiden",
    "validator_duplicate_attribute": "{0} existiert bereits.",
    "validator_duplicate_rule": "Regel existiert bereits.",
    "validator_global_type_name": "Name darf nur Großbuchstaben und Zahlen beinhalten",
    "validator_letters_env": "{0} darf nur aus Buchstaben, Zahlen, Leerzeichen und Unterstrichen bestehen",
    "validator_maxLength": "{0} darf maximal {1} Zeichen lang sein.",
    "validator_minLength": "{0} muss mindestens {1} Zeichen lang sein.",
    "validator_multiInteger": "{0} darf nur mit Kommata abgetrennte Zahlen beinhalten.",
    "validator_no_specific_character": "Eingabe darf das Zeichen \"{0}\" nicht enthalten",
    "validator_numberExt": "{0} darf nur Zahlen beinhalten.",
    "validator_numeric": "{0} darf nur Zahlen beinhalten.",
    "validator_positive_numbers": "{0} darf nur positive ganzzahlige Werte beinhalten",
    "validator_regex": "{0} muss zum Pattern {1} passen.",
    "validator_required": "{0} ist ein Pflichtfeld.",
    "validator_required_remark_info_confirmation": "Bitte einen Informationstext und/oder Bestätigungstext eingeben.",
    "validator_start_before_end": "Das Startdatum muss vor dem Enddatum liegen.",
    "validator_unique": "{0} darf nur einmal vorkommen.",
    "validator_upperCase": "{0} darf nur aus einem Großbuchstaben bestehen.",
    "validator_upperCaseAndNumber": "{0} darf nur Großbuchstaben und Zahlen enthalten.",
    "validator_upperCaseOrNumber": "{0} darf nur aus einer Zahl oder einem Großbuchstaben bestehen.",
    "validator_valid": "{0} ist ungültig.",
    "version": "Version",
    "version_title": "Version",
    "vilauma_types_board": {
        "AI": "All Inclusive",
        "AI+": "All Inclusive plus",
        "AO": "Übernachtung",
        "BB": "Frühstück",
        "FB": "Vollpension",
        "FB+": "Vollpension plus",
        "HB": "Halbpension",
        "HB+": "Halbpension plus",
        "SC": "Selbstverpflegung",
        "XX": "laut Programm"
    },
    "vilauma_types_room": {
        "AP": "Apartment",
        "BU": "Bungalow",
        "CA": "Kabine",
        "CH": "Chalet",
        "CT": "Cottage",
        "DL": "Deluxe Zimmer",
        "DP": "Duplex Zimmer",
        "DR": "Doppelzimmer",
        "ER": "Economy Zimmer",
        "FC": "Finca",
        "FR": "Familienzimmer",
        "HA": "Behindertengerechtes Zimmer",
        "HB": "Hausboot",
        "JS": "Juniorsuite",
        "MA": "Maisonette",
        "MB": "Mehrbettzimmer",
        "MH": "Wohnwagen",
        "PH": "Penthouse",
        "SP": "Superior Zimmer",
        "SR": "Einzelzimmer",
        "ST": "Studio",
        "SU": "Suite",
        "TR": "Dreibettzimmer",
        "VF": "Ferienwohnung",
        "VH": "Ferienhaus",
        "VI": "Villa",
        "WB": "Wasserbungalow",
        "XX": "laut Programm"
    },
    "warning_whitelisting_needed_custom_rate": "Die Regeln für benutzerdefinierte Raten wurden geändert und diese Marke nutzt einen Ratenfilter. Um den Filter erneut anzuwenden, muss ein komplettes Whitelisting durchgeführt werden (\"vollständiger Export\" oder mit leerem Filter).",
    "warning_whitelisting_needed_preselection": "Verträge, die auf der Whitelist waren, wurden durch die Markenvorauswahl von der Whitelist entfernt. Um diese aus der Produktion zu entfernen, muss ein Whitelisting durchgeführt werden.",
    "weekday_cap": "Wochentag",
    "weekday_restrictions_cap": "Reisezeitraum Wochentage",
    "weekday_restrictions_count_cap": "Reisezeitraum Wochentage",
    "whitelist": "Whitelist",
    "whitelist_changes": "Whitelist-Änderungen pro Land",
    "whitelist_changes_download_button": "Änderungen herunterladen",
    "whitelist_changes_download_error": "Whitelist-Änderungen konnten nicht geladen werden.",
    "whitelist_filter_from_draft_added_btn": "Hinzugefügte whitelisten",
    "whitelist_filter_from_draft_btn": "Ergebnis whitelisten",
    "whitelist_filter_from_draft_removed_btn": "Entfernte whitelisten",
    "whitelist_filter_ignored": "Dieser Filter hat beim Whitelisten keinen Effekt",
    "whitelist_history": {
        "contracts_added_cap": "Zur Whitelist hinzugefügte Verträge",
        "contracts_removed_cap": "Von Whitelist entfernte Verträge",
        "link_cap": "Export Statistiken",
        "revert_whitelist_job": "Rückgängig machen",
        "revert_whitelist_job_cap": "Whitelist Export rückgängig machen",
        "time_after_cap": "Start",
        "time_before_cap": "Ende",
        "time_of_occurrence_cap": "Zeitpunkt",
        "username_cap": "Nutzername",
        "whitelist_history_cap": "Whitelist Historie",
        "whitelist_job_cap": "Whitelist Export",
        "whitelist_job_download_info": "Änderungen herunterladen",
        "whitelist_job_id_cap": "Export ID",
        "whitelist_job_show_info": "Details",
        "whitelist_revert_changes_cap": "Änderungen, wenn dieser Whitelist Export rückgängig gemacht wird"
    },
    "whitelist_info": "{0} Verträge für {1} Hotels",
    "whitelist_info_button_show": "Whitelist Details",
    "whitelist_info_error": "Whitelist-Details konnten nicht bestimmt werden.",
    "whitelist_status": {
        "ADDING": "Füge hinzu:",
        "REMOVING": "Entferne:",
        "SKIPPING": "Ignoriere, weil sich der Whitelist Status in der Zwischenzeit geändert hat:"
    },
    "whitelist_total_change": "Ändere insgesamt {0} Verträge für {1} Hotels",
    "whitelisted": "Whitelisted",
    "whitelisted_cap": "Aktiv",
    "xx_extra_type": "Sonstiges",
    "yes": "Ja"
};
