/**
 * All available permissions as they are defined in the mayfly-authanticator-data project.
 * Not all of them will be needed in this project.
 */
export enum Permission {
    // WILDCARD = '*', // equivalent to not using a permission restriction in the first place
    PORTFOLIO_READ = 'PORTFOLIO:READ',
    PRESELECTION_READ = 'PRESELECTION:READ',
    PRESELECTION_WRITE = 'PRESELECTION:WRITE',
    PRODSPEC_ASSIGNMENT_READ = 'PRODUCTION_SPECIFICATION_ASSIGNMENT:READ',
    PRODSPEC_ASSIGNMENT_WRITE = 'PRODUCTION_SPECIFICATION_ASSIGNMENT:WRITE',
    PRODSPEC_READ = 'PRODUCTION_SPECIFICATION:READ',
    PRODSPEC_WRITE = 'PRODUCTION_SPECIFICATION:WRITE',
    DRAFT_READ = 'DRAFT:READ',
    DRAFT_WRITE = 'DRAFT:WRITE',
    FILTERSET_WRITE = 'FILTERSET:WRITE',
    GLOBALTYPE_WRITE = 'GLOBALTYPE:WRITE',
    GLOBALTYPE_EXPORT = 'GLOBALTYPE:EXPORT',
    WHITELIST = 'WHITELIST',
    WHITELIST_HISTORY_READ = 'WHITELIST_HISTORY:READ',
    WHITELIST_HISTORY_REVERT = 'WHITELIST_HISTORY:REVERT',
    EXTRA_ASSIGNMENT_READ = 'EXTRA_ASSIGNMENT:READ',
    EXTRA_ASSIGNMENT_WRITE = 'EXTRA_ASSIGNMENT:WRITE',
    DC_ASSIGNMENT_READ = 'DC_ASSIGNMENT:READ',
    DC_ASSIGNMENT_WRITE = 'DC_ASSIGNMENT:WRITE',
    PROGRAM_TYPE_READ = 'PROGRAM_TYPE:READ',
    PROGRAM_TYPE_WRITE = 'PROGRAM_TYPE:WRITE',
    EXCHANGE_RATE_READ = 'EXCHANGE_RATE:READ',
    EXCHANGE_RATE_WRITE = 'EXCHANGE_RATE:WRITE',
    ASC_READ = 'ASC:READ',
    ASC_WRITE = 'ASC:WRITE',
    ASC_EXPORT = 'ASC:EXPORT',
    SCOPE_READ = 'SCOPE:READ',
    SCOPE_WRITE = 'SCOPE:WRITE',
    MASTER_HOTEL_READ = 'MASTER_HOTEL:READ',
    MASTER_CODE_SCHEMA_WRITE = 'MASTER_CODE_SCHEMA:WRITE',
    ROOM_MATCHING_READ = 'ROOM_MATCHING:READ',
    ROOM_MATCHING_WRITE_SCHEMA = 'ROOM_MATCHING:WRITE:SCHEMA',
    ROOM_MATCHING_WRITE_CODE = 'ROOM_MATCHING:WRITE:CODE',
    ROOM_MATCHING_WRITE_RULE = 'ROOM_MATCHING:WRITE:RULE',
    ROOM_MATCHING_WRITE_TEMPLATE = 'ROOM_MATCHING:WRITE:TEMPLATE',
    ROOM_MATCHING_WRITE_BLACKLIST = 'ROOM_MATCHING:WRITE:BLACKLIST',
    BOARD_MATCHING_READ = 'BOARD_MATCHING:READ',
    BOARD_MATCHING_WRITE_CODE = 'BOARD_MATCHING:WRITE:CODE',
    BOARD_MATCHING_WRITE_RULE = 'BOARD_MATCHING:WRITE:RULE',
    RATE_MATCHING_READ = 'RATE_MATCHING:READ',
    RATE_MATCHING_WRITE_RATE = 'RATE_MATCHING:WRITE:RATE',
    RATE_MATCHING_WRITE_RULE = 'RATE_MATCHING:WRITE:RULE',
    REMARK_READ = 'REMARK:READ',
    REMARK_WRITE = 'REMARK:WRITE',
    GLOBALTYPE_GROUP_READ = 'GLOBALTYPE_GROUP:READ',
    GLOBALTYPE_GROUP_WRITE = 'GLOBALTYPE_GROUP:WRITE',
    CUSTOM_AIRPORTS_READ = 'CUSTOM_AIRPORTS:READ',
    EXTRA_READ = 'EXTRA:READ',
    EXTRA_WRITE = 'EXTRA:WRITE',
    PORTFOLIO_CONFIG_READ = 'PORTFOLIO_CONFIG:READ',
    PORTFOLIO_CONFIG_WRITE_GLOBAL_TYPE_SOURCES = 'PORTFOLIO_CONFIG:WRITE:GLOBAL_TYPE_SOURCES',
    PORTFOLIO_CONFIG_WRITE_EXCHANGE_RATE_SOURCE = 'PORTFOLIO_CONFIG:WRITE:EXCHANGE_RATE_SOURCE',
    PORTFOLIO_CONFIG_WRITE_BRAND = 'PORTFOLIO_CONFIG:WRITE:BRAND',
    PORTFOLIO_CONFIG_WRITE_BRAND_PRESELECTION = 'PORTFOLIO_CONFIG:WRITE:BRAND_PRESELECTION',
    PORTFOLIO_CONFIG_WRITE_UNKNOWN_ROOMS = 'PORTFOLIO_CONFIG:WRITE:UNKNOWN_ROOMS',
    ICM_READ = 'ICM:READ',
    DC_READ = 'DC:READ',
    DC_WRITE = 'DC:WRITE',
    AGENCY_READ = 'AGENCY:READ',
    AGENCY_WRITE = 'AGENCY:WRITE',
    AGENCY_NUMBER_READ = 'AGENCY_NUMBER:READ',
    AGENCY_NUMBER_WRITE = 'AGENCY_NUMBER:WRITE',
    ADDRESS_READ = 'ADDRESS:READ',
    ADDRESS_WRITE = 'ADDRESS:WRITE',
    PAYMENT_OPTION_READ = 'PAYMENT_OPTION:READ',
    PAYMENT_OPTION_WRITE = 'PAYMENT_OPTION:WRITE',
    LOCALSUPPLIER_HOTEL_READ = 'LOCALSUPPLIER:HOTEL:READ',
    LOCALSUPPLIER_HOTEL_WRITE = 'LOCALSUPPLIER:HOTEL:WRITE',
    LOCALSUPPLIER_FLIGHT_READ = 'LOCALSUPPLIER:FLIGHT:READ',
    LOCALSUPPLIER_FLIGHT_WRITE = 'LOCALSUPPLIER:FLIGHT:WRITE',
    INSURANCE_READ = 'INSURANCE:READ',
    INSURANCE_WRITE = 'INSURANCE:WRITE',
    LOCALSUPPLIER_CONTENT_READ = 'LOCALSUPPLIER:CONTENT:READ',
    LOCALSUPPLIER_CONTENT_WRITE = 'LOCALSUPPLIER:CONTENT:WRITE',
    BRAND_READ = 'BRAND:READ',
    BRAND_WRITE = 'BRAND:WRITE',
    BRAND_WRITE_COPY = 'BRAND:WRITE:COPY',
    BOOKING_READ = 'BOOKING:READ',
    BOOKING_WRITE = 'BOOKING:WRITE',
    PACKAGE_TRAVEL_DIRECTIVE_READ = 'PACKAGE_TRAVEL_DIRECTIVE:READ',
    PACKAGE_TRAVEL_DIRECTIVE_WRITE = 'PACKAGE_TRAVEL_DIRECTIVE:WRITE',
    PRICE_EQUALITY_READ = 'PRICE_EQUALITY:READ',
    PRICE_EQUALITY_WRITE = 'PRICE_EQUALITY:WRITE',
    FULFILLMENT_READ = 'FULFILLMENT:READ',
    FULFILLMENT_WRITE = 'FULFILLMENT:WRITE',
    LIVE_BRAND_READ = 'LIVE_BRAND:READ',
    LIVE_BRAND_WRITE = 'LIVE_BRAND:WRITE',
    LIVE_SUPPLIER_READ = 'LIVE_SUPPLIER:READ',
    LIVE_SUPPLIER_WRITE = 'LIVE_SUPPLIER:WRITE',
    CONTENT_STORE = 'CONTENT_STORE',
    CONTENT_CONFIGURATION = 'CONTENT_CONFIGURATION',
    CONFIG_ENVIRONMENT = 'CONFIG:ENVIRONMENT',
    CONFIG_ACCOUNT_CUSTOMER_READ = 'CONFIG:ACCOUNT:CUSTOMER:READ',
    CONFIG_ACCOUNT_CUSTOMER_WRITE = 'CONFIG:ACCOUNT:CUSTOMER:WRITE',
    CONFIG_ACCOUNT_MISC_READ = 'CONFIG:ACCOUNT:MISC:READ',
    CONFIG_ACCOUNT_MISC_WRITE = 'CONFIG:ACCOUNT:MISC:WRITE',
    CONFIG_ACCOUNT_LPAC_READ = 'CONFIG:ACCOUNT:LPAC:READ',
    CONFIG_ACCOUNT_LPAC_WRITE = 'CONFIG:ACCOUNT:LPAC:WRITE',
    CONFIG_ACCOUNT_BOOKING_READ = 'CONFIG:ACCOUNT:BOOKING:READ',
    CONFIG_ACCOUNT_BOOKING_WRITE = 'CONFIG:ACCOUNT:BOOKING:WRITE',
    CONFIG_EXPERT_LPAC_READ = 'CONFIG:EXPERT:LPAC:READ',
    CONFIG_EXPERT_LPAC_WRITE = 'CONFIG:EXPERT:LPAC:WRITE',
    CONFIG_EXPERT_HUB_READ = 'CONFIG:EXPERT:HUB:READ',
    CONFIG_EXPERT_HUB_WRITE = 'CONFIG:EXPERT:HUB:WRITE',
    CONFIG_EXPERT_DHUB_READ = 'CONFIG:EXPERT:DHUB:READ',
    CONFIG_EXPERT_DHUB_WRITE = 'CONFIG:EXPERT:DHUB:WRITE',
    CONFIG_EXPERT_PLAYER_READ = 'CONFIG:EXPERT:PLAYER:READ',
    CONFIG_EXPERT_PLAYER_WRITE = 'CONFIG:EXPERT:PLAYER:WRITE',
    EVENTS_READ = 'EVENTS:READ',
    EVENTS_READ_USERS = 'EVENTS:READ:USERS',
    INSIGHTS_READ = 'INSIGHTS:READ',
    INSIGHTS_WRITE = 'INSIGHTS:WRITE',
    EXTERNAL_APPS = 'EXTERNAL_APPS',
}

export default Permission;
