export default {
    "ERROR_BRAND_LOCKED": "This brand is currently locked as part of a running process, and it cannot be modified.",
    "ERROR_BRAND_LOCKED_TITLE": "Brand Locked",
    "ERROR_CONFLICTING_SEMAPHORE_ACQUISITION_COPY": "This brand is currently part of a brand copy process and therefore it cannot be modified.",
    "ERROR_CONFLICTING_SEMAPHORE_ACQUISITION_COPY_TITLE": "Brand Locked: Brand copy in progress",
    "ERROR_CONFLICTING_SEMAPHORE_ACQUISITION_WHITELIST": "The last whitelist job has not finished yet.<br>This function will be available again in a few minutes.",
    "ERROR_CONFLICTING_SEMAPHORE_ACQUISITION_WHITELIST_TITLE": "Processing",
    "ERROR_RULE_ALREADY_EXISTS": "A different rule for this keyword already exists.",
    "ERROR_RULE_ALREADY_EXISTS_TITLE": "Rule already exists",
    "TIME_GAP_ERROR": "Exchange rates that are assigned must be complete and should not contain any gap periods. The final exchange rate should therefore not contain an end date and the start date must be today or newer.",
    "TIME_GAP_ERROR_TITLE": "Time gap when trying to assign exchange rates",
    "TIME_OVERLAP_ERROR": "Exchange rates cannot be assigned to one brand for an overlapping time period. Please check the highlighted entries and remove any overlapping time frames. Empty time frames are used as min/max values respectively.",
    "TIME_OVERLAP_ERROR_TITLE": "Time overlap when trying to assign exchange rates",
    "access_denied_msg": "Your are not authorized to see this page.",
    "accommodation": {
        "missing_translation": "The translation for this accommodation type is missing. As a fallback, the label provided by the supplier is displayed.",
        "subtype": {
            "AH": "Seniors' Residence",
            "AP": "Apartments",
            "B": "Mountain Hut, Cabin",
            "BB": "Bed & Breakfast, Homestay",
            "BW": "Bivouac hut",
            "CC": "Camping & Cottages",
            "CG": "Camping Site",
            "D": "Others",
            "FD": "Vacation City, Amusement Park, Holiday Village",
            "FG": "Gîte",
            "FH": "Holiday Villa, Ranch House",
            "FW": "Holiday Apartment",
            "G": "Inn",
            "GH": "Guest House",
            "GK": "Kro",
            "GR": "Group Accommodation",
            "H": "Hotel",
            "HA": "Apartment Hotel, Serviced apartments",
            "HC": "Hotel & Coffee Shop",
            "HE": "parador, pousada, village-hotel",
            "HF": "Caravanserai, Han",
            "HG": "Hotel Inn",
            "HH": "Unique Hotels (e.g.: Icehotel, Crane hotel)",
            "HI": "Non-public Hotels & Clubs",
            "HJ": "Ryokan",
            "HK": "Spa Hotel",
            "HM": "Motel, Motor Lodge",
            "HN": "Riad",
            "HO": "Hostel, Backpackers Hotel, Lobby",
            "HP": "Hotel-Pension",
            "HQ": "--- reserved for future use ---",
            "HR": "Hotel & Restaurant",
            "HS": "Ship Hotel, Cruise ship",
            "HT": "Train Hotel",
            "HU": "University/College Hotel",
            "K": "Clinic, Hospital",
            "KK": "Health Clinic",
            "KR": "Rehab Hospital",
            "KS": "Sanatorium",
            "KW": "Therapeutic group accommodation",
            "L": "Lodge, Safari Lodge",
            "LE": "Eco Lodge",
            "LT": "Tent Camp, Luxury Tents",
            "MZ": "Rooms for Service Technicians/Assemblers",
            "P": "Pension, Lodging House",
            "PC": "Pension & Coffee Shop",
            "PG": "B&B pension",
            "PR": "Pension & Restaurant",
            "R": "Resort",
            "RI": "Private Island Resort",
            "SC": "Self Catering Accommodation",
            "T": "Congress/Conference Center",
            "UB": "Holiday Farm, Farmstay, Agritourism",
            "WH": "Dorm, Dormitory",
            "YH": "Youth Hostel"
        },
        "type": {
            "1": "Inn",
            "2": "Pension",
            "3": "Care",
            "4": "Commercial Private Quarters",
            "5": "Mountain Lodges",
            "6": "Apartment",
            "7": "Accommodation for workers",
            "8": "Others",
            "9": "Dormitory",
            "10": "Guest House",
            "11": "Camping",
            "12": "Clinic/Hospital",
            "13": "Resort",
            "14": "Hotel",
            "15": "Group Homes",
            "16": "Roundtrip"
        }
    },
    "accommodation_cap": "Accommodation type",
    "accommodation_subtype_disabled": "This accommodation subtype is disabled, because the belonging accommodation type is already selected",
    "accommodation_type_disabled": "This accommodation type is disabled, because a belonging accommodation subtype is already selected",
    "actions_cap": "Actions",
    "activated_cap": "Activated",
    "active": "active",
    "active_cap": "Active",
    "active_master_code_cap": "Active master code",
    "active_rule_elements": "Active rule elements",
    "add": "add",
    "add_airport": "add airport code",
    "add_all": "add all",
    "add_as_remark_btn": "add remark for filter",
    "add_constant": "add constant",
    "add_new": "add new",
    "add_program_type": "add program type",
    "add_rule": "add rule",
    "add_to_draft": "add to draft",
    "additional_type_placeholder": "type AdditionalType",
    "address": "Address",
    "address_code": "Address code",
    "address_delete": "Delete address",
    "address_edit": "Edit address",
    "address_register": "Create address",
    "addresses_cap": "Addresses",
    "adopt_delivered_airports_btn": "adopt airports",
    "adopt_delivered_hotel_category_btn": "adopt category",
    "adopt_delivered_hotel_name_btn": "adopt hotel name",
    "agency": "agency",
    "agency_delete_cap": "Delete agency",
    "agency_number": "agency no.",
    "agency_number_delete_cap": "Delete agency number",
    "agency_numbers_cap": "Agency numbers",
    "agency_numbers_edit": "Edit agency number",
    "agency_numbers_register": "Create agency number",
    "agoda": "Agoda",
    "air_type_cap": "Type of Aircraft",
    "airline_cap": "Airline",
    "airports_per_page_cap": "Airports per page",
    "airports_total": "Total airports:",
    "all": "all",
    "amount_cap": "Amount",
    "amount_gross_cap": "Amount (Gross)",
    "and_or_required_field": "one of each required",
    "annotation_cap": "Annotation",
    "appliance_code_cap": "Appliance Code",
    "appliance_extra_codes_cap": "Appliance Codes",
    "appliance_group_code_cap": "Appliance Group Code",
    "appliance_groups_cap": "Appliance Groups",
    "appliances_cap": "Apply to",
    "apply": "apply",
    "apply_exchange": "assign to chosen brand",
    "apply_rule": "apply rule",
    "apply_to_cap": "Refers To",
    "arrival_airport_cap": "Arrival airport",
    "arrival_airports_cap": "Arrival airports",
    "arrival_airports_to_use_cap": "Use these arrival airports",
    "asc": {
        "assign_button_cap": "add restrictions",
        "deleteModal": {
            "message": "Are you sure you would like to remove ALL Accommodation Sales Control restrictions from Hotels matching the given filter?",
            "title": "Delete Accommodation Sales Control restrictions by filter"
        },
        "exportBtn": "export accommodation sales control",
        "exportConfirmModal": {
            "btnConfirm": "export ASC",
            "exportInProgress": "An ASC export is already in progress. The export will become available when the previous operation is complete",
            "message": "Are you sure you would like to export the Accommodation Sales Control?",
            "successMessage": "ASC successfully exported",
            "title": "Export ASC"
        },
        "filterModal": {
            "assignmentInProgress": "An ASC assignment is already in progress. The assignment will become available when the previous operation is complete",
            "title": "Add Accommodation Sales Control by filter"
        },
        "form": {
            "end_disabled_tt": "End date cannot be set when start date is set",
            "start_disabled_tt": "Start date cannot be set when end date is set"
        },
        "hotelModal": {
            "add_btn": "add ASC restriction",
            "current_asc_cap": "Assigned restrictions",
            "delete_cap": "Delete",
            "emptyText": "There are no restrictions currently assigned",
            "empty_restriction_not_allowed_tooltip": "Empty restrictions are not allowed",
            "end_cap": "End Date",
            "giataId_cap": "GIATA ID",
            "goalId_cap": "GOAL ID",
            "localSupplier_cap": "Local Supplier",
            "new_asc_cap": "Create New Accommodation Sales Control restriction",
            "restrictions_to_be_added_cap": "Restrictions to be added",
            "start_cap": "Start Date",
            "title": "Edit assigned restrictions"
        },
        "remove_button_cap": "remove by filter",
        "table_column_cap": "Sales Control"
    },
    "assign": "assign",
    "assign_brands": "Assign brands",
    "assign_brands_error": "Error during brand assignment",
    "assign_brands_success": "Brand assignment successfully changed",
    "assign_distribution_channel_btn": "assign distribution channels",
    "assign_distribution_channel_cap": "Assign distribution channel",
    "assign_extras_btn": "assign to result",
    "assign_program_types": "assign program types",
    "assign_program_types_cap": "Add program type",
    "assign_to_brand": "Assign to brands",
    "assigned": "assigned",
    "assigned_brands_cap": "Assigned Brands",
    "assigned_cap": "Assigned",
    "assigned_exchange_rates_cap": "Assigned exchange rates",
    "assigned_extras_cap": "Assigned Extras",
    "assigned_globaltypes_cap": "Assigned GlobalTypes",
    "assigned_prod_specs_cap": "Assigned Specifications",
    "assigned_to_brands": "Assigned to brands",
    "at_extra_type": "admission ticket",
    "auth_access_denied": "Access Denied",
    "auth_login": "Login",
    "auth_token": "Token",
    "auto_refresh_cap": "Automatic refresh",
    "auto_whitelist_config_cap": "Auto-Whitelist toggle",
    "available_description_languages_cap": "Description texts (languages)",
    "back_to_draft_btn": "add again",
    "back_to_grouped_view": "back to grouped view",
    "back_to_list": "back to list",
    "back_to_main_code_view": "back to Main code group",
    "basic": "Basic",
    "basic_data_cap": "Base Data",
    "bhubpwcustomer": "peakwork customer",
    "bhubpwoffice": "peakwork office",
    "bhubusername": "username",
    "board_cap": "Board",
    "board_code_assigned": "then assign board code",
    "board_code_cap": "Board code",
    "board_code_not_found": "Assigned board code could not be found",
    "board_count_cap": "Board count",
    "board_key_cap": "Board key",
    "board_matching_config_cap": "Board auto matching toggle",
    "board_names_cap": "Board names",
    "board_restrictions_cap": "Board",
    "board_restrictions_count_cap": "Board",
    "board_rule_added_successfully": "Rule added successfully",
    "board_rule_deleted_successfully": "Rule deleted successfully",
    "board_rule_edited_successfully": "Rule edited successfully",
    "board_tag_cap": "Board tag",
    "booking_code_cap": "Booking Code",
    "booking_end_cap": "End of Booking Period",
    "booking_period_cap": "Period of booking",
    "booking_start_cap": "Start of Booking Period",
    "brand": "brand",
    "brandSpecificOverride": "Brand-specific override. Delivered",
    "brand_bhub_credentials": "Brand B-Hub credentials",
    "brand_cap": "Brand",
    "brand_copy": {
        "cap": {
            "copy_contracts": "copy contracts",
            "existing": "Copy into existing brand",
            "modal": "Copy Into Brand",
            "new": "Create new brand"
        },
        "card": {
            "options": "Copy Options",
            "source": "Source Brand",
            "submit": "Submit",
            "target": "Target Brand"
        },
        "code_description": "The code used to identify the brand e.g. when searching for offers",
        "info": {
            "brand_deletion_warning": "If an error occurs during the copy process, the new brand is automatically deleted.",
            "brand_lock_warning": "This process can take a long time, during which both affected brands are locked and both the brand itself and its data cannot be modified. <br> During this time we recommend not using the exchange rates api and instead sending them upon copy completion.",
            "head": "The following information will be copied to the new brand:",
            "head_select": "For each contract matched, the following information can be copied into the existing brand:",
            "matched": "Your configured filter matches {numContracts} contracts.",
            "no_intersecting_suppliers": "There are no common suppliers between the source and the target brand.",
            "no_steps": "No copy steps have been selected."
        },
        "name_description": "Name for the brand. Will be displayed in the Porfolio Management in your Box.",
        "steps": {
            "BRAND_ASSIGNMENTS": "Brand Assignments",
            "DISTRIBUTION_CHANNELS": "Distribution Channels",
            "EXCHANGE_RATES": "Exchange Rates",
            "GLOBAL_EXTRAS": "Extra Assignments",
            "GLOBAL_TYPES": "Brand-specific Globaltypes",
            "HOTEL_ENRICHMENTS": "Brand-specific Hotel Enrichments (Program Types)",
            "REMARKS": "Remark assignments",
            "ROOM_BOARD_BLACKLIST": "Room & Board selection",
            "WHITELIST": "Draft Status"
        }
    },
    "brand_copy_process_step": "Copy Step:",
    "brand_currency_cap": "Brand currency",
    "brand_delete": "Delete brand",
    "brand_edit": "Edit brand",
    "brand_locked_warning": "Due to an ongoing process for this brand (e.g. Whitelisting, Brand copy...) the brand data currently cannot be modified.",
    "brand_package_travel_directive": "Package Travel Directive Configuration",
    "brand_package_travel_directive_delete": "Delete Package Travel Directive configuration",
    "brand_package_travel_directive_edit": "Edit Package Travel Directive configuration",
    "brand_package_travel_directive_register": "Create Package Travel Directive configuration",
    "brand_preselection_cap": "Brand Preselection",
    "brand_register": "Create brand",
    "brand_supplier_configuration_title": "Configuration for \"{name}\"",
    "brandbooking_delete": "Delete B-Hub credentials",
    "brandbooking_edit": "Edit B-Hub credentials",
    "brandbooking_register": "Create B-Hub credentials",
    "brands": "brands",
    "brands_cap": "Brands",
    "c_dtpicker": {
        "clear_cap": "Clear selection",
        "close_cap": "Close the picker",
        "decrement_hour": "Decrement hour",
        "decrement_minute": "Decrement minute",
        "decrement_second": "Decrement second",
        "increment_hour": "Increment hour",
        "increment_minute": "Increment minute",
        "increment_second": "Increment second",
        "next_century": "Next century",
        "next_decade": "Next decade",
        "next_month_cap": "Next month",
        "next_year": "Next year",
        "pick_hour": "Pick hour",
        "pick_minute": "Pick minute",
        "pick_second": "Pick second",
        "prev_century": "Previous century",
        "prev_decade": "Previous decade",
        "prev_month_cap": "Previous month",
        "prev_year": "Previous year",
        "select_decade": "Select decade",
        "select_month_cap": "Select month",
        "select_year": "Select year",
        "today_cap": "Go to today"
    },
    "c_table": {
        "column_visibility": "column visibility",
        "empty_result": "no content",
        "entries_per_page": "Entries per page: ",
        "no_response": "Request failed!",
        "total": "Total:",
        "total_extras": "Extras total"
    },
    "calculation_cap": "Calculation",
    "cancel": "cancel",
    "category_cap": "Hotel category",
    "channels_cap": "Channels",
    "charges_cap": "Charges",
    "charts": {
        "horizontal_bar_amount": "amount",
        "horizontal_bar_draft_count": "draft",
        "horizontal_bar_total_count": "total",
        "horizontal_bar_whitelist_count": "whitelist"
    },
    "city_cap": "City",
    "clear_dates": "Clear Dates",
    "close": "close",
    "close_application": "close application",
    "code": "code",
    "code_cap": "Code",
    "code_supplier_cap": "Supplier code",
    "codes_cap": "Codes",
    "condition_cap": "If/Then",
    "configuration": "configuration",
    "configure_basic": "Configure basic data",
    "confirm": "confirm",
    "confirm_add_room_matching_configuration": "Do you want to add room matching configuration '{0}' ",
    "confirm_add_room_matching_configuration_title": "Add New Room Matching Configuration",
    "confirm_brandAssignmentInProgress_in_pending": "The previous brand assignment job is not finished yet.<br>This function will be available in a few minutes.",
    "confirm_copy_room_matching_configuration": "Do you want to copy the existing room matching configuration '{source}' into the new room matching configuration '{target}'?",
    "confirm_delete_board_code": "Remove board code \"{0}\"? <br> Please note that this could affect the board matching!",
    "confirm_delete_board_code_title": "Delete board code",
    "confirm_delete_custom_rate": "Shall the custom rate \"{0}\" really be removed?",
    "confirm_delete_custom_rate_title": "Delete rate",
    "confirm_delete_extra": "Would you like to delete this extra and its assignment to the hotel contracts?",
    "confirm_delete_extra_title": "Delete Extra",
    "confirm_delete_global_type": "Do your really want to delete this GlobalType?",
    "confirm_delete_global_type_title": "Delete GlobalType",
    "confirm_delete_rate_rule": "Do your really want to delete this rule?",
    "confirm_delete_rate_rule_title": "Delete rule",
    "confirm_delete_room_code": "Remove room code \"{0}\"? <br> Please note that this could affect the room matching!",
    "confirm_delete_room_code_title": "Delete room code",
    "confirm_delete_room_matching_configuration": "Do you want to delete room matching configuration '{0}' ",
    "confirm_delete_room_matching_configuration_title": "Delete a room matching configuration",
    "confirm_draft_remove_result": "Remove complete search result from draft?",
    "confirm_draft_remove_result_title": "Remove result",
    "confirm_draft_result": "Add complete search result to draft?",
    "confirm_draft_result_title": "Add result",
    "confirm_export_global_types": "Do you want to export all GlobalTypes? <br>(GlobalTypes are also part of the whitelist export)",
    "confirm_export_global_types_title": "Export GlobalTypes",
    "confirm_full_export": "The full export triggers a complete update of brand information, i.e. <b>hotels from the whitelist</b> including all their basic data changes, corresponding <b>extras</b>, <b>exchange rates</b> and <b>DRV GlobalTypes</b>.<br>Do you really want to trigger the full export?",
    "confirm_full_export_title": "Full export",
    "confirm_job_in_pending": "The last job is not finished yet.<br>This function will be available in a few minutes again.",
    "confirm_overwrite_exchange_rate_msg": "Do you really want to overwrite the existing exchange rate?",
    "confirm_overwrite_exchange_rate_title": "Exchange rate overwrite",
    "confirm_rb_automatching_activate_cap": "Activate Room/Board auto matching",
    "confirm_rb_automatching_deactivate_cap": "Deactivate Room/Board auto matching",
    "confirm_remark_delete": "Remove this remark?",
    "confirm_remove_board_rule": "Delete rule \"{0} {1} ({2})\"?",
    "confirm_remove_board_rule_title": "Delete rule",
    "confirm_remove_exchange_rate": "Remove exchange rate \"{0} => {1}\" ?",
    "confirm_remove_exchange_rate_template_msg": "Do you really want to remove this exchange rate?",
    "confirm_remove_exchange_rate_template_title": "Remove exchange rate",
    "confirm_remove_exchange_rate_title": "Remove exchange rate",
    "confirm_remove_from_draft_title": "Remove from draft",
    "confirm_remove_hotel_from_draft": "Remove hotel \"{0}\" from draft?",
    "confirm_remove_hotels_from_draft": "Remove {0} hotels from draft?",
    "confirm_remove_room_rule": "Delete rule \"{0} {1} ({2})\"?",
    "confirm_remove_room_rule_title": "Delete rule",
    "confirm_save_rate_rules": "Do you want to save the custom rate rules? <br/><br/> The rules will be applied directly afterwards, which might take some time. During this process, all brands are locked.",
    "confirm_save_rate_rules_title": "Save & Apply",
    "confirm_set_master_code_rule": "Do you want to apply the new rule?<br>This will change the existing master codes for every hotel in every brand!<br>Please notice that the computing of the new rule takes some time.<br>Please do not assign extras or remarks while processing!",
    "confirm_set_master_code_rule_title": "Rule for master code",
    "confirm_update_room_matching_configuration": "Do you want to update room matching configuration '{0}' ",
    "confirm_update_room_matching_configuration_title": "Updating Room Matching Configuration",
    "confirm_whitelist_draft_result": "Do you want to add the filter result of your draft to your whitelist?",
    "confirmation_btn": "confirm changes",
    "confirmation_text": "Confirmation text",
    "contains_brand_specific_locked_msg": "This Hotel contains brand-specific overrides which can be set via the enrichment API. Please use the API to edit/remove the brand specific enrichment(s) before editing general enrichment's via the frontend.",
    "contractUuid_cap": "Contract UUID",
    "contract_cap": "Contracts",
    "contract_currency_cap": "Contract currency",
    "contract_distribution_channels": "Distribution channels assigned to contract",
    "contract_level_filter_applied": "The following contract level filter(s) will affect this field: {0}",
    "contract_name_cap": "Contract key",
    "contracts_grouped_tooltip": "{number} contracts were grouped together",
    "conversion_factor_cap": "Conversion factor",
    "copy": "copy",
    "count_of_images_cap": "Image count",
    "count_of_rooms": "Count of rooms",
    "count_of_rooms_matching_rate_filter": "Count of rooms matching rate filter",
    "country": "country",
    "country_cap": "Country",
    "create_btn": "create",
    "create_globalType_blacklist_cap": "Select GlobalType Blacklist",
    "create_globalType_filter_cap": "Select GlobalType Filter",
    "csv_export_modal": {
        "error_getting_supported_headers": "An error occurred while retrieving supported CSV fields",
        "export_options": "Export Options",
        "selected_brand": "Selected brand"
    },
    "csv_fields": "CSV fields",
    "currency_cap": "Currency",
    "currency_not_provided": "not provided",
    "custom_airports_cap": "Custom Arrival Airports",
    "custom_codes_cap": "Custom code templates",
    "custom_codes_deactivated": "Custom code templates can only be applied for default configuration",
    "custom_descriptions_cap": "Custom description",
    "custom_master_code_overwrite_cap": "Custom master code",
    "custom_master_code_overwrite_descriptions": "Custom descriptions",
    "custom_rate_assigned_label": "then assign the custom rate",
    "custom_rate_assignment": "Rate rule",
    "custom_rate_cap": "Custom rate",
    "custom_rate_code_cap": "Code",
    "custom_rate_deleted": "Rate deleted successfully",
    "custom_rate_distribution_channel_exclusions_cap": "Excluded distribution channels",
    "custom_rate_filter": "Custom rate filter",
    "custom_rate_filtered_tooltip": "This custom rate is not included in the custom rate brand filter. The room will not be exported.",
    "custom_rate_global_type_assignment": "GlobalType rule",
    "custom_rate_live_supplier_tooltip": "Application of custom rate rules not known for live suppliers. Brand custom rate filter is applied during the live search.",
    "custom_rate_name_cap": "Name",
    "custom_rate_rules_exported": "Custom rates exported successfully",
    "custom_rate_unknown": "Custom rate unknown",
    "custom_rates_cap": "Custom rates",
    "customtype_placeholder": "type CustomType",
    "date_format": "MM/DD/YYYY",
    "date_restrictions_cap": "Booking/Travel Period",
    "date_restrictions_count_cap": "Booking/Travel Period",
    "date_time_format": "MM/DD/YYYY hh:mm a",
    "date_time_seconds_format": "DD/MM/YYYY HH:mm:ss",
    "days": "days",
    "dc_assigned": "assigned",
    "dc_unassigned": "unassigned",
    "deactivate_btn": "deactivate",
    "default": "default",
    "delete": "delete",
    "delete_all": "delete all",
    "delete_confirm_btn": "Yes, delete",
    "delete_filter_set_cap": "Delete Filter Set",
    "deleted_brand": "deleted brand",
    "deleted_info": "Deletion info",
    "deleted_info_cap": "Removed from inventory at",
    "deleted_info_not_scheduled": "Not scheduled for cleanup",
    "deleted_info_not_scheduled_job_disabled": "The cleanup job is disabled",
    "deleted_info_not_scheduled_whitelisted": "Whitelisted for brands {0}",
    "deleted_info_scheduled_date": "Will be cleaned up after {0}",
    "delivered_arrival_airports_cap": "Delivered arrival airports",
    "delivered_cap": "Delivered",
    "delivered_code": "board code",
    "delivered_code_label": "delivers the board code",
    "delivered_supplier_rate_label": "delivers the rate",
    "delivered_supplier_rates_cap": "Delivered rates",
    "departure_airport_cap": "Departure Airport",
    "description": "description",
    "description_cap": "Description",
    "description_contains": "contains the keyword",
    "description_deactivate_rb_matching": "You are deactivating the automatic room and board matching. That means rooms and boards will be published as delivered from suppliers.",
    "description_language_cap": "If a description in language",
    "description_languages_cap": "Description languages",
    "descriptions": "Descriptions",
    "discard": "discard",
    "discard_and_close": "discard changes and close",
    "display_hotels": "Display hotels",
    "distributionDashboard": {
        "booking_traffic": "Booking Traffic",
        "live_search": "Live Search"
    },
    "distribution_agencies_cap": "Agencies",
    "distribution_agencies_edit": "Edit agency",
    "distribution_agencies_register": "Create agency",
    "distribution_cap": "Distribution Channel",
    "distribution_channel": "distribution channel",
    "distribution_channel_cap": "Distribution channel",
    "distribution_channel_delete_cap": "Delete distribution channel",
    "distribution_channel_max_info": "Please note that no more than 60 distribution channels are supported.",
    "distribution_channels_cap": "Distribution Channels",
    "distribution_channels_edit": "Edit distribution channel",
    "distribution_channels_register": "Create distribution channel",
    "do_not_modify": "do not modify",
    "documentation": "Documentation",
    "doris_live_brand_configuration": "Live Search Brand Configuration",
    "doris_live_supplier_configuration": "Live Search Supplier Configuration",
    "double_room": "double room",
    "download_csv_export": "download CSV export",
    "download_in_progress": "download in progress",
    "download_options_cap": "Download options",
    "draft": "draft",
    "draft_all_btn": "add result to draft",
    "draft_button_cap": "Draft action",
    "draft_page_btn": "add page to draft",
    "drafted": "drafted",
    "drafted_hotels": "draft",
    "drafted_hotels_cap": "draft",
    "drafted_or_whitelisted": "drafted or whitelisted",
    "drv_global_types_cap": "DRV GlobalTypes",
    "edit": "edit",
    "edit_cap": "Edit",
    "edit_global_types": "edit GlobalTypes",
    "edit_hotel_data": "Edit hotel data",
    "edit_hotel_data_cap": "edit hotel data",
    "edit_hotel_global_types_cap": "Edit Hotel GlobalTypes",
    "edit_master_data": "edit master data",
    "edit_room_descriptions_add_custom_code": "Create new custom code",
    "edit_room_descriptions_cap": "Edit Room Descriptions",
    "edit_room_descriptions_prompt": "Select edit option",
    "edit_room_descriptions_use_existing_code": "Use a custom code template",
    "edit_rule": "edit rule",
    "empty_code": "Empty Code",
    "end_cap": "End",
    "end_date_cap": "End Date",
    "enriched_program_type_cap": "Enriched program types",
    "env_stage_demo": "Demo",
    "env_stage_dev": "Develop",
    "env_stage_prod": "Production",
    "env_stage_qa": "QA",
    "env_stage_showcase": "Showcase",
    "env_stage_staging": "Staging",
    "env_stage_test": "Test",
    "env_updated": "Environment updated",
    "environment_description": "Here you can define the name and stage for your environment. The name and stage is displayed in the top right of the screen. They are arbitrary and only used for display purposes. For example, can be used to distinguish between different instances.",
    "environments": "environments",
    "error": "error",
    "error_activate": "error while activating",
    "error_adding_global_type_failed": "An error occurred while adding GlobalType",
    "error_adding_main_rule_failed": "An error occurred while adding rule",
    "error_airports_call_failed": "An error occurred while requesting airports",
    "error_all_currencies_call_failed": "An error occurred while requesting currencies",
    "error_all_currencies_for_brand_call_failed": "An error occurred while requesting currencies for selected brand",
    "error_all_main_rules_failed": "An error occurred while requesting rules for main codes",
    "error_all_sub_rules_failed": "An error occurred while requesting rules for sub codes",
    "error_asc_edit_call_failed": " An error occurred while changing the Accommodation Sales Control restrictions",
    "error_assigning_distribution_channel_failed": "An error occurred while assigning distribution channels",
    "error_autocomplete_call_failed": "An error occurred while requesting input completions",
    "error_automatching_config_call_failed": "An error occurred while requesting automatching configuration",
    "error_board_codes_call_failed": "An error occurred while requesting codes for boards",
    "error_board_description_call_failed": "An error occurred while requesting board descriptions",
    "error_board_rules_call_failed": "An error occurred while requesting rules for boards",
    "error_brand_call_failed": "An error occurred while requesting brands",
    "error_city_call_failed": "An error occurred while requesting available cities",
    "error_code": {
        "NAME_ALREADY_EXISTS": "This name already exists"
    },
    "error_content_gc": "An error occurred while cleaning the content cache",
    "error_contract_call_failed": "An error occurred while requesting contracts",
    "error_contract_description_call_failed": "An error occurred while requesting contract descriptions",
    "error_country_call_failed": "An error occurred while requesting available countries",
    "error_custom_rate_brand_filters_call_failed": "An error occurred while requesting custom rate brand filters",
    "error_custom_rate_call_failed": "An error occurred while requesting custom rates",
    "error_custom_rate_rules_export_failed": "An error occurred while exporting custom rates",
    "error_custom_rate_saving_failed": "An error occurred while saving custom rate",
    "error_custom_rate_update_failed": "An error occurred while updating custom rate",
    "error_dashboard": "Error loading dashboard",
    "error_dashboard_no_url": "Failed to retrieve dashboard URL.",
    "error_delete_room_code_failed": "An error occurred while deleting room code",
    "error_deleting_board_code_failed": "An error occurred while deleting board code",
    "error_deleting_custom_rate_failed": "An error occurred while deleting custom rate",
    "error_deleting_extra_failed": "An error occurred while deleting extra",
    "error_deleting_rate_rule_failed": "An error occurred while deleting rule",
    "error_deleting_remark_failed": "An error occurred while deleting remark",
    "error_deleting_room_code_failed": "An error occurred while deleting room code",
    "error_deleting_room_matching_config_default": "Default room matching configuration cannot be deleted",
    "error_distribution_channel_call_failed": "An error occurred while requesting distribution channel",
    "error_download_call_failed": "An error occurred while starting download",
    "error_draft_remove_result_call_failed": "An error occurred while removing whole result from draft",
    "error_draft_result_call_failed": "An error occurred while adding whole result to draft",
    "error_editing_global_type_failed": "An error occurred while saving changes in GlobalType",
    "error_event_call_failed": "An error occurred while requesting events",
    "error_exchange_rate_assignment": "An error occurred while assigning exchange rate",
    "error_exchange_rate_template_call_failed": "Could not retrieve configured exchange rates",
    "error_exchange_rates_call_failed": "An error occurred while requesting exchange rates",
    "error_exchange_rates_config_call_failed": "An error occurred while requesting exchange rates config",
    "error_exchange_rates_config_put_failed": "An error occurred while saving exchange rates config",
    "error_exporting_exchange_rates": "An error occurred while exporting exchange rates",
    "error_external_app_not_found": "Could not load data for the application \"{0}\".",
    "error_external_apps_call_failed": "An error occurred while loading the external applications",
    "error_extra_call_failed": "An error occurred while requesting extra",
    "error_extras_add_call_failed": "An error occurred while adding extra",
    "error_extras_call_failed": "An error occurred while requesting extras",
    "error_extras_call_for_goalid_failed": "An error occurred while requesting details for this hotel",
    "error_extras_remove_call_failed": "An error occurred while removing extra",
    "error_filterset_call_failed": "An error occurred while requesting filter sets",
    "error_filterset_saving_failed": "An error occurred while saving filter set",
    "error_full_export_failed": "An error occurred during full export",
    "error_full_export_global_types_failed": "An error occurred while exporting GlobalTypes",
    "error_get_master_code_rule_call_failed": "An error occurred while requesting current rule",
    "error_get_missing_exchange_rates": "An error occurred while requesting missing exchange rates",
    "error_getting_environment": "An error occurred while requesting environment information",
    "error_getting_release_notes": "An error occurred while requesting release notes information",
    "error_global_type_delete_call_failed": "An error occurred while deleting GlobalType",
    "error_global_types_call_failed": "An error occurred while requesting GlobalTypes",
    "error_global_types_sources_call_failed": "An error occurred while requesting sources of GlobalTypes",
    "error_globaltype_groups_load_failed": "An error occurred while requesting globaltype groups",
    "error_globaltype_groups_save_failed": "An error occurred while saving globaltype groups",
    "error_globaltype_groups_scoring_failed": "An error occurred while calculating GlobalTypes scores for group, the threshold can never be reached, please reduce it or provide/adjust the scores",
    "error_globaltypes_blacklist_call_failed": "An error occurred while requesting GlobalType blacklist",
    "error_globaltypes_config_call_failed": "An error occurred while requesting GlobalType configuration",
    "error_globaltypes_config_edit_call_failed": "An error occurred while saving GlobalType configuration",
    "error_hotel_call_failed": "An error occurred while requesting hotels",
    "error_hotel_drafting_failed": "An error occurred while adding hotels to draft",
    "error_hotel_removing_failed": "An error occurred while removing hotels from draft",
    "error_hotel_whitelisting_failed": "An error occurred during whitelisting",
    "error_hotelpreselection_config_call_failed": "An error occurred while updating Hotel Preselection configuration",
    "error_hotelpreselection_config_fetch_call_failed": "An error occurred while requesting Hotel Preselection configuration",
    "error_ignored_tokens_call_failed": "An error occurred while requesting ignored tokens",
    "error_infx_attributes_call_failed": "An error occurred while requesting Infx attributes",
    "error_init_rate_rules_call_failed": "An error occurred while getting rules",
    "error_internal_server_error": "INTERNAL SERVER ERROR - a critical error occurred",
    "error_languages_call_failed": "An error occurred while requesting available languages",
    "error_languages_call_failed_persisted": "Room description languages could not be loaded. Please try again later.",
    "error_main_description_call_failed": "An error occurred while requesting descriptions",
    "error_master_code_active_call_failed": "An error occurred while requesting master code active state",
    "error_network_error": "Service is not reachable",
    "error_no_visualization_for_configuration": "No visual display for configuration element : \"{0}\".",
    "error_options_call_failed": "An error occurred while requesting options",
    "error_permissions": "An error occurred while retrieving user permissions",
    "error_primary_hotel_id": "An error occurred while retrieving the primary hotel id configuration",
    "error_program_types_delete_call_failed": "An error occurred while removing program types",
    "error_program_types_save_call_failed": "An error occurred while saving the program types",
    "error_rating_suppliers_call_failed": "An error occurred while requesting rating suppliers",
    "error_region_call_failed": "An error occurred while requesting available regions",
    "error_reload": "An error occurred; please reload the page.",
    "error_remarks_call_failed": "An error occurred while requesting remarks",
    "error_removing_distribution_channel_failed": "An error occurred while removing distribution channels",
    "error_removing_exchange_rate_from_brand_failed": "An error occurred while removing exchange rate",
    "error_revert_whitelist_job_call_failed": "An error occurred during the reversion of a whitelist export",
    "error_room_code_config_call_failed": "An error occurred while loading the room code configuration",
    "error_room_codes_call_failed": "An error occurred while requesting room codes",
    "error_room_custom_codes_call_failed": "An error occurred while requesting custom codes",
    "error_room_main_codes_call_failed": "An error occurred while requesting main codes",
    "error_room_matching_configurations_call_failed": "An error occurred while requesting room matching configurations",
    "error_room_sub_codes_call_failed": "An error occurred while requesting sub codes",
    "error_save": "saving failed",
    "error_saving_all_board_rules_failed": "An error occurred while saving board rules",
    "error_saving_all_main_rules_failed": "An error occurred while saving main rules",
    "error_saving_brand_config_failed": "An error occurred while saving brand configuration",
    "error_saving_custom_rate_filters_failed": "An error occurred while saving custom rate filters",
    "error_saving_descriptions_failed": "An error occurred while saving descriptions",
    "error_saving_exchange_rate_failed": "An error occurred while saving exchange rates",
    "error_saving_hotel_data_failed": "An error occurred while saving hotel data",
    "error_saving_rate_rules_failed": "An error occurred while saving custom rate rules",
    "error_saving_remark_failed": "An error occurred while saving remark",
    "error_saving_room_matching_config_failed": "An error occurred while saving the room matching configuration",
    "error_saving_rooms_and_boards_failed": "An error occurred while saving selected rooms and boards",
    "error_saving_rule_failed": "An error occurred while saving rule",
    "error_saving_unknown_rooms_config_failed": "An error occurred while saving unknown rooms configuration",
    "error_search_descriptions_failed": "An error occurred while querying room descriptions",
    "error_single_hotel_call_failed": "An error occurred while requesting hotel room and board data",
    "error_sub_description_call_failed": "An error occurred while requesting room names for sub codes",
    "error_supplier_call_failed": "An error occurred while requesting supplier",
    "error_supplier_rate_call_failed": "An error occurred while requesting delivered rates",
    "error_supplier_type_call_failed": "An error occurred while requesting supplier types",
    "error_title_code": "Error {code}",
    "error_token_ignore_call_failed": "An error occurred while ignoring the token",
    "error_token_restore_call_failed": "An error occurred while restoring the token",
    "error_unknown": "Unknown error",
    "error_unknown_rooms_config_call_failed": "An error occurred while requesting unknown rooms configuration",
    "error_unknown_rooms_types_call_failed": "An error occurred while requesting unknown rooms types",
    "error_updating_auto_whitelist_configuration_failed": "An error occurred while updating auto-whitelist configuration",
    "error_updating_rating_supplier_assignment_failed": "An error occurred while updating rating supplier configuration",
    "error_version_call_failed": "An error occurred while requesting the software version",
    "error_while_getting_auto_whitelist_configuration": "An error occurred while requesting auto-whitelist configuration",
    "error_while_getting_brand_configurations": "An error occurred while requesting brand configurations",
    "error_while_getting_rating_supplier_configuration": "An error occurred while requesting rating supplier configuration",
    "error_while_updating_environment": "Error occurred while updating environment",
    "error_whitelist_history_call_failed": "An error occurred while requesting the whitelist history",
    "error_whitelist_job_info_call_failed": "Couldn't get whitelist export revert details",
    "error_whitelist_job_info_download_failed": "Couldn't download whitelist export revert details",
    "error_whitelisting_info": "Error while getting whitelisting info",
    "events": {
        "business_context_cap": "Context",
        "correlation_id_cap": "Correlation ID",
        "date_cap": "Date",
        "events_cap": "Events",
        "link": {
            "details_cap": "Details"
        },
        "link_cap": "Link",
        "message_cap": "Message",
        "severity_cap": "Severity",
        "time_end_cap": "End",
        "time_start_cap": "Start",
        "triggers_cap": "Triggers",
        "username_cap": "User"
    },
    "ex_extra_type": "excursions",
    "exchange_last_export_on": "Last export on",
    "exchange_rate_source_api_import": "Source: API Import",
    "exchange_rate_source_portfolio": "Source: Portfolio",
    "exchange_rate_successfully_removed": "Exchange rate successfully removed",
    "exchange_rate_successfully_saved": "Exchange rate successfully saved",
    "exchange_rate_table_has_expired": "Exchange rate has expired",
    "exchange_rate_table_last_export": "Last export",
    "exchange_rate_table_never_exported": "Never exported",
    "exchange_rate_table_soon_expiring": "Exchange rate is expiring soon",
    "exchange_rate_templates_cap": "Master data exchange rates",
    "exchange_rate_will_be_deleted": "Source: Portfolio. Exchange rate can be overwritten by (automatic) import via API",
    "exchange_rates_assigned_cap": "Assigned exchange rates",
    "exchange_rates_never_exported": "Exchange rates for this brand have not been exported yet",
    "exchange_rates_successfully_exported": "Exchange rates exported successfully",
    "exchange_rates_table_empty_table": "No exchange rates found",
    "exchange_rates_table_filter_cap": "Filter",
    "exchange_rates_table_filter_inactive_nothing_selected": "Filters inactive, no filter field selected",
    "exchange_rates_table_filter_on_cap": "Filter on",
    "exchange_rates_table_source_currency_cap": "Source Currency Code",
    "exchange_rates_table_target_currency_cap": "Target Currency Code",
    "exchangerate_difference_cap": "Difference",
    "exchangerates_info": "The exchange rates managed here are used to convert the currency of hotel contracts into the brand's currency. If an exchange rate is missing it is not possible to calculate offers for this contract and thus these contracts cannot be marketed. These exchange rates are not used to convert from the brand currency to the selling currency of a website. Those rates need to be stored in a calculation.",
    "exchangerates_info_delivered_by_api": "The portfolio configuration permits importing exchange rates via the API. Note that those changes can be overwritten by changes in the UI and vice versa.",
    "expert": "Expert",
    "export": "export",
    "exportBoardKey_cap": "Board ID",
    "exportRoomKeyBoardKey_cap": "Room/Board ID",
    "exportRoomKey_cap": "Room ID",
    "export_status_exported": "Hotel contract exported successfully",
    "export_status_no_distribution_channels": "Hotel contract is not assigned to any distribution channel",
    "export_status_no_goal_id": "Hotel contract has no GOAL ID",
    "export_status_no_rooms_and_boards": "No exportable rooms and boards found",
    "export_status_not_whitelisted": "Hotel contract not on whitelist",
    "export_status_removed_from_whitelist": "Hotel contract removed from whitelist",
    "export_type_full": "Contract data",
    "export_type_hoteldata": "Hotel data",
    "export_type_masterdata": "Master data",
    "external_app": "External Applications",
    "extra_deleted": "Extra was deleted successfully",
    "extra_max_apply_cap": "Max. Times for Application",
    "extra_of_type_added": "Extra of type \"{0}\" added successfully",
    "extra_of_type_removed": "Extra of type \"{0}\" removed successfully",
    "extras": "extras",
    "extras_assign_page_btn": "assign extra to page",
    "extras_assigned": "assigned",
    "extras_assigned_cap": "Assigned",
    "extras_cap": "Extras",
    "extras_not_assigned": "not assigned",
    "extras_overview_assigned_cap": "Assigned Extras",
    "extras_per_page_cap": "Extras per page",
    "feedback": "Feedback",
    "filter": "filter",
    "filterInformation": {
        "noFilters": "No filters selected: matches everything"
    },
    "filter_airport": "filter airport",
    "filter_btn": "filter",
    "filter_cap": "Filter",
    "filter_city": "filter city",
    "filter_country": "filter country",
    "filter_region": "filter region",
    "filters": {
        "accommodations": "Accommodation types",
        "agoda": "Agoda rating",
        "arrivalAirport": "Arrival airport",
        "assignedBrands": "Assigned Brands",
        "boardCode": "Board",
        "brandConfigId": "Brand",
        "category": "Hotel category",
        "city": "City",
        "contractId": "Contract ID",
        "country": "Country",
        "currency": "Currency",
        "customRate": "Custom Rate",
        "deleted": "In last delivery",
        "descriptionLanguages": "Description texts (languages)",
        "distributionChannel": "Distribution channel",
        "distributionChannelsAssigned": "Assigned to any distribution channel",
        "draftedForBrand": "Drafted for brand",
        "enrichedProgramType": "Enriched Program Types",
        "firstDeliverySince": "Created since",
        "giataIds": "GIATA IDs",
        "globalTypes": "Global Types",
        "goalIds": "GOAL IDs",
        "hasTaxInformation": "Tax Information available",
        "holidaycheck": "HolidayCheck rating",
        "hotelCode": "Hotel code",
        "hotelKey": "Hotel key",
        "hotelName": "Hotel name",
        "lastDeliverySince": "Modified since",
        "localSupplier": "Hotel supplier",
        "localSuppliers": "Hotel supplier",
        "masterCode": "Master code",
        "masterCodeMissing": "Room matching missing",
        "minImageCount": "Image count (min.)",
        "objectType": "Object type",
        "otherContractsWhitelisted": "Hotel has whitelisted contracts",
        "preselectedForCache": "Preselected for cache",
        "region": "Region",
        "reviews": "Count of reviews (min.)",
        "roomCode": "Room",
        "scopedTooltip": "Limited to",
        "source": "Supplier instance/job",
        "status": "Status",
        "tripadvisor": "TripAdvisor rating",
        "trustyou": "TrustYou rating",
        "whitelistedForBrand": "Whitelisted for brand"
    },
    "filterset_add_cap": "Add Filter Set",
    "filterset_cap": "Filter Set",
    "filterset_name_cap": "Name for filter set",
    "firstname": "First name",
    "flight": "Flight",
    "flight_label_cap": "Flight Label",
    "flight_price_table_cap": "Flight-Price Table",
    "flight_restrictions_cap": "Flight",
    "flight_restrictions_count_cap": "Flight",
    "flight_type_cap": "Outbound/Inbound Flight",
    "flightonly": "flight only",
    "full_export_global_types_btn": "GlobalTypes full export",
    "giataId_cap": "GiataId",
    "giata_id": "GIATA ID",
    "giata_ids": "GIATA IDs",
    "global_extras_configuration_call_failed": "Retrieval of global extras configuration failed",
    "global_extras_goal_resolution_disabled": "Assignment of extras on goal level is disabled for this environment",
    "global_supplier_cap": "Supplier",
    "global_type_cap": "GlobalType",
    "global_type_duplicate_warning": "The GlobalType \"{0}\" is already existing for all brands. If you want to assign it to a specific brand, deactivate it on all brands before adding the new one.",
    "global_type_duplicate_warning_click_cap": "Click",
    "global_type_duplication_warnign_click_cap_end": " to show it.",
    "global_type_group_create_cap": "Create GlobalType group",
    "global_type_group_edit_cap": "Edit GlobalType group",
    "global_type_groups_cap": "GlobalType groups",
    "global_types": "GlobalTypes",
    "global_types_cap": "GlobalTypes",
    "global_types_cap_enriched": "GlobalTypes enriched",
    "global_types_custom_rate_rules_cap": "If a room contains the following DRV GlobalType",
    "global_types_types_cap": "GlobalTypes ( GT | ST | AT | CT )",
    "globaltype_filter_cap": "GlobalTypes",
    "globaltype_groups_delete_message": "Do you really want to delete this GlobalType group?",
    "globaltype_groups_delete_title": "Delete GlobalType group",
    "globaltype_groups_deleted_successfully": "GlobalType group successfully deleted",
    "globaltype_groups_saved_successfully": "GlobalType group successfully saved",
    "globaltype_placeholder": "type GlobalType",
    "globaltypes": "GlobalTypes",
    "goalId_cap": "GoalId",
    "goal_id": "GOAL ID",
    "group_cap": "Group",
    "groups_per_page_cap": "Groups per page",
    "gt_additional_type": "AdditionalType",
    "gt_custom_type": "CustomType",
    "gt_globaltype": "GlobalType",
    "gt_source_globaltype_import": "GlobalType Import",
    "gt_source_hotel_contract": "Hotel contract",
    "gt_source_non_bookable_content": "Non-bookable content",
    "gt_source_portfolio": "Portfolio",
    "gt_subtype": "SubType",
    "gu_extra_type": "guide",
    "guest_restrictions_cap": "Travellers (Person Types)",
    "guest_restrictions_count_cap": "Travellers (Person Types)",
    "hasTaxInfo_cap": "Tax Information",
    "here": "here",
    "hf_extra_type": "handling fee",
    "holidaycheck": "HolidayCheck",
    "hotel": "Hotel",
    "hotel_added_to_draft": "Hotel \"{0}\" added to draft",
    "hotel_code_cap": "Hotel code",
    "hotel_codes": "Hotel codes",
    "hotel_description_cap": "Description",
    "hotel_exported_successfully": "exported successfully",
    "hotel_images_cap": "Hotel Images",
    "hotel_info_cap": "Hotel information",
    "hotel_key_cap": "Hotel key",
    "hotel_key_renamed": "previously called \"Contract key\"",
    "hotel_name_cap": "Hotel name",
    "hotel_new_added": "new in draft",
    "hotel_not_exported": "not exported",
    "hotel_on_whitelist": "on whitelist",
    "hotel_ratings_supplier_label": "Hotel rating supplier",
    "hotel_removed_from_draft": "Hotel \"{0}\" removed from draft",
    "hotel_removed_from_whitelist": "hotel \"{0}\" removed from whitelist",
    "hotel_restrictions_cap": "Hotel",
    "hotel_restrictions_count_cap": "Hotel",
    "hotel_supplier_and_codes": "Supplier / Hotel codes",
    "hotel_will_be_removed": "will be removed from whitelist",
    "hoteldetail": "Hotel detail",
    "hotelonly": "hotel only",
    "hotels": "hotels",
    "hotels_added_to_draft": "{0} hotels added to draft",
    "hotels_added_to_whitelist": "added {0} to whitelist",
    "hotels_cap": "Hotels",
    "hotels_per_page_cap": "Hotels per page",
    "hotels_removed_from_draft": "{0} hotels removed from draft",
    "hotels_removed_from_whitelist": "removed {0} hotels from whitelist",
    "hotels_successfully_whitelisted": "Hotels whitelisted",
    "hub": "Hub",
    "hubDashboard": {
        "customer_traffic": "Customer Traffic",
        "request_traffic": "Request Traffic",
        "server_load": "Server Load"
    },
    "iata_code_cap": "IATA Code",
    "id": "ID",
    "ignored": "ignored",
    "image_count": "count of images",
    "image_load_failed": "Image not found",
    "image_loading": "Loading",
    "in_extra_type": "insurance",
    "inactive_gt_source": "inactive",
    "inactive_gt_source_tooltip": "This source will not be exported ( inactive )",
    "include_all": "include all",
    "info_text": "Information text",
    "infx_attributes_cap": "INFX Attributes",
    "infx_cap": "INFX",
    "infx_level_cap": "Level",
    "infx_level_contract": "Contract",
    "infx_level_hotel": "Hotel",
    "infx_level_room": "Room",
    "infx_position_cap": "Position",
    "infx_value_cap": "Value",
    "insurance_and_transfer": "Insurances & transfer",
    "insurances": "Insurances",
    "invalid_brand_exchanges": "The assigned exchange rates cannot be exported. No time gaps or time overlaps can exist. Please check the exchange rate(s) for: {source} > {target}",
    "invalid_brand_exchanges_TOO_MANY_ENTRIES": "The assigned exchange rates cannot be exported. Only a maximum of {maximum} exchange rates are supported, but {given} exchange rates are assigned to this brand.",
    "invert_cap": "Invert",
    "is_assigned_for": "assigned for \"{brand}\"",
    "is_not_assigned_for": "not assigned for \"{brand}\"",
    "is_not_on_whitelist_for": "not whitelisted for \"{brand}\"",
    "is_on_whitelist_for": "whitelisted for \"{brand}\"",
    "key_customer_rate_cap": "Custom rate",
    "key_supplier_cap": "Supplier key",
    "key_supplier_rate_cap": "Supplier rate",
    "keyword": "keyword",
    "keyword_cap": "Keyword",
    "label_cap": "Season Label",
    "lang_de_de": "Deutsch",
    "lang_en_us": "English",
    "language": "language",
    "language_cap": "Language",
    "language_wildcard": "Wildcard ({0})",
    "languages_cap": "Languages",
    "last_delivered_cap": "In last delivery",
    "last_delivery_cap": "Last delivery",
    "last_modification_timestamp_cap": "Last modification",
    "lastname": "Last name",
    "listactions_delete_confirm_btn": "Yes, delete",
    "live_search_brand_config_delete": "Delete Live Brand Configuration",
    "live_search_brand_config_edit": "Edit Live Brand Configuration",
    "live_search_brand_config_register": "Create Live Brand Configuration",
    "live_search_supplier_delete": "Delete Live Search Supplier",
    "live_search_supplier_edit": "Edit Live Search Supplier",
    "live_search_supplier_register": "Create Live Search Supplier",
    "loading": "loading...",
    "local_supplier_cap": "Hotel supplier",
    "local_supplier_rule_cap": "If the hotel supplier",
    "login_msg": "Click the login button, please.",
    "login_via_peakwork": "To login...",
    "logout": "Logout",
    "lookerDashboardConfig": {
        "active": "active",
        "allowedExtraFilters": "Allowed extra filters",
        "config_saved": "Looker configuration saved successfully",
        "config_saved_failed": "Failed to save looker configuration: {0}",
        "dashboardIdLabel": "Looker dashboard id",
        "dashboard_saved": "Dashboard saved successfully",
        "dashboard_saved_failed": "Failed to save dashboard: {0}",
        "defaultFilterKey": "Default filter key",
        "defaultFilterValue": "Default filter value",
        "lookerConfigHead": "Looker Configuration",
        "lookerHost": "Looker host",
        "lookerSecret": "Looker secret",
        "models": "Looker models",
        "userAttribute": "User attributes"
    },
    "lookerDashboardNavigation": {
        "goBackButton": "Go Back",
        "goForwardButton": "Go Forward"
    },
    "lpac": "LPAC",
    "main_code_cap": "Main code",
    "main_codes_cap": "Main codes",
    "main_navigation": {
        "accommodation_sales_control": "Accommodation Sales Control",
        "account_basic": "Customer",
        "account_hub": "HotelData, GeoData, Ratings",
        "accounts": "Accounts",
        "addresses": "Addresses",
        "agencies": "Agencies",
        "agency_numbers": "Agency numbers",
        "assign_distribution_channel": "Distribution Channels",
        "assign_extras": "Assign Extras",
        "boards": "Boards",
        "booking": "Booking",
        "booking_statistics": "Booking Statistics",
        "brand_portfolio": "Brand Portfolio",
        "brand_preselection": "Brand Preselection",
        "brands": "Brands",
        "brands_assignment_cap": "Brands Assignment",
        "brands_copy": "Brand Copy",
        "brands_overview": "Overview",
        "configuration": "Configuration",
        "configuration_basic": "Basic",
        "configuration_dhub": "D-Hub",
        "configuration_dhubcancellationrules": "Cancellation Rules",
        "configuration_dhubdatamix": "Datamix Settings",
        "configuration_hub": "Hub",
        "configuration_lpac": "LPAC",
        "configuration_player": "Player",
        "content": "Content",
        "contract_import": "Portfolio Contract Import",
        "converter_statistics": "Converter Statistics",
        "custom_airports": "Custom Airports",
        "dashboards": "Dashboards",
        "distribution": "Distribution",
        "distribution_channels": "Distribution Channels",
        "dynamic_touroperator": "Dynamic Touroperator Administration",
        "edf_deliveries": "EDF Deliveries",
        "edit_production_specification": "Edit Production Specification",
        "environment": "Environment",
        "events": "Events",
        "exchange_rates": "Exchange Rates",
        "expert": "Expert",
        "external": "External",
        "external_apps": "External Applications",
        "extras": "Extras",
        "flight": "Flight",
        "fulfillment": "Fulfillment",
        "generic_form": "Generic Form",
        "global_type_groups": "GlobalType Groups",
        "home": "Home",
        "hotel": "Hotel",
        "hotel_master_data": "Hotel",
        "hub_statistics": "Hub Search Statistics",
        "insights": "Insights",
        "insurance": "Insurance",
        "live_search_config": "Live Search",
        "live_search_config_brand": "Brands",
        "live_search_config_supplier": "Supplier",
        "live_search_statistics": "Live Search Statistics",
        "live_supplier": "Supplier",
        "looker_dashboard_config": "Looker Dashboard Configuration",
        "lpac": "LPAC",
        "master_data": "Master Data",
        "nonbookablecontent": "Non-Bookable Content",
        "operation": "Operation",
        "overview": "Overview",
        "package_travel_directive": "Package Travel Directive",
        "payments": "Payment Options",
        "peakworkAdmin": "Peakwork Admin",
        "player_production_summary": "Player Production",
        "portfolio": "Portfolio",
        "portfolio_configuration": "Portfolio Configuration",
        "portfolio_management": "Portfolio Management",
        "portfolio_overview": "Portfolio Overview",
        "price_equality_rules": "Price Equality",
        "production": "Production",
        "production_specification": "Production Specification",
        "program_type_enrichment": "Enrich Program Types",
        "rates": "Rates",
        "reference_data": "Reference data",
        "remarks": "Remarks",
        "rooms": "Rooms",
        "scoper": "Portfolio Scoper",
        "supplier_inventory": "Supplier Inventory",
        "supplier_performance": "Supplier Performance",
        "suppliers": "Suppliers",
        "supply": "Supplier",
        "transfer": "Transfer",
        "whitelist_export_statistics": "Whitelist export statistics",
        "whitelisting": "Whitelisting",
        "whitelisting_history": "Whitelist History"
    },
    "mask_cap": "Week Days (Mon-Sun)",
    "master_code_cap": "Master code",
    "master_code_override_cap": "Override master code",
    "master_code_rule_cap": "Master code rule",
    "master_codes_cap": "Master codes",
    "master_data_cap": "Master data",
    "master_data_readonly": "You cannot apply changes due to an ongoing process for this brand (e.g. Whitelisting, Brand copy...) or you are lacking the required permissions to do this.",
    "max_age_cap": "Age (max.)",
    "max_booking_offset_cap": "Max. Number of Days before Booking",
    "max_cap": "Max",
    "max_person_cap": "Number of People (max)",
    "migration_in_progress_active_no_items": "Migration running, count unavailable",
    "migration_in_progress_items": "Items migrated: {0}",
    "migration_in_progress_not_active": "Pending",
    "migration_in_progress_subtitle": "Migrations are currently running. The panel will be available upon completion.",
    "migration_in_progress_title": "Migrations In Progress",
    "min_age_cap": "Age (min)",
    "min_booking_offset_cap": "Min. number of days before booking",
    "min_cap": "Min",
    "min_person_cap": "Number of People (min)",
    "missing_exchange_rates_cap": "Missing exchange rates",
    "mode_cap": "Flight/Hotel",
    "move_to_reorder": "Move to reorder",
    "msg_activate_error": "Error while activating",
    "msg_activate_success": "Activation successful",
    "msg_added_all_result": "{0} added",
    "msg_address_delete": "Do you really want to delete the address \"{0}\"?",
    "msg_agency_delete_cap": "Do you really want to delete the agency \"{0}\"?",
    "msg_agency_number_delete_cap": "Do you really want to delete the agency-number \"{0}\"?",
    "msg_ambiguously_enriched_hotel_giata_id": "Automatically deduced GIATA ID may be ambiguous and incorrect.",
    "msg_auto_whitelist_configurations_successfully_updated": "Auto-Whitelisting configuration successfully updated",
    "msg_board_code_deleted_successful": "Board code \"{0}\" deleted successfully",
    "msg_brand_config_changed_upstream": "The brand configuration has been changed in the meantime. A reset is recommended.",
    "msg_brand_config_saved_successfully": "Brand configuration saved successfully",
    "msg_brand_deactivated": "Warning: Brand is deactivated",
    "msg_brand_delete": "Do you really want to delete brand \"{0}\"?",
    "msg_brand_supplier_existing_flights": "All selected flight supplier will be removed when saving the brand.",
    "msg_brand_supplier_existing_hotels": "All selected hotel supplier will be removed when saving the brand.",
    "msg_brand_supplier_flight_mask": "No flight supplier allowed",
    "msg_brand_supplier_hotel_mask": "No hotel supplier allowed",
    "msg_brands_booking_delete": "Do you really want to delete the B-Hub credentials \"{0}\"?",
    "msg_brands_package_travel_directive_delete": "Do you really want to delete the Package Travel Directive configuration \"{0}\"?",
    "msg_configuration_for_rating_suppliers_successfully_updated": "Configuration for rating suppliers successfully updated",
    "msg_custom_mastercode_missing": "Mastercode is missing. This can be set manually or via the panel enrichment API",
    "msg_custom_rate_edited_successfully": "Custom rate saved successfully",
    "msg_custom_rate_filters_saved_successfully": "Custom rate filters saved successfully",
    "msg_custom_rate_saved_successfully": "Custom rate updated successfully",
    "msg_custom_roomcodes_not_available": "Due to the high amount of sub codes and code length, custom overrides are not available. The rule based overrides (Portfolio Management/Master Data/Rooms) are unaffected and continue to function.",
    "msg_deactivate_error": "Error while deactivating",
    "msg_deactivate_success": "Deactivation successful",
    "msg_definition_not_found": "Could not load the configuration for this form.",
    "msg_delete_confirm": "Do you really want to delete all entries?",
    "msg_delete_error": "Deletion failed",
    "msg_delete_filterset": "Do you want to delete the filter set \"{0}\"?",
    "msg_delete_remark_successfully": "Remark deleted successfully",
    "msg_delete_success": "Deletion successful",
    "msg_descriptions_saving_successful": "Descriptions successfully saved",
    "msg_distribution_channel_delete_cap": "Do you really want to delete the distribution channel \"{0}\"?",
    "msg_distribution_channels_assigned_successfully": "Distribution channels assigned successfully",
    "msg_distribution_channels_removed_successfully": "Distribution channels removed successfully",
    "msg_enriched_hotel_giata_id": "Enriched hotel GIATA ID",
    "msg_fallback_remark_text": "Standard if translation missing",
    "msg_giata_id_unknown": "The given GIATA ID is unknown.",
    "msg_global_type_added_succesfully": "GlobalType added successfully",
    "msg_global_type_deleted_succesfully": "GlobalType deleted successfully",
    "msg_global_type_edited_succesfully": "Editing of GlobalType saved successfully",
    "msg_global_type_export_succesfully": "GlobalTypes export triggered successfully",
    "msg_goal_id_unknown": "The given GOAL ID is unknown.",
    "msg_hotel_not_found": "Hotel not found",
    "msg_hotel_preselection_config": "Assign new Imports to brands",
    "msg_init_brand_copy_error": "Failed to load Brand",
    "msg_instance_not_found": "The entry does not exist or could not be found.",
    "msg_insufficient_edit_permissions": "You are lacking the required permissions to edit this",
    "msg_live_search_brand_delete": "Do you really want to delete the Live Search Configuration for the brand \"{0}\"?",
    "msg_live_search_supplier_delete": "Do you really want to delete the Live Search Configuration for the supplier \"{0}\"?",
    "msg_missing_master_code_rooms_or_boards": "Some of the rooms or boards do not have a master code matching and cannot be processed yet",
    "msg_no_active_suppliers_configured": "No active suppliers configured for this brand!",
    "msg_no_board_restrictions": "No restriction for boards defined",
    "msg_no_brand_configured": "No brand configured!",
    "msg_no_brand_selected": "No brand selected!",
    "msg_no_charges": "No surcharges specified",
    "msg_no_contracts_for_this_brand": "There are no contracts for the chosen brand.",
    "msg_no_date_restrictions": "No restriction for booking/travel period specified",
    "msg_no_distribution_channels_assigned_to_contract": "No distribution channels assigned to contract",
    "msg_no_distribution_channels_defined": "No distribution channels created yet.",
    "msg_no_external_applications": "Currently, there are no external applications installed.",
    "msg_no_flight_restrictions": "No flight restriction specified",
    "msg_no_guest_restrictions": "No guest restrictions specified",
    "msg_no_hotel_restrictions": "No hotel restrictions specified",
    "msg_no_program_types_defined": "No program types have been created",
    "msg_no_restrictions": "No restrictions specified",
    "msg_no_results_found": "No results found",
    "msg_no_season_restrictions": "No season restrictions specified",
    "msg_no_stacks_found": "No stacks found.",
    "msg_no_stay_restrictions": "No restrictions for the duration of stay specified",
    "msg_no_suppliers_configured": "No suppliers configured for this brand!",
    "msg_no_weekday_restrictions": "No restrictions on week days defined ",
    "msg_not_delivered_by_any_supplier": "Not delivered by any supplier",
    "msg_page_not_found": "The page \"{0}\" could not be found.",
    "msg_payment_delete": "Do you really want to delete the payment \"{0}\"?",
    "msg_rate_rule_deleted": "Rate deleted successfully",
    "msg_rate_rules_saved_successfully": "Custom rate rules saved successfully",
    "msg_room_and_board_selection_not_saveable": "At least one room and board must be selected",
    "msg_room_and_board_selection_saved_succesfully": "Your room and board selection saved successfully",
    "msg_room_board_brand_configuration_saved_successfully": "Configuration for rooms and boards successfully updated",
    "msg_room_code_config_changed_successfully": "Room code configuration updated successfully",
    "msg_room_code_deleted_successful": "Room code \"{0}\" deleted successfully",
    "msg_room_gts_incomplete": "Room GlobalTypes applying to the full contract are not displayed",
    "msg_room_key_unknown": "The selected room could not be found",
    "msg_room_matching_configuration_saved_successfully": "Room matching configuration saved successfully",
    "msg_rule_saved_succesfully": "Rule saved successfully",
    "msg_save_error": "Saving failed",
    "msg_save_error_buyprice_roomcode_mismatch": "Saving Failed! When 'Best Price Logic' is set to 'Sellprice', 'Offer Grouping' must be set to 'Room codes and Hotel Codes'",
    "msg_save_error_checkfields": "Please check your input fields",
    "msg_save_error_generic": "Saving failed: {0}",
    "msg_save_hotel_data_success": "Saving hotel data successful",
    "msg_save_success": "Saving successful",
    "msg_save_warning_remark_applies_to_all_hotels": "Remark will apply to all hotels, please confirm",
    "msg_saving_exchangerates_config_successful": "Configuration of Exchange rate sources successfully saved",
    "msg_saving_globaltypes_settings_successful": "Configuration of GlobalType sources successfully saved",
    "msg_saving_hotelpreselection_config_successful": "Configuration of Hotel Preselection successfully saved",
    "msg_state_change_dc_start": "Processing of the distribution channel finished. Click",
    "msg_state_change_end": "to update the hotel list.",
    "msg_state_change_start": "Contracts state has changed. Click",
    "msg_statuschange_failed_unknown": "Unexpected error while changing status",
    "msg_statuschange_success": "Initialized status change successfully",
    "msg_success_full_export": "Full export triggered successfully",
    "msg_supplier_delete": "Do you really want to delete supplier \"{0}\"?",
    "msg_unknown_rooms_config_changed_successfully": "Settings for unknown rooms changed successfully",
    "msg_unsaved_changes": "Warning: Unsaved changes!",
    "msg_unsaved_changes_prompt": "You have unsaved changes. Do you want to discard them?",
    "name": "name",
    "name_cap": "Name",
    "never": "never",
    "new": "new",
    "new_exchange_rate_cap": "Create new exchange rate",
    "new_global_type_cap": "New GlobalType",
    "new_rule_btn": "new rule",
    "no": "no",
    "no_accommodations_found": "No accommodation types found",
    "no_airports_found": "No airports found",
    "no_board_codes_found": "No board codes found",
    "no_board_descriptions": "No board descriptions for language \"{0}\" found",
    "no_brand_selected_to_assign_exchange_rates": "Please select a brand",
    "no_city_found": "No city found",
    "no_country_found": "No country found",
    "no_custom_airports_found": "No custom arrival airports found",
    "no_custom_rate_found": "no custom rates found",
    "no_delivered_supplier_rates": "No rates found",
    "no_description_cap": "No descriptions available",
    "no_exchange_rate_templates_configured": "No exchange rates configured yet.",
    "no_exchange_rates_for_brand": "No exchange rates assigned.",
    "no_filters_selected_msg": "No filters selected",
    "no_globaltype_found": "No GlobalType found",
    "no_ignored_room_descriptions": "No ignored room descriptions for language \"{0}\" found",
    "no_images_found": "No images found",
    "no_local_supplier_selected": "No local suppliers are selected",
    "no_missing_exchange_rates": "No missing exchange rates found",
    "no_region_found": "No region found",
    "no_room_codes_found": "No room codes found",
    "no_room_descriptions": "No room descriptions for language \"{0}\" found",
    "no_room_descriptions_for_rule": "No room descriptions found for selected rule",
    "no_tokens_to_ignore_selected": "No tokens to ignore selected",
    "no_tokens_to_restore_selected": "No tokens to restore selected",
    "none": "None",
    "none_assigned": "None",
    "none_for_contract": "None for the entire contract",
    "not_applicable_filters": "Parts of your filter set is not applicable in this context",
    "not_assigned": "not assigned",
    "not_drafted": "not drafted",
    "not_in_brand_filter": "excluded by brand configuration on export",
    "not_valid_for": "not valid for",
    "not_whitelisted": "not whitelisted",
    "num_splits": "NumSplits (Player)",
    "number": "Number",
    "object_type_contract": "contract",
    "object_type_product": "product",
    "off": "off",
    "ok": "OK",
    "open_application": "Open application",
    "open_in_new_window": "Open application in a new window",
    "open_provided_exchange_rates": "request exchange rates",
    "operator_cap": "Operator",
    "output_cap": "Output",
    "override_room_desc_failed": "An error occurred while updating the room description",
    "override_room_desc_successfully_saved": "Room description successfully saved",
    "overrides_cap": "Overrides",
    "overview_cap": "Overview",
    "overwrite": "Overwrite",
    "overwrite_hotel_category": "Overwrite Hotel category",
    "overwrite_supplier_remarks": "Overwrite supplier remarks",
    "package": "package",
    "page_not_found": "Page not found",
    "panel": "Panel",
    "payment_delete": "Delete payment",
    "payment_edit": "Edit payment",
    "payment_options_cap": "Payment Options",
    "payment_register": "Create payment",
    "payment_template": "Payment type",
    "percent_cap": "Percent",
    "person_type_cap": "Person Type",
    "pf_extra_type": "payment fee",
    "portfolio_config": {
        "brand": {
            "master_codes_title": "Master codes",
            "title": "Brand Configuration",
            "use_master_codes_in_distribution": "Use master codes in distribution (hotel, room & board)"
        },
        "exchange_rates_sources": {
            "cap": "Exchange rates API Import",
            "options": {
                "API_IMPORT": "Enable import via API. Changes can still be made on the UI. Please note that changes via UI and API do overwrite each other."
            }
        },
        "globaltypes_sources": {
            "cap": "GlobalTypes Sources",
            "globaltypes_blacklist": {
                "info": "The GlobalType blacklist defines a list of GlobalTypes that are excluded from all Offers. Trailing wildcards are allowed in all 4 GlobalType parts. It is important to note that only supplier GlobalTypes can be excluded.",
                "label": "GlobalTypes Blacklist"
            },
            "info": "GlobalTypes identify hotel facilities and features with respect to a set of standardized codes. Based on the list of GlobalTypes a hotel can be searched on different distribution websites. The set of GlobalTypes for one hotel is determined by one to four different sources that can be activated. In this configuration tab you can decide whether you want to activate all sources (default) or only some of them.",
            "options": {
                "CONTENT": "Non-bookable content (GlobalTypes delivered in the non-bookable content from hotel suppliers)",
                "HOTELCONTRACT": "Hotel contract (GlobalTypes delivered from hotel suppliers)",
                "IMPORT": "GlobalTypes import (GlobalTypes import through the 'Enrichment Import API')",
                "PORTFOLIO": "Portfolio (GlobalTypes created in the Panel by users)"
            }
        },
        "hotel_preselection_cap": "Brand Preselection",
        "primary_hotelid_cap": "Primary Hotel ID",
        "primary_hotelid_info": "This BOX identifies hotels by their {0} ID.",
        "unknown_rooms": {
            "cap": "Unknown Rooms",
            "info": "Some live suppliers do not provide a complete list of rooms. This option controls whether unknown rooms are discoverable in search requests. <br> This option can be overridden per contract per brand.",
            "options": {
                "EXPORT_ANY": "Include all unknown rooms",
                "EXPORT_KNOWN_ROOMS_WITH_UNKNOWN_BOARDS": "Include known rooms with unknown boards",
                "EXPORT_NONE_UNKNOWN": "No unknown rooms"
            }
        }
    },
    "post_filter_selection_tooltip": "Drafting and whitelisting is not available for the 'room matching missing' filter",
    "post_filter_warning_cap": "The total count cannot be determined when using the 'room matching missing' filter",
    "preselect_for_cache": "Preselect hotels for cache",
    "preselected_for_cache_cap": "Preselected for cache",
    "preselection_all_brands_option": "Assign all Brands",
    "preselection_blocked_warning": "Due to a brand copy process, the selected change cannot currently be applied.",
    "preselection_change_whitelist_cap": "Will affect current draft",
    "preselection_no_assignment_option": "No assignment",
    "preselection_remove_warning": "Removing these Brands will remove contracts from the draft. A whitelisting will need to be performed to remove them from production",
    "process_none": "no process",
    "productionDashboard": {
        "portfolio_overview_over_time": "Portfolio Overview over Time"
    },
    "production_specification": {
        "add_cap": "Create Production Specification",
        "central_supplier_cap": "Central Supplier",
        "edit_cap": "Edit Production Specification",
        "form": {
            "calendar_toggle_title": "Toggle between relative and absolute date",
            "date_cap": "Date",
            "evaluate_btn": "evaluate hotel & product counts",
            "export_csv_btn": "export as CSV",
            "filter_modal_cap": "Edit rule filters",
            "implicit_rule": "Implicitly added rule: matches everything",
            "local_supplier_placeholder": "Select any number of local suppliers",
            "los_cap": "Length of Stay (LOS)",
            "no_rule": "No rule has been specified: matches nothing",
            "no_supplier_request_threshold": "No supplier request limit found",
            "no_threshold_evaluation": "Re-evaluation required for the threshold calculation",
            "occupancies": {
                "adults_cap": "Adults",
                "children_cap": "Children",
                "infants_cap": "Infants"
            },
            "occupancies_cap": "Occupancy",
            "rule_type_cap": "Rule Type",
            "rules_cap": "Rules",
            "save_tooltip": {
                "error": "The input is not valid",
                "no_changes": "There are no changes to save"
            },
            "settings_cap": "Settings",
            "supplier_limit_cap": "Supplier Limit",
            "threshold_calculation_cap": "Request Threshold Calculation",
            "threshold_value_cap": "Threshold value (%)",
            "total_requests_cap": "Total Requests"
        },
        "hotel_count_cap": "Hotel Count",
        "last_export_summary": {
            "assigned_specifications": "Assigned Specifications",
            "cap": "Last Export Summary",
            "error_prompt": "Specification export will fail, please reduce scope",
            "estimated_requests_produced": "Estimated Requests Produced",
            "estimated_total_offers": "Estimated Total Requests",
            "hotel_supplier": "Hotel Supplier",
            "incomplete_estimate": "Incomplete estimate due to missing data",
            "last_export_time": "Last Export Time",
            "multiplication_factor": "Multiplication Factor",
            "no_assigned_specifications": "No exported specifications for supplier",
            "product_count": "Product Count",
            "specification_name": "Specification Name",
            "state": "State",
            "supplier_threshold": "Supplier Threshold",
            "threshold_update": "Current supplier threshold has changed since last export",
            "threshold_value": "Threshold Value (%)",
            "total_threshold_value": "Total Threshold Value (%)",
            "warning_prompt": "Specification is nearly at limit, consider reducing scope"
        },
        "modal": {
            "cap": "Assigned Production Specifications",
            "error_filter_call_failed": "An error occurred while retrieving production specification assignments",
            "hotel_supplier": "Hotel Supplier Code",
            "included": "Included",
            "matching_rule": "Influencing Rule",
            "name": "Name",
            "no_rules_found": "No rules match to this hotel entry",
            "no_specifications_found": "No production specifications found",
            "not_preselected": "This hotel is not preselected for the cache and is thus exempted from production."
        },
        "no_localsupplier": "There are no local suppliers for this central supplier",
        "notify": {
            "activate_success": "Production specification has been activated",
            "assignment_error": "Error during production specification assignment",
            "assignment_success": "Production specification assignment has been saved",
            "deactivate_success": "Production specification has been deactivated",
            "delete_error": "Production specification could not be deleted",
            "delete_success": "Production specification has been deleted",
            "evaluate_error": "Production specification rules could not be evaluated",
            "fetch_error": "Unable to fetch available production specifications",
            "fetch_export_history_error": "Unable to fetch last export information",
            "form_error": "The input form contains invalid data",
            "save_error": "Production specification could not be saved",
            "save_success": "Production specification has been saved"
        },
        "product_count_cap": "Product Count",
        "prompt": {
            "delete": "Do you really want to delete the production specification '{0}'?"
        },
        "scoped": "Production specifications cannot be edited with a limited scope",
        "select_cap": "Production Specifications",
        "states": {
            "ERROR": "Error",
            "OK": "Successful",
            "no_state": "N/A"
        },
        "table": {
            "error_prompt": "Review supplier threshold in \"Last Export Summary\" tab",
            "last_export_per_supplier": "Last export per supplier",
            "last_export_state_cap": "State of last export",
            "last_export_time_cap": "Last export",
            "n_during_last_export": "{0} during last export",
            "no_export": "There was no export.",
            "no_of_suppliers_cap": "Number of suppliers",
            "show_details": "details"
        },
        "table_cap": "Production Specifications",
        "tabs": {
            "last_export_summary": "Last Export Summary",
            "manage_specifications": "Manage Specifications",
            "search_hotel_assignments": "Search Hotel Assignments"
        }
    },
    "program_type_cap": "Program type",
    "program_type_create_placeholder": "new program type",
    "program_type_enrichment_cap": "Overview",
    "program_type_management_cap": "Manage program types",
    "program_type_mastercode_active_tooltip": "Due to the mastercode rule, this will be added to the mastercode on export",
    "program_type_mastercode_warning": "The program type is only added on export and has an undetermined length. Therefore the mastercode length may vary in production and is not fixed",
    "program_types_cap": "Select Program types",
    "program_types_create_cap": "Program types",
    "program_types_delete_btn_warn": "confirm delete",
    "program_types_delete_hover": "Deleting will automatically remove assignment from all hotels. This may take some time.",
    "provided_currency_cap": "requested",
    "provided_exchange_rates": "Exchange rates",
    "rate_cap": "Rate",
    "ratings": {
        "value_not_set": "No Rating"
    },
    "rc_extra_type": "rental car",
    "refresh": "refresh",
    "region_cap": "Region",
    "relationship_cap": "Relation",
    "relative_cap_suffix": "(relative)",
    "release_date_title": "Release date",
    "release_notes_description": "The BOX release notes describe important changes, newly added features, improvements and removed/deprecated features and/or APIs.",
    "release_notes_header": "Box release notes",
    "release_time_cap": "Release Time",
    "remarkFilters": {
        "brand": "Brand",
        "giataId": "GIATA ID",
        "giataIds": "GIATA IDs",
        "goalId": "GOAL ID",
        "goalIds": "GOAL IDs",
        "hotelName": "Hotel Name",
        "language": "Language",
        "masterCode": "Master Code",
        "title": "Title"
    },
    "remark_cap": "Remark",
    "remark_label_cap": "Label",
    "remarks_cap": "Remarks",
    "remarks_per_page_cap": "Remarks per page",
    "remove": "remove",
    "remove_brands": "Remove brands",
    "remove_distribution_channel_btn": "remove distribution channels",
    "remove_distribution_channel_cap": "Remove distribution channel",
    "remove_draft_all_btn": "remove result from draft",
    "remove_draft_page_btn": "remove page from draft",
    "remove_extras_btn": "remove from result",
    "remove_from_draft": "remove from draft",
    "remove_program_types_cap": "remove program types",
    "request_cap": "On Request",
    "required": "required",
    "required_field": "required",
    "required_fields": "Required fields",
    "required_fields_tooltip": "Required fields are automatically prepended when they were not selected below",
    "reset": "reset",
    "reset_rule": "reset rule",
    "restrictions_cap": "Restrictions",
    "restrictions_count_cap": "Restrictions (Count)",
    "reviews": "Reviews",
    "rf_extra_type": "rail and fly",
    "roomOverrides": {
        "filters": {
            "contractId": "Contract Id",
            "roomKey": "Room Key"
        },
        "table": {
            "cap": "Room Overrides",
            "data": {
                "disableLinkText": "Not available"
            },
            "emptyText": "No room overrides found",
            "header": {
                "contractId": "Contract Id",
                "description": "Room Description ({0})",
                "giataId": "GIATA ID",
                "goalId": "GOAL ID",
                "hotelName": "Hotel name",
                "link": "Details",
                "localSupplierCode": "Supplier Code",
                "mainRoomTag": "Main Room Tag",
                "masterCode": "Master Code Override",
                "roomKey": "Room Key",
                "subRoomTags": "Sub Room Tags"
            }
        }
    },
    "room_board_leave_page_confirm": "Yes, leave page",
    "room_board_leave_page_message": "Do you want to leave page without saving ?",
    "room_board_leave_page_title": "Unsaved changes",
    "room_board_leave_table_confirm": "Yes, leave table",
    "room_board_leave_table_message": "Do you want to leave table without saving ?",
    "room_cap": "Room",
    "room_code_assigned": "then assign room type",
    "room_code_cap": "Room code",
    "room_code_not_found": "Assigned room code could not be found",
    "room_detail": {
        "allotment_view": {
            "avail": {
                "NO_ALLOTMENTS": "No Allotments"
            },
            "day": "Day",
            "options": {
                "half_year": "6 months",
                "year": "12 months"
            }
        },
        "offer_view": {
            "allotment": "Allotment",
            "arrival_date": "Arrival date",
            "avail": {
                "AVAILABLE": "Available",
                "BOOKED_OUT": "Booked out",
                "ON_REQUEST": "On request",
                "STOP_SALE": "Stop sale"
            },
            "avg_night": "Avg. night",
            "avg_stay": "Avg. stay",
            "currency": "All prices in {0}",
            "date_range": "Results for {0} up to {1}",
            "details": {
                "availability_cap": "Availability",
                "card_cap": "Offer Details",
                "date_and_los_cap": "Date and period",
                "no_offer_found": "No offer found for selected date.",
                "no_selection": "No offer selected. Click on a cell in the results table to view details here.",
                "pax_prices_cap": "Pax Prices",
                "price_cap": "Price"
            },
            "error": {
                "bad_los": "Invalid LOS. Number must be between 1 and {0}, inclusive.",
                "bad_pax": "Invalid pax age. The age must be positive.",
                "generic": "An error occurred while requesting offer information.",
                "no_match_hotel": "Hotel could not be found.",
                "no_match_room": "Room could not be found.",
                "no_occupancies": "There are no offers for the requested occupancy.",
                "no_offers": "No offers found for the given configuration.",
                "no_results": "No offers found."
            },
            "filter": {
                "arrival_date": "Arrival date",
                "arrival_date_placeholder": "Earliest available",
                "paxes": "Paxes (age)"
            },
            "no_data_for_live_suppliers": "This view is not supported for live search suppliers",
            "no_results": "No results",
            "price_info": "Uncalculated supplier prices",
            "results_cap": "Results",
            "show": "Show",
            "show_offers_title": "Show offers"
        },
        "tabs": {
            "allotments": "Allotments",
            "list": "List",
            "master_code": "By master code",
            "offers": "Offers"
        },
        "tabs_cap": "Rooms and boards"
    },
    "room_images": "Room Images",
    "room_information_title": "Room Details",
    "room_key_cap": "Room key",
    "room_master_code_descriptions": "Room descriptions",
    "room_matching_configuration_title": "Room matching configuration",
    "room_matching_configurator": {
        "add_buttons": {
            "code_sequence": "add a code sequence",
            "matching_character_code": "add a character",
            "sub_code": "add a sub code"
        },
        "add_room_config": "new configuration",
        "append_custom_rate_descriptions": "Append custom rate descriptions",
        "append_custom_rate_descriptions_max_room_descriptions_size": "Max room descriptions size",
        "auto_matching": "Automatic room code matching",
        "cancel_room_config_creation": "cancel configuration creation",
        "cancel_room_config_edit": "cancel configuration edit",
        "caps": {
            "CONSTANT": "Constant Character",
            "MAIN_CODE": "Main Code",
            "SEQUENCE": "Sequence Length",
            "SUB_CODE": "Sub Code"
        },
        "configurator": "Configurator",
        "delete_room_config": "delete configuration",
        "edit_room_config": "edit configuration",
        "one_of_two_must_be_activated": "\"{0}\" must be activated if \"{1}\" is not activated",
        "overwrite_room_descriptions": "Overwrite room descriptions",
        "preview": "CODE PREVIEW",
        "reset_configuration": "reset configuration",
        "room_configuration_copy": "Copy an existing configuration",
        "room_configuration_set_as_default": "Set configuration as default",
        "room_name_prompt": "Configuration Name",
        "set_code_length": "Main/Sub Code Length",
        "validations": {
            "config_name_already_used": " Configuration name is already in use",
            "main_code_position": "Main code must be first",
            "matching_type_auto_cannot_contain": "Code sequences or characters are only allowed if the room descriptions are not overwritten",
            "matching_type_none_cannot_contain": "Code sequences or characters are only allowed if the codes are automatically matched",
            "missing_form_data": "Required configuration data missing",
            "none_selected": "Room code auto matching will not be applied",
            "rule_is_valid": "Rule is valid",
            "semi_auto_selected": "Supplier descriptions will be exported as room descriptions",
            "sequence_digits_positive": "Number of digits for sequence must be positive",
            "toma_warning": "Configuration is not TOMA-compliant"
        }
    },
    "room_name_cap": "Room name",
    "room_names_code_cap": "Room/Board Name ",
    "room_rule_added_successfully": "Rule added successfully",
    "room_rule_deleted_successfully": "Rule deleted successfully",
    "room_rule_edited_successfully": "Rule edited successfully",
    "room_tag_cap": "Room tag",
    "room_tag_sub_cap": "Sub tag {index}",
    "rooms": "rooms",
    "rooms_and_boards_cap": "Rooms and boards",
    "rooms_cap": "Rooms",
    "rooms_count_cap": "Rooms count",
    "roomsandboards": "Rooms and Boards",
    "rounding_cap": "Rounding",
    "rule": "rule",
    "rule_already_exists": "Rule already exists",
    "rule_cap": "Rule",
    "rule_matches": "Rule \"{0}\" matches",
    "rule_matches_modal_description": "The displayed matches consider only the current rule and are not taking any rule priority into account",
    "rule_matches_no_active_supplier": "Hotel supplier unknown",
    "rule_preview": "Rule preview",
    "rule_right_length": "The rule has the correct length",
    "rule_too_long": "The rule is too long",
    "rule_too_short": "The rule is too short",
    "rule_types": {
        "CONSTANT": "Constant",
        "IATA_CODE": "Airport Code",
        "PROGRAM_TYPE": "Program type",
        "SEQUENCE": "Counter"
    },
    "rules_cap": "Rules",
    "save": "save",
    "save_and_apply": "Save & Apply",
    "save_selected": "adopt selected exchange rates",
    "score": "Score",
    "search": "search",
    "search_cap": "Search",
    "select_airports": "select airports",
    "select_board_tag": "select board tag",
    "select_brand": "select brand",
    "select_currency": "select currency",
    "select_custom_board_code": "custom board code",
    "select_custom_room_code": "custom room code",
    "select_distribution_channel": "choose distribution channel",
    "select_env": "Select environment",
    "select_filterset": "select filter set",
    "select_language": "select language",
    "select_room_code": "select room code",
    "select_room_matching_config": "select a room matching configuration",
    "select_room_tag": "select room tag",
    "select_supplier": "select supplier",
    "select_supplier_cap": "Select supplier",
    "selected": "selected",
    "selected_cap": "Chosen",
    "selected_fields": "Selected fields",
    "selected_filter_criteria": "Selected Filter Criteria",
    "selected_globaltypes_blacklist_cap": "Selected GlobalType Blacklist",
    "selected_globaltypes_filter_cap": "Selected GlobalType Filter",
    "selected_rooms_cap": "Selected rooms",
    "sequence_cap": "Sequence (Days)",
    "show": "show",
    "show_globaltype": "show",
    "show_hide": "show / hide",
    "show_hotels_for_remark": "show hotels for remark",
    "show_infx": "show",
    "show_ratefilter_excluded_rooms_cap": "Show rooms not matched by custom rate filter",
    "sidebar": {
        "ascAssignment": {
            "abbr": "ASC",
            "description": "Modification of accommodation sales control restrictions to the following brands:",
            "title": "Accommodation Sales Control restrictions"
        },
        "automatchingConfigChange": {
            "abbr": "AC",
            "description": "Auto matching config changes are being applied",
            "title": "Auto Matching Config Changes"
        },
        "brandAssignmentInProgress": {
            "abbr": "BP",
            "description": "Brand Preselection changes are being applied",
            "title": "Brand Preselection Changes"
        },
        "brandcopy": {
            "abbr": "BC",
            "description": "Copying brands:",
            "title": "Copy Brand"
        },
        "contract_import": {
            "abbr": "IHC",
            "description": "Importing hotel contracts for the following suppliers",
            "title": "Import Hotel Contracts"
        },
        "customRates": {
            "abbr": "CRR",
            "description": "Changes to Custom Rate Rules are being applied. All Brands are currently locked.",
            "title": "Custom Rate Rules"
        },
        "dcAssignment": {
            "abbr": "DCA",
            "description": "Distribution Channel Assignment is in progress for the following brands:",
            "title": "Distribution Channel Assignment"
        },
        "draft": {
            "abbr": "DRA",
            "description": "Drafting is in progress for the following brands:",
            "title": "Drafting for Brand"
        },
        "global_extras_assignment": {
            "abbr": "AGE",
            "description": "Assigning Global Extras to the following brands:",
            "title": "Assign Global Extras"
        },
        "global_extras_export": {
            "abbr": "EGE",
            "description": "Global Extras Export",
            "title": "Export Global Extras"
        },
        "global_extras_import": {
            "abbr": "IGE",
            "description": "Import global extras",
            "title": "Import Global Extras"
        },
        "globaltypes_export": {
            "abbr": "GTE",
            "description": "Exporting Globaltypes for following brands:",
            "title": "Globaltypes Export"
        },
        "migrations": {
            "abbr": "MIG",
            "description": "the following migrations are being applied",
            "title": "Migrations"
        },
        "productionSpecificationAssignmentInProgress": {
            "abbr": "PSA",
            "description": "Production Specification Assignment is in progress",
            "title": "Production Specification Assignment"
        },
        "wlProcess": {
            "abbr": "EWL",
            "description": "Exporting hotel contracts for following brands:",
            "title": "Export Whitelist"
        }
    },
    "sk_extra_type": "ski pass",
    "source_cap": "Supplier instance/job",
    "source_rate_cap": "Source",
    "sources_cap": "Source",
    "stage_cap": "Stage",
    "stars": "stars",
    "stars_cap": "Stars",
    "start_cap": "Start",
    "start_date_cap": "Start Date",
    "started_at": "Started",
    "status_cap": "Status",
    "stay_period_cap": "Period of stay",
    "stay_restrictions_cap": "Duration of stay",
    "stay_restrictions_count_cap": "Duration of stay",
    "street_cap": "Street",
    "sub_codes_cap": "Sub codes",
    "sub_tags_cap": "Sub tags",
    "submit": "save",
    "subtype_placeholder": "type SubType",
    "successfully_saved_filterset": "Successfully saved filter set",
    "supplier": "supplier",
    "supplierDashboard": {
        "last_edf_deliveries": "Last full EDF deliveries"
    },
    "supplier_board_code_cap": "Supplier board code",
    "supplier_board_key_cap": "Supplier board key",
    "supplier_cap": "Supplier",
    "supplier_delete": "Delete supplier",
    "supplier_edit": "Edit supplier",
    "supplier_hotel_cap": "Hotel Supplier",
    "supplier_register": "Create supplier",
    "supplier_sources_cap": "Source (supplier)",
    "suppliers": "suppliers",
    "suppliers_cap": "Suppliers",
    "suppliers_type_custom_alert": "curl -X url/{0}",
    "target_currency_cap": "Target currency",
    "target_rate_cap": "Target",
    "technical_source_cap": "Source",
    "technical_sources_cap": "Source (technical)",
    "the_custom_rate_cap": "The custom rate",
    "threshold": "Threshold",
    "title_cap": "Title",
    "to": "to",
    "to_draft_btn": "draft",
    "today": "today",
    "token_ignored_successfully": "Token was ignored successfully",
    "token_restored_successfully": "Token was restored successfully",
    "tokens_ignored_successfully": "Tokens ignored successfully",
    "tokens_restored_successfully": "Tokens restored successfully",
    "tooltip_add_token_as_rule_btn": "create rule",
    "tooltip_board_mastercode_missing": "Board code missing",
    "tooltip_hotel_contract_delivered": "Hotel contract in last delivery",
    "tooltip_hotel_contract_drafted": "Hotel contract on draft",
    "tooltip_hotel_contract_not_delivered": "Hotel contract not in last delivery. Removed on {0}",
    "tooltip_hotel_contract_not_drafted": "Hotel contract not on draft",
    "tooltip_hotel_contract_not_whitelisted": "Hotel contract not on whitelist",
    "tooltip_hotel_contract_whitelisted": "Hotel contract on whitelist",
    "tooltip_ignore_token_btn": "ignore",
    "tooltip_no_rooms_selected": "No rooms selected for export",
    "tooltip_restore_token_btn": "restore",
    "tooltip_room_custom_overrides_deactivated": "Custom room master codes cannot be applied for the selected configuration",
    "tooltip_room_mastercode_missing": "Room code missing",
    "total_board_codes": "Board codes total:",
    "total_contracts_cap": "Contracts total:",
    "total_contracts_on_page_cap": "Contracts on page:",
    "total_custom_rates": "Total rates:",
    "total_extras_cap": "Extras total:",
    "total_groups_cap": "Groups total:",
    "total_hotels_cap": "Hotels total:",
    "total_hotels_on_page_cap": "Hotels on page:",
    "total_remarks_cap": "Remarks total:",
    "total_room_codes": "Room codes total:",
    "total_rooms_cap": "Total rooms",
    "touroperator_code_cap": "Extra Supplier",
    "tr_extra_type": "transfer",
    "travel_type_cap": "Travel type",
    "trigger_full_export_btn": "trigger full export",
    "tripadvisor": "TripAdvisor",
    "trustyou": "TrustYou",
    "tt_missing_permissions": "You are lacking the required permissions to do this",
    "type": "type",
    "type_cap": "Type",
    "unknown_airport": "unknown airport",
    "unknown_brand": "unknown brand",
    "unknown_rooms": {
        "card_cap": "Unknown rooms",
        "info": "Some live suppliers do not provide a complete list of rooms. This option controls per brand whether unknown rooms are discoverable in search requests.",
        "list_was_complete": "All rooms are known",
        "options": {
            "EXPORT_ANY": "Allow unknown rooms",
            "EXPORT_KNOWN_ROOMS_WITH_UNKNOWN_BOARDS": "Allow known rooms with unknown boards",
            "EXPORT_NONE_UNKNOWN": "Disallow unknown rooms",
            "null": "Use default from portfolio config ({0})"
        },
        "options_fallback": {
            "EXPORT_ANY": "allow",
            "EXPORT_KNOWN_ROOMS_WITH_UNKNOWN_BOARDS": "include with unknown boards",
            "EXPORT_NONE_UNKNOWN": "disallow",
            "null": "unknown"
        }
    },
    "unknown_supplier": "unknown supplier",
    "unmatched": "unmatched",
    "update_auto_whitelist_configuration_warning": "When enabling the auto-whitelisting for a brand, <b>an automatic whitelisting and export will be triggered for all contracts of the brand</b>",
    "update_brand_config_cap": "Brand portfolio configuration",
    "update_brand_configuration_message": "Do you really want to change the brand configuration?",
    "update_custom_rate_filters": "To apply the changed room selection, <b>an export will be triggered for all contracts of the brand</b>.",
    "update_master_codes_in_distribution_warning": "When changing the usage of master codes, <b>an export will be triggered for all contracts of the brand</b>.",
    "update_rating_config_description": "Changing rating providers may have an influence on existing filtersets.",
    "update_room_matching_brand_assignment": "When a matching configuration is changed, <b>an export will be triggered for all contracts of the brand<b>. Additionally, <b>all assigned master codes will be removed!</b>",
    "usage": "usage",
    "validation": {
        "greaterEqualThanXy": "{0} must be greater than or equal to {1}",
        "minValue": "Must be a minimum value of {0}",
        "required": "This field is required"
    },
    "validation_error_mastercode_constant_empty": "Please insert constant.",
    "validator_alphaNum": "{0} may only contain alpha-numeric characters.",
    "validator_alphaNumExt": "{0} may only contain alpha-numeric characters as well as spaces.",
    "validator_alphaNumHyphen": "{0} may only contain uppercase letters, numbers as well as hyphens.",
    "validator_alphaSpaces": "{0} may only contain letters as well as spaces.",
    "validator_alpha_num_no_case_hyphen": "{0} may only contain alpha-numeric characters or hyphens",
    "validator_at_least_one_globaltype_required": "At least one GlobalType is required",
    "validator_decimalExt": "{0} may only contain numbers and point or comma as decimal seperator.",
    "validator_different_currencies": "Contract and Brand currency must be different from one another",
    "validator_duplicate_attribute": "{0} already exists.",
    "validator_duplicate_rule": "Rule already exists.",
    "validator_global_type_name": "Name must contain only uppercase letters and numbers",
    "validator_letters_env": "{0} may only contain letters, numbers.",
    "validator_maxLength": "The {0} must not be longer than {1} characters.",
    "validator_minLength": "The {0} must be at least {1} characters long.",
    "validator_multiInteger": "{0} may only contain numbers separated by a comma.",
    "validator_no_specific_character": "Input cannot contain the character \"{0}\"",
    "validator_numberExt": "{0} may only contain numbers.",
    "validator_numeric": "{0} may only contain numbers.",
    "validator_positive_numbers": "{0} may only may only contain positive integer numbers.",
    "validator_regex": "{0} must match the pattern {1}.",
    "validator_required": "{0} is required.",
    "validator_required_remark_info_confirmation": "Please enter Information and/or Confirmation text.",
    "validator_start_before_end": "The start date must be before the end date.",
    "validator_unique": "{0} must be unique.",
    "validator_upperCase": "{0} is only allowed to be an upper case letter.",
    "validator_upperCaseAndNumber": "{0} may only contain uppercase letters and numbers.",
    "validator_upperCaseOrNumber": "{0} is only allowed to be a number or an upper case letter.",
    "validator_valid": "{0} is invalid.",
    "version": "version",
    "version_title": "Version",
    "vilauma_types_board": {
        "AI": "All Inclusive",
        "AI+": "All Inclusive plus",
        "AO": "Accommodation only",
        "BB": "Bed and Breakfast",
        "FB": "Full Board",
        "FB+": "Full Board plus",
        "HB": "Half Board",
        "HB+": "Half Board plus",
        "SC": "Self Catering",
        "XX": "as described"
    },
    "vilauma_types_room": {
        "AP": "Apartment",
        "BU": "Bungalow",
        "CA": "Cabine",
        "CH": "Chalet",
        "CT": "Cottage",
        "DL": "Deluxe",
        "DP": "Duplex",
        "DR": "Double room",
        "ER": "Economy room",
        "FC": "Finca",
        "FR": "Family room",
        "HA": "Handicaped accessible room",
        "HB": "House boat",
        "JS": "Juniorsuite",
        "MA": "Maisonette",
        "MB": "Multibed room",
        "MH": "MobileHome",
        "PH": "Penthouse",
        "SP": "Superior",
        "SR": "Single room",
        "ST": "Studio",
        "SU": "Suite",
        "TR": "Triple room",
        "VF": "Vacation flat",
        "VH": "Vacation home",
        "VI": "Villa",
        "WB": "Water bungalow",
        "XX": "as described"
    },
    "warning_whitelisting_needed_custom_rate": "The custom rate rules have been changed and this brand uses a custom rate filter. To re-apply the filter, a full whitelisting must be performed (\"full export\" or with an empty filter).",
    "warning_whitelisting_needed_preselection": "Some whitelisted contracts were removed by the hotel preselection. To remove these hotels from production, a whitelisting must be performed.",
    "weekday_cap": "week day",
    "weekday_restrictions_cap": "Travel Period Week Days",
    "weekday_restrictions_count_cap": "Travel Period Week Days",
    "whitelist": "whitelist",
    "whitelist_changes": "Whitelist changes per country",
    "whitelist_changes_download_button": "download changes",
    "whitelist_changes_download_error": "Couldn't download whitelist changes.",
    "whitelist_filter_from_draft_added_btn": "apply additions",
    "whitelist_filter_from_draft_btn": "whitelist result",
    "whitelist_filter_from_draft_removed_btn": "apply removals",
    "whitelist_filter_ignored": "this filter has no effect for whitelisting",
    "whitelist_history": {
        "contracts_added_cap": "Contracts added to Whitelist",
        "contracts_removed_cap": "Contracts removed from Whitelist",
        "link_cap": "Export Statistics",
        "revert_whitelist_job": "Revert",
        "revert_whitelist_job_cap": "Revert Whitelist Export",
        "time_after_cap": "Start",
        "time_before_cap": "End",
        "time_of_occurrence_cap": "Time of occurrence",
        "username_cap": "Username",
        "whitelist_history_cap": "Whitelist History",
        "whitelist_job_cap": "Whitelist Export",
        "whitelist_job_download_info": "download changes",
        "whitelist_job_id_cap": "Export ID",
        "whitelist_job_show_info": "details",
        "whitelist_revert_changes_cap": "Changes applied when reverting this whitelist export"
    },
    "whitelist_info": "{0} contract(s) for {1} hotels(s)",
    "whitelist_info_button_show": "whitelist details",
    "whitelist_info_error": "Couldn't get whitelisting info.",
    "whitelist_status": {
        "ADDING": "Adding:",
        "REMOVING": "Removing:",
        "SKIPPING": "Ignoring because the whitelist status changed in the meantime:"
    },
    "whitelist_total_change": "Total change of {0} contract(s) for {1} hotel(s)",
    "whitelisted": "Whitelisted",
    "whitelisted_cap": "Active",
    "xx_extra_type": "miscellaneous",
    "yes": "yes"
};
