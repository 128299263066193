/**
 * Apply the `Partial` type recursively.
 */
export type RecursivePartial<T> = {
    [P in keyof T]?:
        T[P] extends Array<infer U> ? Array<RecursivePartial<U>>
        : T[P] extends (number | string | symbol | undefined) ? T[P]
        : RecursivePartial<T[P]>; };

/**
 * Return an array of *all* keys of a specified type/interface, even optional.
 *
 * Because we cannot reflect on an interface,
 * we instead require an object with all keys of the interface to be passed
 * and then convert it to an array.
 *
 * @see {@link https://github.com/microsoft/TypeScript/issues/3628 Upstream issue}
 * @see {@link https://stackoverflow.com/a/60932900 Alternatives}
 */
export function allKeys<T>(obj: Record<keyof T, unknown>): Array<keyof T> {
    return Array.from(Object.keys(obj)) as Array<keyof T>;
}

/**
 * Return an iterable of the entries of an object with typing information intact.
 *
 * Because TypeScript does not make runtime guarantees
 * of an object only ever containing the keys it is intended to have,
 * we have this custom function that *tells* us it is so.
 * Since we disallow implicit any,
 * this will generally be correct, too.
 *
 * @see {@link hhttps://github.com/microsoft/TypeScript/pull/12253 Upstream issue}
 */
export function keys<T extends Partial<Record<string, unknown>>, K = keyof T>(obj: T): Array<K> {
    return Object.keys(obj) as Array<K>;
}

/**
 * Return an iterable of the entries of an object with typing information intact.
 *
 * Because TypeScript does not make runtime guarantees
 * of an object only ever containing the keys it is intended to have,
 * we have this custom function that *tells* us it is so.
 * Since we disallow implicit any,
 * this will generally be correct, too.
 *
 * @see {@link hhttps://github.com/microsoft/TypeScript/pull/12253 Upstream issue}
 */
export function entries<T extends Partial<Record<keyof T, V>>, K extends keyof T, V = T[K]>(obj: T): Array<[K, V]> {
    return Object.entries(obj) as Array<[K, V]>;
}

/**
 * Verify whether an array has no "nil" values
 * using lodash's `isNil`.
 *
 * {@link https://lodash.com/docs/4.17.15#isNil }
 */
export function arrayHasNoNil<T>(array: Array<T>): array is Array<Exclude<T, null | undefined>> {
    return array.every(isNotNil);
}

/**
 * Filter out nil (= `null | undefined`) values
 * while conveying back typing information.
 *
 * The name was inspired by lodash's `isNil`.
 * {@link https://lodash.com/docs/4.17.15#isNil }
 */
export function isNotNil<T>(value: T): value is Exclude<T, null | undefined> {
    return value != null;
}

/**
 * Create a filter to filter out nil (= `null | undefined`) values
 * of a specific object's field
 * while adjusting the typing information.
 */
export function fieldIsNotNil<T, K extends keyof T>(field: K) {
    return (obj: T): obj is Record<K, Exclude<T[K], null | undefined>> & T => isNotNil(obj[field]);
}

/**
 * Create a mapper that extracts a field from an object
 * while adjusting the typing information.
 */
export function fieldMapper<T, K extends keyof T>(field: K) {
    return (obj: T): T[K] => obj[field];
}
