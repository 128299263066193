import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { Props, TableParams } from '@/types/TableParams';
import { entries } from '@/types/utils';

export const randomId = function () {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 5; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
};

// lazy loading Components
// https://github.com/vuejs/vue-router/blob/dev/examples/lazy-loading/app.js#L8
export const lazyLoading = function (viewFolder: string, vueFile = 'Index') {
    return () => import(`../views/${viewFolder}/pages/${vueFile}.vue`);
};

/**
 * Works simular to the JQuery hasClass-function, but element needs to be first parameter
 * @param element {Object} the DOM element
 * @param className {string}, the class-name
 * @returns {boolean} true if element has class
 */
export const hasClass = function (element: Element, className: string): boolean {
    return !!element.className && new RegExp(`(^|\\s)${className}(\\s|$)`).test(element.className);
};

/**
 * Sorts array of objects by given key
 */
export function sortByKey<T extends Partial<Record<K, T[K]>>, K extends keyof T>(array: Array<T>, key: K): Array<T> {
    return array.sort((a, b) => {
        const x = a[key];
        const y = b[key];
        return (x < y) ? -1 : (x > y) ? 1 : 0;
    });
}

/**
 * Create an object from the given array of keys
 * and the provided function that produces a value for each entry.
 */
export function toObjectWithValues<K extends number | string | symbol, V>(arr: Array<K>, func: (k: K) => V) {
    return arr.reduce(
        (acc, curr) => {
            acc[curr] = func(curr);
            return acc;
        },
        {} as Record<K, V>,
    );
}

/**
 * Check if value is an empty object, collection or null-ish.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmptyObject(obj: any): obj is Array<any> | object | null | undefined {
    const resolvedObj = (obj ?? {});
    const isIterable = [Object, Array].includes(resolvedObj.constructor);
    const haveItems = Object.entries(resolvedObj).length;

    return isIterable && !haveItems;
}

type MaybeString = string | null | undefined;

export function isNonEmptyString(value: MaybeString): value is string {
    return value != null
        && value !== ''
        && value !== 'null';
}

export function stringifyForDisplay(value: Array<MaybeString> | MaybeString, joiner = ', '): string {
    if (Array.isArray(value)) {
        return value
            .filter(isNonEmptyString)
            .join(joiner);
    }
    if (!isNonEmptyString(value)) {
        return '';
    }
    return value;
}

export type Row = {opts: { trClass: string }};
export const handleRowHighlighting = function (props: Props<Row>, tableParams: TableParams) {
    if (!props.entry?.opts) {
        return;
    }

    if (tableParams.defaultRowClass) {
        tableParams.currentRowProps = Object.assign(tableParams.currentRowProps ?? {}, { entry: { opts: { trClass: tableParams.defaultRowClass } } });
    }

    tableParams.defaultRowClass = props.entry.opts.trClass;
    props.entry.opts.trClass = 'table-info';
    tableParams.currentRowProps = props;
};

export class SaveEventHandler {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventFn: (e: KeyboardEvent) => any;
    constructor() {
        this.eventFn = () => undefined;
    }

    bindEvent(callback: unknown) {
        this.eventFn = function (e) {
            if ((e.code === 'F4' || e.code === 'KeyS') && (e.ctrlKey || e.metaKey) && !(e.altKey)) {
                e.preventDefault();
                if (typeof callback === 'function') {
                    callback();
                }
                return false;
            }
        };
        document.addEventListener('keydown', this.eventFn);
    }

    removeEvent() {
        document.removeEventListener('keydown', this.eventFn);
    }
}

/**
 * Test whether a value is "something".
 * @param value the value to test
 * @returns false if value is undefined, null, NaN or empty string (with only whitespace); true otherwise.
 */
export function isValue<T>(value: T): value is Exclude<T, '' | null | undefined> {
    return !(
        value == null
        || (typeof value === 'number' && Number.isNaN(value))
        || (typeof value === 'string' && value.trim() === '')
    );
}

/**
 * Null-safe version of `Number.parseInt`.
 */
export function parseNumber(str?: string | null): number | null {
    if (str == null) {
        return null;
    }
    const int = Number.parseInt(str);
    return Number.isNaN(int) ? null : int;
}

export function sameMembers<T>(array1: Array<T>, array2: Array<T>) {
    return isEqual(sortBy(array1), sortBy(array2));
}

/**
 * Compare two objects/records of the same type by key-value pairs and return the individual results.
 * @param obj1 First operand.
 * @param obj2 Second operand.
 * @returns A records with the same keys and boolean values indicating inequality.
 */
export function valuesAreNotEqual<
    T extends Record<K, V>,
    K extends keyof T,
    V = T[K],
>(obj1: T, obj2: T): Record<keyof T, boolean> {
    return zipValues(obj1, obj2, (a, b) => !isEqual(a, b));
}

/**
 * Zip two objects/records of the same type key-wise with a bi-function.
 * @param obj1 First object.
 * @param obj2 Second object.
 * @param func Zipping function.
 * @returns A record with the same keys and results of the zipping function.
 */
export function zipValues<
    T extends Record<K, V>,
    R,
    K extends keyof T,
    V = T[K],
>(obj1: T, obj2: T, func: (arg1: V, arg2: V) => R): Record<keyof T, R> {
    // Do optional fields require V to be V | undefined?
    const pairs = entries(obj1)
        .map(([key, val]): [keyof T, R] => [key, func(val, obj2[key])]);
    return Object.fromEntries(pairs) as Record<keyof T, R>; // casting is necessary
}
